const { BET_TYPE_CONFIG, ALL_SIDE_IDS } = require("@wagerlab/utils/odds/betTypes");
const { getStatConfig, getStatLevel } = require("@wagerlab/utils/sports/stats");
const { PERIOD_ID_CONFIG, periodIsSupportedByLeague } = require("@wagerlab/utils/sports/periods");
const { isString, isObject, isBoolean } = require("@wagerlab/utils/data/types");
const { getInverseSpread, getInverseOdds, asMoneylineString, asSpreadString } = require("@wagerlab/utils/odds/converters");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");

// OUTRIGHTS  Might take the form of something like this:
// betTypeID=      outright  (or outright/champ/mltournament/mlt/mltourney/tml/tournamentml/winner)
// statEntityID =  teamID_1,
// sideID=         team    field   (or something similar. it depends on how we want to set up outright overunders. ex: o/u for tournament type event or for team wins in an NFL season. we could have a separate betTypeID or use the same one)

const getOddID = (oddData, skipStatEntityIDRefresh = false) => {
  const { statID, periodID, betTypeID, sideID } = oddData || {};
  const statEntityID = skipStatEntityIDRefresh ? oddData?.statEntityID : refreshStatEntityID(oddData);
  if (!betTypeID || !sideID || !periodID || !statID || !statEntityID) return null;
  return `${statID}-${statEntityID}-${periodID}-${betTypeID}-${sideID}`;
};
exports.getOddID = getOddID;

// Keeping this here temporarily until we decide to delete it for good
// const getMarketID = (oddData) => {
//   const { sideID, statID, periodID, betTypeID } = oddData || {};
//   const statEntityID = refreshStatEntityID(oddData);
//   if (!statEntityID) return null;
//   const sideDefaultStatEntityID = getDefaultStatEntityIDForSide(sideID, betTypeID);
//   const statLevelID = statEntityID === sideDefaultStatEntityID || statEntityID === "all" ? "main" : statEntityID;
//   if (!betTypeID || !periodID || !statID || !statLevelID) return null;
//   return `${statLevelID}-${periodID}-${statID}-${betTypeID}`;
// };
// exports.getMarketID = getMarketID;

const getDefaultStatEntityIDForSide = (sideID, betTypeID) => {
  if (betTypeID === "ml" || betTypeID === "sp") {
    if (sideID === "home") return "home";
    if (sideID === "away") return "away";
  }
  if (betTypeID === "ml3way") {
    if (sideID === "home") return "home";
    if (sideID === "away") return "away";
    if (sideID === "draw") return "all";

    if (sideID === "away+draw") return "away";
    if (sideID === "home+draw") return "home";
    if (sideID === "not_draw") return "all";
  }
  if (betTypeID === "prop") {
    if (sideID === "side1") return "side1";
    if (sideID === "side2") return "side2";
  }
  return "";
};
exports.getDefaultStatEntityIDForSide = getDefaultStatEntityIDForSide;

// This looks at the sideID before looking at the existing statEntityID. Returns the refreshed value (doesn't write to the input object)
const refreshStatEntityID = (oddData) => {
  const { statEntityID, betTypeID, sideID, playerID, teamID } = oddData || {};
  const defaultStatEntityIDForSide = getDefaultStatEntityIDForSide(sideID, betTypeID);
  if (defaultStatEntityIDForSide) return defaultStatEntityIDForSide;

  if (playerID && statEntityID === playerID) return playerID;
  if (teamID && statEntityID === teamID) return teamID;

  if (betTypeID === "prop") return statEntityID === "side1" || statEntityID === "side2" ? statEntityID : null;
  if (["ml", "sp"].includes(betTypeID)) return statEntityID === "home" || statEntityID === "away" ? statEntityID : null;
  if (statEntityID === "all" || statEntityID === "home" || statEntityID === "away") return statEntityID;
  return null;
};
exports.refreshStatEntityID = refreshStatEntityID;

const getOpponentOddData = (oddData) => {
  const sideID = getOpponentSideID(oddData);
  const statEntityID = refreshStatEntityID({ ...(oddData || {}), sideID });
  const oddID = getOddID({ ...(oddData || {}), sideID, statEntityID });
  if (!sideID || !statEntityID || !oddID) return null;

  const {
    sideID: sid,
    statEntityID: seid,
    oddID: oid,
    score: s,
    isField: isf,
    closeOdds: co,
    closeSpread: cs,
    bookOdds: bo,
    sourceContext: sc,
    bookSpread: bs,
    odds,
    spread,
    ...sharedOddData
  } = oddData;
  const opponentOddData = { ...sharedOddData, sideID, statEntityID, oddID };

  const inverseSpread = asSpreadString(getInverseSpread(spread));
  if (inverseSpread) opponentOddData.spread = inverseSpread;

  const inverseOdds = asMoneylineString(getInverseOdds(odds));
  if (inverseOdds) opponentOddData.odds = inverseOdds;

  return opponentOddData;
};
exports.getOpponentOddData = getOpponentOddData;

const getOpponentOddID = (oddData) => getOpponentOddData(oddData)?.oddID || null;
exports.getOpponentOddID = getOpponentOddID;

const getOpponentOddIDFromOddID = (oddID) => {
  if (!oddID) return null;
  const [statID, statEntityID, periodID, betTypeID, sideID] = oddID.split("-");
  const opponentSideID = getOpponentSideID({ sideID, betTypeID });
  const opponentStatEntityID = getDefaultStatEntityIDForSide(opponentSideID, betTypeID) || statEntityID;
  return getOddID({ statID, statEntityID: opponentStatEntityID, periodID, betTypeID, sideID: opponentSideID }, true);
};
exports.getOpponentOddIDFromOddID = getOpponentOddIDFromOddID;

const parseOddID = (oddID) => {
  if (!oddID) return null;
  const [statID, statEntityID, periodID, betTypeID, sideID] = oddID.split("-");
  const opponentSideID = getOpponentSideID({ sideID, betTypeID });
  const opponentStatEntityID = getDefaultStatEntityIDForSide(opponentSideID, betTypeID) || statEntityID;
  const opponentOddID = getOddID({ statID, statEntityID: opponentStatEntityID, periodID, betTypeID, sideID: opponentSideID }, true);
  return { oddID, statID, statEntityID, periodID, betTypeID, sideID, opponentSideID, opponentStatEntityID, opponentOddID };
};
exports.parseOddID = parseOddID;

const getOpponentSideID = (oddData) => {
  const { sideID, betTypeID } = oddData || {};
  if (!betTypeID || !sideID) return null;
  return BET_TYPE_CONFIG[betTypeID]?.sideIDs?.[sideID]?.opponentSideID || null;
};

const getOddPickID = (oddData) => {
  const { statID, periodID, betTypeID } = oddData || {};
  const statEntityID = refreshStatEntityID(oddData);
  if (!betTypeID || !periodID || !statID || !statEntityID) return null;
  return `${statID}-${statEntityID}-${periodID}-${betTypeID}`;
};
exports.getOddPickID = getOddPickID;

const getEventPickID = (eventData, oddData) => {
  const { eventID } = eventData || {};
  const oddPickID = getOddPickID(oddData);
  if (!eventID || !oddPickID) return null;
  return `${eventID}-${oddPickID}`;
};
exports.getEventPickID = getEventPickID;

const isValidPeriodID = (periodID, sportID, leagueID) => {
  const periodIsConfigured = periodID && PERIOD_ID_CONFIG[periodID]?.periodID;
  if (!periodIsConfigured || (!sportID && !leagueID)) return !!periodIsConfigured;
  return periodIsSupportedByLeague(periodID, sportID, leagueID);
};
exports.isValidPeriodID = isValidPeriodID;

const isValidBetTypeID = (betTypeID) => !!(betTypeID && BET_TYPE_CONFIG[betTypeID]?.betTypeID);
exports.isValidBetTypeID = isValidBetTypeID;

const isValidSideID = (sideID, betTypeID = null) => {
  if (!sideID) return false;
  if (!betTypeID) return !!ALL_SIDE_IDS[sideID]?.sideID;
  return !!BET_TYPE_CONFIG[betTypeID]?.sideIDs[sideID]?.sideID;
};
exports.isValidSideID = isValidSideID;

const isValidStatEntityID = (oddData) => !!oddData?.statEntityID && oddData?.statEntityID === refreshStatEntityID(oddData);
exports.isValidStatEntityID = isValidStatEntityID;

const classifyOdds = (oddData, eventType, sportID, leagueID) => {
  const { statID, statEntityID, betTypeID, periodID, playerID, teamID } = oddData || {};

  const sportPrefers3WayMoneyline = SPORT_CONFIG[sportID]?.prefers3WayMoneyline;
  const isTopPeriod = periodID === "game";
  const isTopPeriodForSport = sportPrefers3WayMoneyline ? periodID === "reg" : periodID === "game";
  const isSubPeriod = periodID !== "game";
  const isSubPeriodForSport = sportPrefers3WayMoneyline ? periodID !== "game" && periodID !== "reg" : isSubPeriod;
  const isPropEvent = eventType === "prop";
  let isMainStat = false;
  let isMainBet = false;
  let isMainBetForSport = false;
  if (eventType === "prop") {
    isMainStat = statID === "prop0";
    isMainBet = betTypeID === "prop" && (statEntityID === "side1" || statEntityID === "side2");
    isMainBetForSport = isMainBet;
  } else if (eventType === "tournament") {
    // TODO - outrights
    isMainStat = statID === "points";
    isMainBet = betTypeID === "outright" && !!teamID && statEntityID === teamID;
    isMainBetForSport = isMainBet;
  } else {
    isMainStat = statID === "points";
    const isMainSpread = betTypeID === "sp" && (statEntityID === "home" || statEntityID === "away");
    const isMainMoneyline = betTypeID === "ml" && (statEntityID === "home" || statEntityID === "away");
    const isMainOverUnder = betTypeID === "ou" && statEntityID === "all";
    const isMain3WayMoneyline = betTypeID === "ml3way" && (statEntityID === "home" || statEntityID === "away");
    isMainBet = isMainSpread || isMainMoneyline || isMainOverUnder || isMain3WayMoneyline;
    isMainBetForSport = sportPrefers3WayMoneyline ? isMainBet : isMainSpread || isMainMoneyline || isMainOverUnder;
    // isMainBetForSport = sportPrefers3WayMoneyline ? isMainSpread || isMainOverUnder || isMain3WayMoneyline : isMainSpread || isMainMoneyline || isMainOverUnder;
  }
  // Use isTopMarket if you are looking only for main 1-3 markets but for ALL sub-periodIDs and all eventType values
  const isMainMarket = isMainStat && isMainBet;
  const isMainMarketForSport = isMainStat && isMainBetForSport;
  // Use isTopMarket if you are looking only for main 1-3 markets and only for full game periodID but for all eventType values
  const isTopMarketForSport = isMainMarketForSport && isTopPeriodForSport;
  const isTopMarket = isMainMarket && (isTopPeriodForSport || isTopPeriod);
  // Team totals ARE considered "team props"
  // prop0 for events where Event.type=prop are NOT considered "other props"
  const isTeamProp = !isMainMarket && (statEntityID === "home" || statEntityID === "away" || (!!teamID && statEntityID === teamID));
  const isPlayerProp = !isMainMarket && !!playerID && statEntityID === playerID;
  const isGameProp = !isMainMarket && statEntityID === "all";
  const isOtherProp = !isMainMarket && betTypeID === "prop";
  // isAnyProp includes all odds for prop eventTypes. Main market odds for a sub-period are NOT included in isAnyProp
  const isAnyProp = isTeamProp || isPlayerProp || isGameProp || isOtherProp || isPropEvent;
  // Secondary markets are any type of prop markets, all markets on prop events, and all sub-period bets
  const isSecondaryOrPropMarket = isAnyProp || !isTopMarket;
  const isSecondaryOrPropMarketForSport = isAnyProp || !isTopMarketForSport;

  return {
    isMainMarket,
    isTopMarket,
    isTopMarketForSport,
    isSecondaryOrPropMarket,
    isSecondaryOrPropMarketForSport,
    isAnyProp,
    isTeamProp,
    isPlayerProp,
    isGameProp,
    isOtherProp,
    isSubPeriod,
    isSubPeriodForSport,
  };
};
exports.classifyOdds = classifyOdds;

const isSpecialCaseUnsupportedOdds = (oddData, eventData) => {
  const { statID, statEntityID, betTypeID, periodID, playerID, teamID } = oddData || {};
  const { sportID } = eventData || {};

  const statConfig = getStatConfig(statID, sportID);

  // If any of the entries in the neverSupports list match this odd, then it is unsupported
  const statNeverSupportsConfig = statConfig?.neverSupports || [];
  const statNeverSupportsOdd = statNeverSupportsConfig?.some((obj) => {
    if (!obj?.statEntityID && !obj?.betTypeID && !obj?.periodID) return false;

    const statEntityIDUnsupported = !obj?.statEntityID || obj.statEntityID === statEntityID;
    const betTypeIDUnsupported = !obj?.betTypeID || obj.betTypeID === betTypeID;
    const periodIDUnsupported = !obj?.periodID || obj.periodID === periodID;

    return statEntityIDUnsupported && betTypeIDUnsupported && periodIDUnsupported;
  });

  // Stat never supports this odd
  if (statNeverSupportsOdd) return true;

  // If there are no onlySupports entries, or any of the entries match this odd, then this odd is supported
  const statOnlySupportsConfig = statConfig?.onlySupports || [];
  const statSupportsOdd =
    !statOnlySupportsConfig?.length ||
    statOnlySupportsConfig.some((obj) => {
      if (!obj?.statEntityID && !obj?.betTypeID && !obj?.periodID) return true;

      const statEntityIDSupported = !obj?.statEntityID || obj.statEntityID === statEntityID;
      const betTypeIDSupported = !obj?.betTypeID || obj.betTypeID === betTypeID;
      const periodIDSupported = !obj?.periodID || obj.periodID === periodID;

      return statEntityIDSupported && betTypeIDSupported && periodIDSupported;
    });

  // Stat only supports certain odds, and this odd does not match the config
  if (!statSupportsOdd) return true;

  if (isBinary(statConfig?.binary, statEntityID, playerID, teamID) && betTypeID !== "yn" && betTypeID !== "ml") return true; //NOTE: If we want to support player vs player spreads on binary stats (ex Lebron vs Curry double-double) then this will need to be changed

  return false;
};
exports.isSpecialCaseUnsupportedOdds = isSpecialCaseUnsupportedOdds;

const isBinary = (binary, statEntityID, playerID, teamID) => {
  if (!binary) return false;
  if (binary === true) return true;
  if (isObject(binary)) {
    const level = getStatLevel(statEntityID, playerID, teamID);
    return !!binary[level];
  }

  return false;
};
