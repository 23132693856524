const { isNumber } = require("@wagerlab/utils/data/types");

/**
 * Executes an async operation with configurable retry behavior
 *
 * @async
 * @param {Function} operation - Async function to execute with retry logic
 * @param {Object} [options={}] - Configuration options
 * @param {number} [options.maxRetries=1] - Maximum number of retry attempts
 * @param {Function} [options.shouldRetry] - Function that receives the error and returns boolean indicating if retry should occur
 * @param {number} [options.delayMs] - Delay in milliseconds between retries (if not specified, uses exponential backoff with jitter)
 * @param {*} [options.errorValue] - Value to return if all retries fail (instead of throwing)
 * @returns {Promise<*>} Result of the operation if successful
 * @throws {Error} Throws the last error encountered if all retries fail and no errorValue is provided
 *
 * @example
 * // Basic usage with default retry
 * const result = await runWithRetry(async () => {
 *   return await fetchData();
 * });
 *
 * @example
 * // With custom options
 * const result = await runWithRetry(async () => {
 *   return await fetchData();
 * }, {
 *   maxRetries: 3,
 *   shouldRetry: (error) => error.status === 429, // only retry rate limit errors
 *   delayMs: 1000, // wait 1 second between retries
 *   errorValue: [] // return empty array if all retries fail
 * });
 */
const runWithRetry = async (operation, options = {}) => {
  const maxRetries = options?.maxRetries || 1;

  let lastError = null;

  for (let attemptIndex = 0; attemptIndex <= maxRetries + 1; attemptIndex++) {
    try {
      const result = await operation();
      return result;
    } catch (error) {
      lastError = error;

      if (attemptIndex >= maxRetries) break;
      if (options?.shouldRetry && !options.shouldRetry(error)) break;

      let delayMs = options?.delayMs;
      if (!isNumber(delayMs) || delayMs < 0) {
        const baseDelay = Math.min(Math.pow(2, attemptIndex) * 50, 3000);
        const jitter = Math.floor(Math.random() * 300);
        delayMs = baseDelay + jitter;
      }
      await new Promise((resolve) => setTimeout(resolve, delayMs));
    }
  }

  if ("errorValue" in options) return options.errorValue;

  throw lastError;
};
exports.runWithRetry = runWithRetry;
