import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { buildAPIKeyWelcomeEmailRequest } from "@wagerlab/utils/sgo/apiKeyEmails";

export const getAllApiKeys = async () => {
  const result = await callAdminAPI("getApiKeys", {});
  const { success, apiKeys } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return apiKeys || null;
};

export const updateApiKey = async (apiKeyHash, updates) => {
  const result = await callAdminAPI("updateApiKey", { apiKeyHash, updates });
  const { success } = result || {};
  if (!success) console.error(result);
  return !!success;
};

export const regenerateApiKey = async (apiKeyHash) => {
  const result = await callAdminAPI("regenerateApiKey", { apiKeyHash });
  const { success, apiKey: newApiKey, apiKeyHash: newApiKeyHash } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return { apiKey: newApiKey, apiKeyHash: newApiKeyHash };
};

export const sendApiKeyEmail = async (apiKeyData, apiKey) => {
  const sendEmailRequestData = buildAPIKeyWelcomeEmailRequest(apiKey, apiKeyData);
  const result = await callAdminAPI("sendEmailCallable", sendEmailRequestData);
  const { success } = result || {};
  if (!success) console.error(result);
  return !!success;
};
