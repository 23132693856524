const { Collection } = require("../Collection");

const TEAMS_CONFIG = {
  collectionType: "teams",
  collectionName: "Teams",
  adapterType: "mongo",
  primaryKey: "teamID",
};
exports.TEAMS_CONFIG = TEAMS_CONFIG;

class TeamsCollection extends Collection {
  config = TEAMS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.TeamsCollection = TeamsCollection;
