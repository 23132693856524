import React from "react";
import { IconButton, TextField as MaterialTextField, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";
import { parseNumber } from "@wagerlab/utils/data/numbers";

export const TextField = ({ name, tooltip, disabled = false, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { touched, error }, { setValue }] = useField(name);

  const isDisabled = !!(isSubmitting || disabled);
  const hasErrors = !!(touched && error && isString(error));

  const inputProps = tooltip
    ? {
        endAdornment: (
          <Tooltip title={tooltip} placement="top">
            <IconButton color="primary" aria-label="help icon" component="span" sx={{ mr: 2 }}>
              <InfoOutlinedIcon sx={{ color: "lightblue" }} />
            </IconButton>
          </Tooltip>
        ),
      }
    : null;

  return <MaterialTextField {...field} {...otherProps} disabled={isDisabled} fullWidth={true} variant={"outlined"} error={hasErrors} helperText={hasErrors ? error : null} InputProps={inputProps} />;
};

//numberDefault: number | null | ""
//typeFormat: "any" | "int" | "integer"
//valueFormat: "any" | ">0" | "positive" | ">=0" | "non-negative" | "<0" | "negative" | "<=0" | "non-positive"
export const NumberField = ({ numberDefault = null, typeFormat = "", valueFormat = "", ...textFieldProps }) => {
  const [field, { touched, error }, { setValue, setTouched }] = useField(textFieldProps.name);
  const handleEvent = (event) => {
    const inputValue = event?.target?.value || "";
    setTouched(true, false);
    const numberValue = parseNumber(inputValue, numberDefault, typeFormat, valueFormat);
    setValue(numberValue, true);
  };

  return <TextField {...textFieldProps} type="number" onBlur={handleEvent} />;
};
