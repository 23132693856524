import React, { useState, useEffect } from "react";
import { Snackbar, Alert, Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

let globalToastSetState = null;
let globalDialogSetState = null;

export const GlobalToast = () => {
  const [toast, setToast] = useState({ open: false, message: "", type: "info" });

  useEffect(() => {
    globalToastSetState = setToast;
    return () => (globalToastSetState = null);
  }, []);

  const handleClose = (e, r) => setToast({ ...toast, open: false });

  const severity = toast.type === "error" ? "error" : toast.type === "success" ? "success" : "info";

  return (
    <Snackbar open={toast.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export const GlobalDialog = () => {
  const [dialog, setDialog] = useState({
    open: false,
    question: "",
    answers: [],
    resolve: null,
  });

  useEffect(() => {
    globalDialogSetState = setDialog;
    return () => (globalDialogSetState = null);
  }, []);

  const handleClose = () => {
    if (dialog.resolve) dialog.resolve(null);
    setDialog({ open: false, question: "", answers: [], resolve: null });
  };

  const handleAnswer = (answer) => {
    if (dialog.resolve) dialog.resolve(answer);
    setDialog({ open: false, question: "", answers: [], resolve: null });
  };

  return (
    <Dialog open={dialog.open} onClose={handleClose} style={{ zIndex: 2000 }}>
      <DialogTitle>{dialog.question}</DialogTitle>
      <DialogActions>
        {dialog.answers.map((answer, index) => (
          <Button key={index} onClick={() => handleAnswer(answer)} variant="contained" color="primary">
            {answer}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export const presentErrorMessage = (message) => {
  if (!globalToastSetState || !message) return;
  globalToastSetState({ open: true, message, type: "error" });
};

export const presentSuccessMessage = (message) => {
  if (!globalToastSetState || !message) return;
  globalToastSetState({ open: true, message, type: "success" });
};

export const presentInfoMessage = (message) => {
  if (!globalToastSetState || !message) return;
  globalToastSetState({ open: true, message, type: "info" });
};

export const presentQuestion = async (question, answers) => {
  const selectedAnswer = await new Promise((resolve) => {
    if (!globalDialogSetState || !question || !answers?.length) resolve(null);
    else globalDialogSetState({ open: true, question, answers, resolve });
  });
  return selectedAnswer;
};
