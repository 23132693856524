import React, { useState, useEffect } from "react";
import { Alert, Box, Container, Grid, Snackbar, Typography } from "@mui/material";
import { Formik, Form, useFormikContext } from "formik";
import { EventQueryForm } from "@wagerlab/admin/src/events/list/EventQueryForm";
import { queryEvents } from "@wagerlab/admin/src/events/database";
import * as Yup from "yup";
import { EVENT_SUMMARY_PATHS } from "@wagerlab/admin/src/events/list/filterEvents";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { parseNumber } from "@wagerlab/utils/data/numbers";
import { isBoolean, isNumber, parseBoolean } from "@wagerlab/utils/data/types";

const QUERY_FORM_CONFIG = {
  type: {
    defaultValue: "prop",
    key: "type",
    includedKey: "typeIncluded",
    eventPath: "type",
  },
  eventID: {
    defaultValue: "",
    key: "eventID",
    includedKey: "eventIDIncluded",
    eventPath: "eventID",
  },
  eventName: {
    defaultValue: "",
    key: "eventName",
    includedKey: "eventNameIncluded",
    eventPath: "eventName",
  },
  sportID: {
    defaultValue: "",
    key: "sportID",
    includedKey: "sportIDIncluded",
    eventPath: "sportID",
  },
  leagueID: {
    defaultValue: "",
    key: "leagueID",
    includedKey: "leagueIDIncluded",
    eventPath: "leagueID",
  },
  homeTeamID: {
    defaultValue: "",
    key: "homeTeamID",
    includedKey: "homeTeamIDIncluded",
    eventPath: "teams.home.teamID",
  },
  awayTeamID: {
    defaultValue: "",
    key: "awayTeamID",
    includedKey: "awayTeamIDIncluded",
    eventPath: "teams.away.teamID",
  },
  startsAt: {
    defaultValue: null,
    key: "startsAt",
    includedKey: "startsAtIncluded",
    eventPath: "status.startsAt",
    transformer: (momentDate) => momentDate?.toDate?.(),
    valueIsDate: true,
  },
  startsAfter: {
    defaultValue: null,
    key: "startsAfter",
    includedKey: "startsAfterIncluded",
    eventPath: "status.startsAt",
    transformer: (momentDate) => momentDate?.toDate?.(),
    queryOperator: ">",
    valueIsDate: true,
  },
  startsBefore: {
    defaultValue: null,
    key: "startsBefore",
    includedKey: "startsBeforeIncluded",
    eventPath: "status.startsAt",
    transformer: (momentDate) => momentDate?.toDate?.(),
    queryOperator: "<",
    valueIsDate: true,
  },
  oddsAvailable: {
    defaultValue: false,
    key: "oddsAvailable",
    includedKey: "oddsAvailableIncluded",
    eventPath: "status.oddsAvailable",
  },
  oddsPresent: {
    defaultValue: false,
    key: "oddsPresent",
    includedKey: "oddsPresentIncluded",
    eventPath: "status.oddsPresent",
  },
  started: {
    defaultValue: false,
    key: "started",
    includedKey: "startedIncluded",
    eventPath: "status.started",
  },
  ended: {
    defaultValue: false,
    key: "ended",
    includedKey: "endedIncluded",
    eventPath: "status.ended",
  },
  cancelled: {
    defaultValue: false,
    key: "cancelled",
    includedKey: "cancelledIncluded",
    eventPath: "status.cancelled",
  },
  delayed: {
    defaultValue: false,
    key: "delayed",
    includedKey: "delayedIncluded",
    eventPath: "status.delayed",
  },
  finalized: {
    defaultValue: false,
    key: "finalized",
    includedKey: "finalizedIncluded",
    eventPath: "status.finalized",
  },
  limit: {
    defaultValue: 100,
    key: "limit",
    includedKey: "limitIncluded",
    validation: Yup.number().min(1, "Limit must be greater than 0").nullable(),
  },
};

const formValidationFields = Object.values(QUERY_FORM_CONFIG).reduce((formValidationFields, formConfigItem) => {
  const { key, validation } = formConfigItem || {};
  if (!key || !validation) return formValidationFields;
  formValidationFields[key] = validation;
  return formValidationFields;
}, {});
const QUERY_FORM_VALIDATION = Yup.object().shape(formValidationFields);

const DEFAULT_FORM_VALUES = Object.values(QUERY_FORM_CONFIG).reduce((defaultFormVals, formConfigItem) => {
  if (!formConfigItem?.key) return defaultFormVals;
  defaultFormVals[formConfigItem.key] = formConfigItem.defaultValue;
  if (formConfigItem?.includedKey) defaultFormVals[formConfigItem.includedKey] = false;
  return defaultFormVals;
}, {});

const serializeFormToParams = (formValues) => {
  const params = new URLSearchParams();

  Object.entries(QUERY_FORM_CONFIG).forEach(([key, config]) => {
    const includedKey = config?.includedKey;
    const shouldInclude = includedKey && formValues[includedKey];
    if (!shouldInclude) return null;

    const formValue = formValues[key];

    if (formValue == null) return;

    if (config.valueIsDate) {
      const dateString = formValue?.toISOString?.();
      if (!dateString) return;
      params.set(key, dateString);
    } else {
      params.set(key, formValue.toString());
    }
    params.set(includedKey, "true");
  });

  return params;
};

const parseParamsToForm = (searchParams) => {
  const formValues = { ...DEFAULT_FORM_VALUES };

  Object.entries(QUERY_FORM_CONFIG).forEach(([key, config]) => {
    const includedKey = config?.includedKey;
    const shouldInclude = includedKey && searchParams.get(includedKey) === "true";
    if (!shouldInclude) return;

    const rawValue = searchParams.get(key);
    if (rawValue == null) return;
    let formValue = null;
    if (config?.valueIsDate) formValue = moment(rawValue).isValid() ? moment(rawValue) : null;
    else if (isBoolean(DEFAULT_FORM_VALUES[key])) formValue = parseBoolean(rawValue, null);
    else if (isNumber(DEFAULT_FORM_VALUES[key])) formValue = parseNumber(rawValue, null);
    else formValue = rawValue;

    if (formValue == null) return;

    formValues[includedKey] = true;
    formValues[key] = formValue;
  });

  return formValues;
};

export const EventQueryBuilder = ({ handleQueryResult }) => {
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFormSubmit = async (formData, formikBag) => {
    setMessage("");
    const limit = formData?.limit || null;
    const eventQueryParts = Object.values(QUERY_FORM_CONFIG).reduce((queryParts, queryConfigItem) => {
      const { includedKey, key, required, eventPath, transformer, queryOperator, valueIsDate } = queryConfigItem;
      if (!key || !eventPath) return queryParts;
      const shouldInclude = required || !includedKey || formData?.[includedKey];
      if (!shouldInclude) return queryParts;
      const value = transformer ? transformer(formData[key]) : formData[key];
      if (value == null) return queryParts;
      const operator = queryOperator || "==";
      queryParts.push({ key: eventPath, operator, value, valueIsDate });
      return queryParts;
    }, []);

    if (!eventQueryParts.length) return setMessage("Include at least 1 item in your query");

    const queryResult = await queryEvents(eventQueryParts, limit, EVENT_SUMMARY_PATHS);

    if (!queryResult) return setMessage("Error querying events");
    if (!queryResult.length) return setMessage("No events found");

    handleQueryResult(queryResult);
  };

  return (
    <>
      <Grid item xs={12}>
        <Container maxWidth="xl">
          <Formik initialValues={parseParamsToForm(searchParams)} onSubmit={handleFormSubmit} validationSchema={QUERY_FORM_VALIDATION} enableReinitialize>
            <>
              <EventQueryForm />
              <ParamsSyncer />
            </>
          </Formik>
        </Container>
      </Grid>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!message} onClose={() => setMessage("")} autoHideDuration={10000}>
        <Alert onClose={() => setMessage("")} severity={"info"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

const ParamsSyncer = () => {
  const { values } = useFormikContext();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams(serializeFormToParams(values));
  }, [values]);
  return null;
};
