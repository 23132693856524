const { stripEmptyValues, setObjVal, stripNullValues } = require("@wagerlab/utils/data/mutations");

exports.validateEvent = (event) => {
  const { eventID, sportID, leagueID, type } = event || {};

  const eventMetaIsValid = !!(eventID && sportID && leagueID && type);

  const eventEndedIfFinalized = event?.status?.finalized ? event?.status?.ended : true;
  const eventIsValid = eventMetaIsValid && eventEndedIfFinalized;
  if (!eventIsValid) return null;

  return stripNullValues(event);
};
