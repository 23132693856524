let firebaseModule = null;
let firebaseAdminModule = null;
let mongodbModule = null;
let loggerModule = null;

exports.setFirebaseModule = (module, isAdmin, force = false) => {
  if (force) {
    firebaseModule = module;
    firebaseAdminModule = isAdmin ? module : null;
  } else {
    firebaseModule = firebaseModule || module;
    if (isAdmin) firebaseAdminModule = firebaseAdminModule || module;
  }
};
exports.setMongoDBModule = (module, force = false) => (force ? (mongodbModule = module) : (mongodbModule = mongodbModule || module));
exports.setLoggerModule = (module, force = false) => (force ? (loggerModule = module) : (loggerModule = loggerModule || module));

exports.getFirebaseModule = () => firebaseModule;
exports.getFirebaseAdminModule = () => firebaseAdminModule;
exports.getMongoDBModule = () => mongodbModule;
exports.getLoggerModule = () => loggerModule;
