import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

export const AppLayout = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  return (
    <Container maxWidth={false} component="main">
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6" component="div">
                WagerLab Admin
              </Typography>
              <Button onClick={() => navigate("/")} color="inherit">
                Home
              </Button>
            </Stack>
          </Box>

          <Stack direction="row" spacing={2}>
            <Button color="inherit" onClick={handleSignOut}>
              Log Out
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Container>
  );
};
