const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isNumber, isDate, isPositiveNumber, isEmptyObject } = require("@wagerlab/utils/data/types");
const { getOddsDisplay } = require("@wagerlab/utils/displays");
const { BET_TYPE_ENUM, BET_TYPE_CONFIG } = require("@wagerlab/utils/odds/betTypes");
const { getOpponentOddID } = require("@wagerlab/utils/odds/identifiers");
const { supportsOddScore, getOddScore } = require("@wagerlab/utils/odds/scoring");
const { getPeriodsStartedEndedMap, getPeriodStartedEnded } = require("@wagerlab/utils/sports/periodStatus");
const { getStatConfig } = require("@wagerlab/utils/sports/stats");
const _ = require("lodash");

exports.refreshAndSyncOdds = (event, sourceMap = null) => {
  const nowDate = new Date();
  const {
    oddsCloseAtDate,
    oddsCloseAtStart,
    started: eventStarted,
    startsAt: eventStartsAt,
    ended: eventEnded,
    cancelled: eventCancelled,
    hardStart: eventHardStart,
    finalized: eventPrevFinalized,
  } = event?.status || {};

  const periodsStartedEndedMap = getPeriodsStartedEndedMap(event?.status?.periods?.started, event?.status?.periods?.ended, event?.sportID, event?.leagueID);

  const closedByCloseAtDate = isDate(oddsCloseAtDate) && nowDate > oddsCloseAtDate;
  const eventPastStartsAt = eventStartsAt && nowDate > eventStartsAt;
  const eventStartedSafe = eventStarted || (eventHardStart && eventPastStartsAt);
  const closedByStart = oddsCloseAtStart && eventStartedSafe;
  const allOddsClosed = closedByCloseAtDate || closedByStart;

  let oddsAvailable = false;
  let oddsPresent = false;
  Object.keys(event?.odds || {}).forEach((oddID) => {
    const eventOddData = event?.odds?.[oddID];
    if (!oddID || !eventOddData) return;

    let {
      started,
      ended,
      cancelled,
      statID,
      statEntityID,
      periodID,
      betTypeID,
      sideID,
      playerID,
      teamID,
      sourceContext,
      byBookmaker,
      fairOdds,
      bookOdds,
      fairSpread,
      bookSpread,
      fairOverUnder,
      bookOverUnder,
    } = eventOddData || {};

    let score = (started || ended) && !cancelled ? getOddScore(eventOddData, event) : null;
    if (!isNumber(score)) score = null;
    const scoringSupported = !!supportsOddScore(eventOddData, event, sourceMap);

    const { started: periodStarted, ended: periodEnded } = getPeriodStartedEnded(periodID, periodsStartedEndedMap);

    cancelled = !!(cancelled || eventCancelled);
    started = !!(started || periodStarted === true || isPositiveNumber(score));
    ended = !!(cancelled || ended || eventEnded || periodEnded === true);

    const marketName = getOddsDisplay("marketName", eventOddData, event) || null;
    const opposingOddID = getOpponentOddID(eventOddData) || null;

    const canSetOpeningOdds = !eventStartedSafe && !ended && !cancelled && !eventPrevFinalized;
    const canSetClosingOdds = (eventStartedSafe || started || ended) && !cancelled && !eventPrevFinalized;

    let openFairOdds = canSetOpeningOdds ? eventOddData?.openFairOdds || fairOdds : null;
    let closeFairOdds = canSetClosingOdds ? eventOddData?.closeFairOdds || fairOdds : null;
    let openBookOdds = canSetOpeningOdds ? eventOddData?.openBookOdds || bookOdds : null;
    let closeBookOdds = canSetClosingOdds ? eventOddData?.closeBookOdds || bookOdds : null;
    let openFairSpread = canSetOpeningOdds ? eventOddData?.openFairSpread || fairSpread : null;
    let closeFairSpread = canSetClosingOdds ? eventOddData?.closeFairSpread || fairSpread : null;
    let openBookSpread = canSetOpeningOdds ? eventOddData?.openBookSpread || bookSpread : null;
    let closeBookSpread = canSetClosingOdds ? eventOddData?.closeBookSpread || bookSpread : null;
    let openFairOverUnder = canSetOpeningOdds ? eventOddData?.openFairOverUnder || fairOverUnder : null;
    let closeFairOverUnder = canSetClosingOdds ? eventOddData?.closeFairOverUnder || fairOverUnder : null;
    let openBookOverUnder = canSetOpeningOdds ? eventOddData?.openBookOverUnder || bookOverUnder : null;
    let closeBookOverUnder = canSetClosingOdds ? eventOddData?.closeBookOverUnder || bookOverUnder : null;
    // These are currently disabled
    // const fairOverUnderBookOdds = eventOddData?.fairOverUnderBookOdds;
    // const fairSpreadBookOdds = eventOddData?.fairSpreadBookOdds;

    const betTypeConfig = BET_TYPE_CONFIG?.[betTypeID];
    const statConfig = getStatConfig(statID, event?.sportID);

    const deactivateWhenLineReached = betTypeConfig?.canDeactivateWhenOver && !statConfig?.canDecrease && isNumber(score);

    let fairOddsAvailable = !!(eventOddData?.fairOddsAvailable && !ended && fairOdds && !allOddsClosed);
    let bookOddsAvailable = !!(eventOddData?.bookOddsAvailable && !ended && bookOdds);
    if (betTypeID === BET_TYPE_ENUM.sp) {
      fairOddsAvailable = fairOddsAvailable && !!fairSpread;
      bookOddsAvailable = bookOddsAvailable && !!bookSpread;
    }
    if (betTypeID === BET_TYPE_ENUM.ou) {
      if (!fairOverUnder || (deactivateWhenLineReached && score >= fairOverUnder)) fairOddsAvailable = false;
      if (!bookOverUnder) bookOddsAvailable = false; // TODO WWW: This should use deactivateWhenLineReached once oddsList object is updated to pick better line
    }
    if (betTypeID === BET_TYPE_ENUM.yn) {
      if (deactivateWhenLineReached && score >= 0.5) {
        fairOddsAvailable = false;
        bookOddsAvailable = false;
      }
    }

    const mergedEventBookOddData = {
      oddID,
      opposingOddID,
      marketName,
      statID,
      statEntityID,
      periodID,
      betTypeID,
      sideID,
      playerID: playerID || null,
      teamID: teamID || null,
      started,
      ended,
      cancelled,
      bookOddsAvailable,
      fairOddsAvailable,
      fairOdds,
      bookOdds,
      fairSpread,
      bookSpread,
      fairOverUnder,
      bookOverUnder,
      openFairOdds,
      closeFairOdds,
      openBookOdds,
      closeBookOdds,
      openFairSpread,
      closeFairSpread,
      openBookSpread,
      closeBookSpread,
      openFairOverUnder,
      closeFairOverUnder,
      openBookOverUnder,
      closeBookOverUnder,
      score,
      scoringSupported,
      byBookmaker: refreshOddsByBookmaker(byBookmaker, betTypeID, ended, deactivateWhenLineReached, score),
      sourceContext,
    };
    setObjVal(event, `odds.${oddID}`, mergedEventBookOddData);

    oddsPresent = true;
    if (bookOddsAvailable || fairOddsAvailable) oddsAvailable = true;
  });

  setObjVal(event, "status.oddsPresent", oddsPresent);
  setObjVal(event, "status.oddsAvailable", oddsAvailable);

  return event;
};

const refreshOddsByBookmaker = (byBookmaker, betTypeID, ended, deactivateWhenLineReached, score) => {
  const newByBookmaker = Object.entries(byBookmaker || {}).reduce((refreshedByBookmaker, [bookmakerID, bookmakerValues]) => {
    const { altLines, ...mainLine } = bookmakerValues || {};
    const refreshedBookmakerValues = refreshByBookmakerItem(mainLine, betTypeID, ended, deactivateWhenLineReached, score);
    if (!refreshedBookmakerValues) return refreshedByBookmaker;
    const refreshedAltLines = [];
    (altLines || []).forEach((altLine) => {
      if (!refreshedBookmakerValues.deeplink && altLine?.deeplink) refreshedBookmakerValues.deeplink = altLine.deeplink;
      const refreshedAltLine = refreshByBookmakerItem(altLine, betTypeID, ended, deactivateWhenLineReached, score, refreshedBookmakerValues.deeplink);
      if (refreshedAltLine) refreshedAltLines.push(refreshedAltLine);
    });
    if (refreshedAltLines.length) refreshedBookmakerValues.altLines = refreshedAltLines;
    else delete refreshedBookmakerValues.altLines;
    refreshedByBookmaker[bookmakerID] = refreshedBookmakerValues;
    return refreshedByBookmaker;
  }, {});
  if (!newByBookmaker || isEmptyObject(newByBookmaker)) return null;
  return newByBookmaker;
};

const refreshByBookmakerItem = (byBookmakerItem, betTypeID, ended, deactivateWhenLineReached, score, mainLineDeeplink = "") => {
  const { available, odds, spread, overUnder, deeplink, lastUpdatedAt } = byBookmakerItem || {};
  if (!odds || !betTypeID) return null;
  const { hasLine, lineKey, impliedLine } = BET_TYPE_CONFIG?.[betTypeID];
  const line = lineKey ? byBookmakerItem?.[lineKey] : impliedLine;
  if (hasLine && !line) return null;

  byBookmakerItem.available = !!(available && !ended);
  if (byBookmakerItem.available && deactivateWhenLineReached && line && score && score >= line) byBookmakerItem.available = false;

  if (mainLineDeeplink && deeplink && deeplink === mainLineDeeplink) delete byBookmakerItem.deeplink;
  return byBookmakerItem;
};

// const getWagerLabOddsAvailable = (oddData, eventData) => {
//   const { startsAt: eventStartsAt, hardStart: eventHardStart, finalized: eventPrevFinalized, started: eventStarted, live: eventLive, ended: eventEnded } = eventData?.status || {};
//   const { fairOddsAvailable, started, ended, periodID, betTypeID, scoringSupported, fairSpread, byBookmaker } = oddData || {};
//   const nowDate = new Date();

//   if (!fairOddsAvailable || !scoringSupported) return false;

//   const startedSafe = started || eventStarted || (eventHardStart && eventStartsAt && nowDate > eventStartsAt);
//   if (startedSafe && !getPeriodConfig(periodID)?.supportsLiveOdds) return false;
//   if (startedSafe && isFallbackOdds) return false;

//   if (betTypeID === "sp") {
//     const fairSpreadFloat = asSpreadFloat(fairSpread);
//     if (!isNumber(fairSpreadFloat) || fairSpreadFloat === 0) return false;
//   }

//   return true;
// };
