import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import moment from "moment-timezone";
import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { TextButton } from "@wagerlab/admin/src/shared/Buttons";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { parseDate } from "@wagerlab/utils/data/dates";
import * as unknownPlayersConfig from "./unknownPlayersConfig";
import * as unknownTeamsConfig from "./unknownTeamsConfig";
import { presentErrorMessage, presentSuccessMessage } from "@wagerlab/admin/src/utils/alerts";

const UNKNOWNS_CONFIG_BY_TYPE = {
  unknownplayers: unknownPlayersConfig,
  unknownteams: unknownTeamsConfig,
};

export const UnknownEntitiesReport = ({ type }) => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const unknownConfig = UNKNOWNS_CONFIG_BY_TYPE[type];

  const generateReport = async () => {
    setLoading(true);
    try {
      const result = await callAdminAPI("getUnknownsReport", { type: unknownConfig.type });
      if (result?.success) {
        setReport(result?.report || {});
        presentSuccessMessage("Report generated successfully");
      } else {
        presentErrorMessage(`Failed to generate report: ${result?.error || "Unknown error"}`);
      }
    } catch (error) {
      presentErrorMessage(`Error: ${error.message || "Unknown error"}`);
    }
    setLoading(false);
  };

  // Process and sort leagues by number of unknowns
  const reportCards = Object.entries(LEAGUE_CONFIG)
    .map(([leagueID, leagueConfig]) => {
      const reportData = report?.[leagueID] || {};
      const numUnknowns = reportData.numUnknowns || 0;
      const lastRanAt = parseDate(reportData.lastRanAt, null);

      // Determine status based on date
      let statusColor = "black";
      if (lastRanAt) {
        const daysSinceUpdate = moment().diff(moment(lastRanAt), "days");
        if (daysSinceUpdate < 1) {
          statusColor = "success";
        } else if (daysSinceUpdate < 7) {
          statusColor = "warning";
        } else {
          statusColor = "error.main";
        }
      }

      return {
        leagueID,
        leagueName: leagueConfig?.name || leagueConfig?.shortName || leagueID,
        lastRanAt,
        numUnknowns,
        statusColor,
      };
    })
    .sort((a, b) => b.numUnknowns - a.numUnknowns || a.leagueName.localeCompare(b.leagueName));

  const shortReportCards = !loading && !!report;

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextButton text="Generate Report" loading={loading} onClick={generateReport} color="primary" size="large" />
      </Box>

      {shortReportCards && (
        <Grid container spacing={2}>
          {reportCards.map((reportCard) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={reportCard.leagueID}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {reportCard.leagueName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    League ID: {reportCard.leagueID}
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Unknown {unknownConfig.typeDisplay}:
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        {reportCard.numUnknowns}
                      </Typography>
                    </Box>

                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="body2" color={reportCard.statusColor}>
                        {reportCard.lastRanAt ? moment(reportCard.lastRanAt).fromNow() : "No data"}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};
