const { Collection } = require("../Collection");

const EVENTS_CONFIG = {
  collectionType: "events",
  collectionName: "Events",
  adapterType: "mongo",
  primaryKey: "eventID",
};
exports.EVENTS_CONFIG = EVENTS_CONFIG;

class EventsCollection extends Collection {
  config = EVENTS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.EventsCollection = EventsCollection;
