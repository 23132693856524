import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Box, Typography, Button, DialogActions } from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";
import { useQueryClient } from "@tanstack/react-query";
import { copyToClipboard } from "@wagerlab/admin/src/shared/clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { regenerateApiKey, sendApiKeyEmail } from "@wagerlab/admin/src/apiKeys/database";
import { presentErrorMessage, presentInfoMessage, presentSuccessMessage } from "@wagerlab/admin/src/utils/alerts";

export const KeyRegenerator = ({ apiKeyData }) => {
  const queryClient = useQueryClient();
  const [newKeyInfo, setNewKeyInfo] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [keyRegenerating, setKeyRegenerating] = useState(false);

  const handleSendEmail = async () => {
    if (emailSending || emailSent) return;
    setEmailSending(true);
    const newEmailSent = await sendApiKeyEmail(apiKeyData, newKeyInfo?.apiKey);
    if (newEmailSent) {
      presentSuccessMessage(`Successfully sent API key to ${apiKeyData?.email}`);
      setEmailSent(true);
    } else {
      presentErrorMessage("Failed to send API key email. See console logs for details");
    }
    setEmailSending(false);
  };

  const handleRegenerate = async () => {
    if (keyRegenerating) return;
    setKeyRegenerating(true);
    const confirmed = window.confirm("Are you sure you want to re-generate this user's API key? Doing so will permanently destroy their existing key making it unusable. This cannot be reversed");
    if (!confirmed) return setKeyRegenerating(false);
    const confirmedAgain = window.confirm(
      `Are you double-sure you want to do this? ${
        apiKeyData?.email || "UNKNOWN EMAIL"
      } will lose access to their existing key. You will get to see the new key once it is generated and you will have the option to send them an email with that new key.`
    );
    if (!confirmedAgain) return setKeyRegenerating(false);

    const newApiKeyInfo = await regenerateApiKey(apiKeyData?.apiKeyHash);
    if (newApiKeyInfo) {
      setNewKeyInfo(newApiKeyInfo);
    } else {
      presentErrorMessage("Failed to regenerate API key. See console logs for details");
    }
    setKeyRegenerating(false);
  };

  const handleClose = () => {
    setEmailSent(false);
    setEmailSending(false);
    setKeyRegenerating(false);
    if (newKeyInfo) {
      queryClient.invalidateQueries({ queryKey: ["apiKeys"] });
      setNewKeyInfo(null);
    }
  };

  return (
    <>
      <IconButton Icon={KeyOffIcon} onClick={handleRegenerate} loading={keyRegenerating} color="primary" title="Regenerate API Key" />
      <Dialog open={!!newKeyInfo} maxWidth="md" fullWidth>
        <DialogTitle>New API Key Generated</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 3 }}>
            <Box
              sx={{
                p: 2,
                bgcolor: "grey.100",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography sx={{ fontFamily: "monospace", fontSize: "1.1rem" }}>{newKeyInfo?.apiKey}</Typography>
              <IconButton
                Icon={ContentCopyIcon}
                onClick={() => {
                  copyToClipboard(newKeyInfo?.apiKey);
                }}
                size="small"
                color="primary"
              />
            </Box>
            <Typography sx={{ fontFamily: "monospace", fontSize: "0.7rem" }}>API Key Hash: {newKeyInfo?.apiKeyHash}</Typography>

            <Typography color="warning.main">Note: This key will not be shown again after closing this window</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={emailSent || emailSending} onClick={handleSendEmail} sx={{ alignSelf: "flex-start" }}>
            {emailSending ? "Sending..." : emailSent ? `Email sent to ${apiKeyData?.email}` : `Send API Key to ${apiKeyData?.email}`}
          </Button>
          <Button onClick={handleClose} variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
