import { Button, Box, Typography, IconButton, Grid } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/shared/inputs/TextField";
import { SelectBoolean } from "@wagerlab/admin/src/shared/inputs/SelectBoolean";
import { useFormikContext, FieldArray } from "formik";
import _ from "lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AFFILIATES_LIST_FIELD_CONFIG = {
  affiliateID: { defaultValue: "", type: "string", label: "Affiliate ID", preventChanges: true },
  enabled: { defaultValue: true, type: "boolean", label: "Enabled", tooltip: "Whether this affiliate is currently enabled and active or not" },
  name: { defaultValue: "", type: "string", label: "Name" },
  title: { defaultValue: "", type: "string", label: "Title" },
  cta: { defaultValue: "", type: "string", label: "Call to Action" },
  bannerImg: { defaultValue: "", type: "string", label: "Banner Image URL" },
  bonusCardImg: { defaultValue: "", type: "string", label: "Bonus Card Image URL" },
  defaultLanding: { defaultValue: "", type: "string", label: "Default Landing URL" },
  darkColor: { defaultValue: "", type: "string", label: "Dark Color" },
  accentColor: { defaultValue: "", type: "string", label: "Accent Color" },
  lightColor: { defaultValue: "", type: "string", label: "Light Color" },
  whiteLogoImg: { defaultValue: "", type: "string", label: "White Logo Image URL" },
  colorLogoImg: { defaultValue: "", type: "string", label: "Color Logo Image URL" },
  wannaBetPromptText: { defaultValue: "", type: "string", label: "Wanna Bet Prompt Text" },
  wannaBetButtonText: { defaultValue: "", type: "string", label: "Wanna Bet Button Text" },
  bottomSheetTitleText: { defaultValue: "", type: "string", label: "Bottom Sheet Title Text" },
  bottomSheetSubTitleText: { defaultValue: "", type: "string", label: "Bottom Sheet Subtitle Text" },
  jsInjections: { defaultValue: [], type: "array", label: "JS Injections", optional: true },
  promoCode: { defaultValue: "", type: "string", label: "Promo Code", optional: true },
  successUrlContains: { defaultValue: "", type: "string", label: "Success URL Contains", optional: true },
};
const AFFILIATE_FORM_BASIC_FIELD_ORDER = [
  "affiliateID",
  "enabled",
  "name",
  "title",
  "cta",
  "bannerImg",
  "defaultLanding",
  "darkColor",
  "accentColor",
  "lightColor",
  "whiteLogoImg",
  "colorLogoImg",
  "bonusCardImg",
  "wannaBetPromptText",
  "wannaBetButtonText",
  "bottomSheetTitleText",
  "bottomSheetSubTitleText",
  "promoCode",
  "successUrlContains",
];

export const ConfigAffiliatesList = ({ prevConfig }) => {
  const { values, errors, touched, initialValues } = useFormikContext();

  return (
    <Box sx={{ margin: 2 }}>
      <FieldArray name="affiliatesList">
        {({ push, remove }) => (
          <>
            {values.affiliatesList.map((affiliate, index) => (
              <Box key={`${affiliate?.affiliateID || index}`} sx={{ marginBottom: 5, border: "1px solid #ccc", padding: 1 }}>
                <Typography variant="h4" sx={{ textTransform: "uppercase", marginBottom: 1 }}>
                  {affiliate?.affiliateID || affiliate?.name || `Affiliate #${index + 1}`}
                </Typography>
                <Grid container spacing={2}>
                  {AFFILIATE_FORM_BASIC_FIELD_ORDER.map((fieldName) => {
                    const fieldConfig = AFFILIATES_LIST_FIELD_CONFIG?.[fieldName];
                    const initialFieldValue = initialValues?.affiliatesList?.[index]?.[fieldName];
                    const fieldValue = affiliate?.[fieldName];
                    const shouldPreventChanges = !!(fieldConfig?.preventChanges && initialFieldValue);
                    const fieldError = touched?.affiliatesList?.[index]?.[fieldName] && errors?.affiliatesList?.[index]?.[fieldName];
                    if (fieldConfig?.type === "string") {
                      return (
                        <Grid item xs={12} sm={6} key={fieldName}>
                          <TextField
                            fullWidth
                            tooltip={fieldConfig?.tooltip || ""}
                            disabled={shouldPreventChanges}
                            label={fieldConfig?.label || fieldName || "? DO_NOT_SAVE ?"}
                            name={`affiliatesList[${index}].${fieldName}`}
                            error={fieldError}
                            required={!fieldConfig?.optional}
                          />
                        </Grid>
                      );
                    } else if (fieldConfig?.type === "boolean") {
                      return (
                        <Grid item xs={12} sm={6} key={fieldName}>
                          <SelectBoolean
                            fullWidth
                            tooltip={fieldConfig?.tooltip || ""}
                            disabled={shouldPreventChanges}
                            label={fieldConfig?.label || fieldName || "? DO_NOT_SAVE ?"}
                            name={`affiliatesList[${index}].${fieldName}`}
                            error={fieldError}
                            required={!fieldConfig?.optional}
                          />
                        </Grid>
                      );
                    }

                    return null;
                  })}
                </Grid>

                <FieldArray name={`affiliatesList[${index}].jsInjections`}>
                  {({ push: pushJs, remove: removeJs }) => (
                    <>
                      <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                        {AFFILIATES_LIST_FIELD_CONFIG?.jsInjections?.label}
                      </Typography>
                      {affiliate.jsInjections &&
                        affiliate.jsInjections.map((injection, jsIndex) => (
                          <Box key={jsIndex} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <TextField
                              label={"Element ID"}
                              name={`affiliatesList[${index}].jsInjections[${jsIndex}].elementID`}
                              error={touched?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.elementID && errors?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.elementID}
                              required={true}
                              // value={injection.elementID}
                              // onChange={handleChange}
                            />
                            <TextField
                              label={"Injected Placeholder"}
                              name={`affiliatesList[${index}].jsInjections[${jsIndex}].injectedPlaceholder`}
                              error={touched?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.injectedPlaceholder && errors?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.injectedPlaceholder}
                              // value={injection.injectedPlaceholder}
                              // onChange={handleChange}
                            />
                            <TextField
                              label={"Injected Value"}
                              name={`affiliatesList[${index}].jsInjections[${jsIndex}].injectedValue`}
                              error={touched?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.injectedValue && errors?.affiliatesList?.[index]?.jsInjections?.[jsIndex]?.injectedValue}
                              // value={injection.injectedValue}
                              // onChange={handleChange}
                            />
                            <IconButton onClick={() => removeJs(jsIndex)}>
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Box>
                        ))}
                      <Button startIcon={<AddCircleOutlineIcon />} onClick={() => pushJs({ elementID: "", injectedPlaceholder: "", injectedValue: "" })}>
                        Add JS Injection
                      </Button>
                    </>
                  )}
                </FieldArray>

                {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                  <Button startIcon={<RemoveCircleOutlineIcon />} color="error" onClick={() => remove(index)}>
                    Remove Affiliate
                  </Button>
                </Box> */}
              </Box>
            ))}
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                const initialAffiliateValues = _.mapValues(AFFILIATES_LIST_FIELD_CONFIG, "defaultValue");
                push(initialAffiliateValues);
              }}
              size={"large"}
            >
              Add New Affiliate
            </Button>
          </>
        )}
      </FieldArray>
    </Box>
  );
};
