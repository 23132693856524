const { stripNullValues } = require("@wagerlab/utils/data/mutations");
const { isString, isArray, isDate, isNumber } = require("@wagerlab/utils/data/types");
const { getLoggerModule } = require("@wagerlab/utils/modules");
const _ = require("lodash");

let LOGGING_CONTEXT = null;

const LOG = {
  debug: {
    type: "debug",
    messagePrefix: "[WLDEBUG]",
    logFuncName: "debug",
    level: 1,
    includeContext: true,
  },
  info: {
    type: "info",
    messagePrefix: "[WLINFO]",
    logFuncName: "info",
    level: 2,
    includeContext: true,
  },
  warn: {
    type: "warn",
    messagePrefix: "[WLWARN]",
    logFuncName: "warn",
    level: 3,
    includeContext: true,
  },
  error: {
    type: "error",
    messagePrefix: "[WLERROR]",
    logFuncName: "error",
    level: 4,
    includeContext: true,
  },
  log: {
    type: "log",
    messagePrefix: "",
    logFuncName: "log",
    level: 2,
    includeContext: true,
  },
  metric: {
    type: "metric",
    messagePrefix: "[METRIC]",
    logFuncName: "log",
    level: 5,
    includeContext: false,
  },
};
exports.LOG = LOG;

const METRIC_TYPES = {
  COUNTER: "COUNTER",
  LATENCY: "LATENCY",
};
exports.METRIC_TYPES = METRIC_TYPES;

const logError = (message, data = null) => writeLog(message, data, "error");
exports.logError = logError;

const logWarning = (message, data = null) => writeLog(message, data, "warn");
exports.logWarning = logWarning;

const logInfo = (message, data = null) => writeLog(message, data, "info");
exports.logInfo = logInfo;

const logDebug = (message, data = null) => writeLog(message, data, "debug");
exports.logDebug = logDebug;

const logCounter = (metricName, metricValue = 1, additionalData = null) => {
  if (!metricName) return;
  return writeMetric(metricName, metricValue, METRIC_TYPES.COUNTER, additionalData);
};
exports.logCounter = logCounter;

const logDuration = (metricName, startTimeMs, endTimeMs, additionalData = null) => {
  if (!metricName || typeof endTimeMs !== "number" || typeof startTimeMs !== "number" || startTimeMs > endTimeMs) return;
  const metricValue = endTimeMs - startTimeMs;
  return writeMetric(metricName, metricValue, METRIC_TYPES.LATENCY, additionalData);
};
exports.logDuration = logDuration;

const setLoggingContext = (loggingContext) => {
  if (!loggingContext || typeof loggingContext !== "object" || Array.isArray(loggingContext) || Object.keys(loggingContext).length === 0) {
    LOGGING_CONTEXT = null;
  } else {
    LOGGING_CONTEXT = loggingContext;
  }
};
exports.setLoggingContext = setLoggingContext;

const writeLog = (inputMessage, inputData, logType) => {
  const { messagePrefix, logFuncName, level, includeContext } = LOG[logType] || LOG.log || {};
  if (!inputMessage || !logFuncName) return;
  const logger = getLoggerModule();
  const logFunc = logger[logFuncName];
  if (!logFunc) return;
  const { logAs, context, minLogLevel } = logger;
  if ((minLogLevel || 0) > level) return;
  const message = messagePrefix ? `${messagePrefix} ${inputMessage}` : inputMessage;

  if (logAs === "message") return logFunc(message);

  if (logAs === "mesage,payload" && !inputData && !includeContext) return logFunc(message);
  if (logAs === "mesage,payload" && !inputData && !LOGGING_CONTEXT && !context) return logFunc(message);

  const inputDataObj = inputData && typeof inputData === "object" && !Array.isArray(inputData) ? inputData : inputData ? { NON_OBJ_LOG_DATA: inputData } : {};

  const payload = formatPayload({
    ...(includeContext ? context || {} : {}),
    ...(includeContext ? LOGGING_CONTEXT || {} : {}),
    ...inputDataObj,
    ...(logAs === "payload" || logAs === "stringify" ? { message } : {}),
  });

  if (logAs === "payload") return logFunc(payload);
  if (logAs === "stringify") return logFunc(JSON.stringify(payload));
  return logFunc(message, payload);
};

const writeMetric = (metricName, metricValue, metricType, additionalData) => {
  const logger = getLoggerModule();
  const { enabled } = logger?.metrics || {};
  if (!enabled) return;
  if (!metricName || !isNumber(metricValue)) return;
  if (metricType !== METRIC_TYPES.COUNTER && metricType !== METRIC_TYPES.LATENCY) return;

  const metricContext = { ...(additionalData || {}), metricName, metricValue };
  return writeLog(`${metricName} = ${metricValue}`, metricContext, "metric");
};

const formatPayload = (payload) => {
  if (payload?.error) payload.error = formatError(payload.error);
  if (payload?.axiosError) payload.axiosError = formatAxiosError(payload.axiosError);
  if (payload?.req) payload.req = formatRequest(payload.req);
  return payload;
};

const formatAxiosError = (axiosError) => {
  let axiosUrl = "";
  try {
    const axiosBaseUrl = axiosError?.config?.url || "";
    const axiosParams = axiosError?.config?.params ? `?${new URLSearchParams(axiosError?.config?.params).toString()}` : "";
    axiosUrl = axiosBaseUrl + axiosParams;
  } catch (err) {
    //no-op
  }

  const formattedAxiosError = {};
  if (axiosUrl) formattedAxiosError.axiosUrl = axiosUrl;
  if (axiosError?.response?.status) formattedAxiosError.statusCode = axiosError?.response?.status;
  if (axiosError?.response?.statusText) formattedAxiosError.statusText = axiosError?.response?.statusText;
  if (axiosError?.message) formattedAxiosError.errorMessage = axiosError?.message;
  if (axiosError?.stack) formattedAxiosError.stackTrace = axiosError?.stack;
  if (axiosError?.code) formattedAxiosError.errorCode = axiosError?.code;
  if (axiosError?.response?.data) formattedAxiosError.responseData = axiosError?.response?.data;
  return formattedAxiosError;
};

const formatError = (error) => {
  if (!error) return null;
  const { name, message, stack } = error;

  const formattedError = {
    name,
    message,
    stack,
    // errorObject: JSON.stringify(error), //TODO deal with circular error caused by this
    errorString: error?.toString?.() || `${error}`,
  };
  return formattedError;
};

const formatRequest = (req) => {
  if (!req) return null;

  const ips = !req.ips ? [] : isArray(req.ips) ? req.ips : isString(req.ips) ? req.ips.split(",") : [];
  const forwardedForIPs = req.headers?.["x-forwarded-for"] ? req.headers?.["x-forwarded-for"]?.split?.(",") || [] : [];
  const allIPsSet = new Set([forwardedForIPs[0], req.ip, req.connection?.remoteAddress, ...(forwardedForIPs.slice(1) || []), ...ips]);
  const allIPs = Array.from(allIPsSet).filter((ip) => !!ip && ip !== "::ffff:" && ip !== "::1" && ip !== "unknown");

  return stripNullValues({
    method: req.method,
    path: req.path,
    url: req.originalUrl || req.url,
    baseUrl: req.baseUrl,
    query: req.query,
    headers: {
      "user-agent": req.headers?.["user-agent"],
      "origin": req.headers?.origin,
      "referer": req.headers?.referer || req.headers?.referrer,
      "x-forwarded-for": req.headers?.["x-forwarded-for"],
      // "content-type": req.headers?.["content-type"],
      // "accept": req.headers?.accept,
      // "x-api-key": req.headers?.["x-api-key"],
    },
    ip: allIPs[0] || null,
    ips: allIPs,
    country: req.ipInfo?.country || req.headers["cloudfront-viewer-country"] || req.headers["cf-ipcountry"],
    region: req.ipInfo?.region || req.headers["cloudfront-viewer-country-region"] || null,
    // Uncomment if we need metrics on security protocols
    // protocol: req.protocol,
    // secure: req.secure,
    // xhr: req.xhr,
  });
};

// const opentelemetry = require("@opentelemetry/api");
// const { ValueType } = require("@opentelemetry/api");
// // Docs: https://github.com/GoogleCloudPlatform/opentelemetry-operations-js/blob/main/packages/opentelemetry-cloud-monitoring-exporter/README.md
// const writeMetric_OLD_OPENTELEMETRY = (metricName, metricValue, metricType, additionalData) => {
//   const logger = getLoggerModule();
//   const { metricLoggers, enabled, useOpenTelemetry } = logger?.metrics || {};
//   if (!enabled) return;
//   if (!metricName || !isNumber(metricValue)) return;
//   if (metricType !== METRIC_TYPES.COUNTER && metricType !== METRIC_TYPES.LATENCY) return;

//   const metricContext = Object.fromEntries(Object.entries(additionalData || {}).filter(([k, v]) => (v && isString(v)) || isNumber(v)));

//   if (!useOpenTelemetry) {
//     metricContext.metricName = metricName;
//     metricContext.metricValue = metricValue;
//     return writeLog(`${metricName} = ${metricValue}`, metricContext, "metric");
//   }

//   const metricLoggerKey = `${metricName}-${metricType}`;
//   const metricMeter = opentelemetry.metrics.getMeter("wl-metrics-meter");
//   if (!metricMeter || !metricLoggers) return;

//   if (!metricLoggers.has(metricLoggerKey)) {
//     const metricPath = `CUSTOM_METRICS/${metricName}`;
//     const newMetricLogger =
//       metricType === METRIC_TYPES.COUNTER
//         ? metricMeter.createCounter(metricPath, { description: `${metricType} for ${metricName}`, valueType: ValueType.INT })
//         : metricMeter.createHistogram(metricPath, { description: `${metricType} for ${metricName}`, unit: "ms", valueType: ValueType.INT });
//     metricLoggers.set(metricLoggerKey, newMetricLogger);
//   }

//   const metricLogger = metricLoggers.get(metricLoggerKey);
//   if (metricType === METRIC_TYPES.COUNTER) {
//     return metricLogger.add(metricValue ?? 1, metricContext);
//   } else {
//     return metricLogger.record(metricValue, metricContext);
//   }
// };
