const { copyData, setObjVal } = require("@wagerlab/utils/data/mutations");
const _ = require("lodash");
const { isDate, isNumber } = require("@wagerlab/utils/data/types");
const { getOpponentOddID } = require("@wagerlab/utils/odds/identifiers");
const { getOddScore } = require("@wagerlab/utils/odds/scoring");
const { eventRequiresManualScoring } = require("@wagerlab/utils/events/getters");
const { parseTimestamp } = require("@wagerlab/utils/data/dates");
const { sourceStatusEnabled } = require("@wagerlab/utils/aggregator/sourceStatus");

// Values updates Jan 2025 due to complaints of slowness. Can revert if we see problems.
// const FORCE_FINALIZE_AFTER_STATUS_ENDED_HOURS = 12;
// const FORCE_FINALIZE_AFTER_ALL_SOURCES_FINAL_HOURS = 1;
// const MAX_PERCENT_MISSING_PLAYER_PROPS = 0.1;
// const MAX_PERCENT_MISSING_NON_PLAYER_PROPS = 0;
// const MAX_PERCENT_MISSING_SCORE_MISMATCHES = 0;

const FORCE_FINALIZE_AFTER_STATUS_ENDED_HOURS = 2;
const FORCE_FINALIZE_AFTER_ALL_SOURCES_FINAL_HOURS = 1;
const MAX_PERCENT_MISSING_PLAYER_PROPS = 0.1;
const MAX_PERCENT_MISSING_NON_PLAYER_PROPS = 0.05; // Covers all wagers except player props (ex: main markets + game/team props)
const MAX_PERCENT_MISSING_SCORE_MISMATCHES = 0.05;

exports.refreshEventFinalized = (eventData, canAutoFinalize) => {
  const isManuallyScoredEvent = eventRequiresManualScoring(eventData);
  const { cancelled, ended, finalized: prevFinalized } = eventData?.status || {};
  const canFinalizeBecausePrevFinalized = prevFinalized;
  const canFinalizeBecauseCancelled = cancelled;
  const canFinalizeBecauseManualEnded = ended && isManuallyScoredEvent;

  if (canFinalizeBecauseCancelled || canFinalizeBecauseManualEnded || canFinalizeBecausePrevFinalized) {
    return setFinalized(eventData, true);
  }

  if (!ended) {
    _.unset(eventData, "status.oddsFinalizeAt");
    return setFinalized(eventData, false);
  }
  if (canAutoFinalize) {
    return setFinalized(eventData, true);
  }

  const { hasDisabledSource, hasUnfinalizedSource } = checkEventSourceStatuses(eventData);

  const nowTimestamp = Date.now();
  let oddsFinalizeAtTimestamp = parseTimestamp(eventData?.status?.oddsFinalizeAt);

  if (hasUnfinalizedSource) {
    let newOddsFinalizeAtTimestamp = nowTimestamp + FORCE_FINALIZE_AFTER_STATUS_ENDED_HOURS * 60 * 60 * 1000;
    if (!oddsFinalizeAtTimestamp || newOddsFinalizeAtTimestamp < oddsFinalizeAtTimestamp) oddsFinalizeAtTimestamp = newOddsFinalizeAtTimestamp;
  } else if (hasDisabledSource || checkEventMissingScores(eventData)) {
    let newOddsFinalizeAtTimestamp = nowTimestamp + FORCE_FINALIZE_AFTER_ALL_SOURCES_FINAL_HOURS * 60 * 60 * 1000;
    if (!oddsFinalizeAtTimestamp || newOddsFinalizeAtTimestamp < oddsFinalizeAtTimestamp) oddsFinalizeAtTimestamp = newOddsFinalizeAtTimestamp;
  } else {
    oddsFinalizeAtTimestamp = 0;
  }

  if (oddsFinalizeAtTimestamp) {
    const oddsFinalizeAtDate = new Date(oddsFinalizeAtTimestamp);
    setObjVal(eventData, "status.oddsFinalizeAt", oddsFinalizeAtDate);
    return setFinalized(eventData, nowTimestamp >= oddsFinalizeAtTimestamp);
  } else {
    return setFinalized(eventData, true);
  }
};

const checkEventSourceStatuses = (eventData) => {
  let hasDisabledSource = false;
  let hasUnfinalizedSource = false;
  Object.values(eventData?.sourceContext || {}).forEach((singleSourceContext) => {
    const { supportsEventScores, supportsEventStats, supportsPlayerStats, eventScoresFinal, eventStatsFinal, playerStatsFinal, eventStatusFinal, sourceStatus } = singleSourceContext || {};
    if (!sourceStatusEnabled(sourceStatus)) {
      hasDisabledSource = true;
      return;
    }

    const eventScoresUnfinalized = supportsEventScores && (!eventScoresFinal || !eventStatusFinal);
    const eventStatsUnfinalized = supportsEventStats && (!eventStatsFinal || !eventStatusFinal);
    const playerStatsUnfinalized = supportsPlayerStats && (!playerStatsFinal || !eventStatusFinal);

    if (eventScoresUnfinalized || eventStatsUnfinalized || playerStatsUnfinalized) {
      hasUnfinalizedSource = true;
    }
  });
  return { hasDisabledSource, hasUnfinalizedSource };
};

const checkEventMissingScores = (eventData) => {
  let playerPropsChecked = 0;
  let playerPropsFailed = 0;

  let nonPlayerPropsChecked = 0;
  let nonPlayerPropsFailed = 0;

  let scoreMismatchesChecked = 0;
  let scoreMismatchesFailed = 0;
  Object.entries(eventData?.odds || {}).forEach(([oddID, eventOddData]) => {
    if (!eventOddData?.scoringSupported || eventOddData?.cancelled) return;
    const isPlayerProp = !!eventOddData?.playerID;
    const score = eventOddData?.score;
    const hasScore = isNumber(score);

    if (isPlayerProp) {
      playerPropsChecked++;
      if (!hasScore) playerPropsFailed++;
    } else {
      nonPlayerPropsChecked++;
      if (!hasScore) nonPlayerPropsFailed++;
    }

    // Remove the below block if we don't want to prevent finalization on score mismatches
    const { periodID, statEntityID, statID } = eventOddData || {};
    if (hasScore && periodID && statEntityID && statID) {
      const copiedEventResults = copyData(eventData?.results || {});
      _.unset(copiedEventResults, `${periodID}.${statEntityID}.${statID}`);
      const altScore = getOddScore(eventOddData, { ...eventData, results: copiedEventResults }, true);
      if (isNumber(altScore)) {
        scoreMismatchesChecked++;
        // Sometimes the calculated score can be slightly different due to percentage calculations so we allow it to be off by 1
        if (altScore > score + 1 || altScore < score - 1) scoreMismatchesFailed++;
      }
    }
  });

  const percentFailedPlayerProps = playerPropsChecked > 0 ? playerPropsFailed / playerPropsChecked : 0;
  const percentFailedNonPlayerProps = nonPlayerPropsChecked > 0 ? nonPlayerPropsFailed / nonPlayerPropsChecked : 0;
  const percentFailedScoreMismatches = scoreMismatchesChecked > 0 ? scoreMismatchesFailed / scoreMismatchesChecked : 0;

  return (
    percentFailedPlayerProps > MAX_PERCENT_MISSING_PLAYER_PROPS ||
    percentFailedNonPlayerProps > MAX_PERCENT_MISSING_NON_PLAYER_PROPS ||
    percentFailedScoreMismatches > MAX_PERCENT_MISSING_SCORE_MISMATCHES
  );
};

// This just sweeps through and cleans up some of the values in cases where we finalized the odds
const setFinalized = (event, finalized) => {
  if (finalized) {
    setObjVal(event, "status.finalized", true);
    setObjVal(event, "status.ended", true);
    setObjVal(event, "status.live", false);
    setObjVal(event, "status.delayed", false);
    _.unset(event, "status.oddsFinalizeAt");
  } else {
    setObjVal(event, "status.finalized", false);
  }

  const { finalized: eventFinalized, cancelled: eventCancelled, ended: eventEnded } = event?.status || {};

  const eventOddIDsVisited = {};
  Object.keys(event?.odds || {}).forEach((oddID_main) => {
    const eventOddData_main = event?.odds?.[oddID_main];
    const oddID_opponent = getOpponentOddID(eventOddData_main);
    const eventOddData_opponent = event?.odds?.[oddID_opponent];
    if (!oddID_main && !oddID_opponent) return;
    if (oddID_main && eventOddIDsVisited[oddID_main]) return;
    if (oddID_opponent && eventOddIDsVisited[oddID_opponent]) return;
    if (oddID_main) eventOddIDsVisited[oddID_main] = true;
    if (oddID_opponent) eventOddIDsVisited[oddID_opponent] = true;

    let started = !!(eventOddData_main?.started || eventOddData_opponent?.started);
    let cancelled = !!(eventOddData_main?.cancelled || eventOddData_opponent?.cancelled);
    let ended = !!(eventOddData_main?.ended || eventOddData_opponent?.ended);
    let score_main = eventOddData_main?.score;
    let score_opponent = eventOddData_opponent?.score;
    if (isNumber(score_main) && !isNumber(score_opponent)) score_opponent = 0;
    if (isNumber(score_opponent) && !isNumber(score_main)) score_main = 0;

    const hasAnyScore = isNumber(score_main) || isNumber(score_opponent);
    const hasAnyNonZeroScore = (isNumber(score_main) && score_main !== 0) || (isNumber(score_opponent) && score_opponent !== 0);
    started = started || hasAnyNonZeroScore || (eventFinalized && hasAnyScore);
    ended = ended || eventEnded || eventCancelled || eventFinalized;
    cancelled = cancelled || eventCancelled || (eventFinalized && !hasAnyScore);
    if (cancelled) {
      score_main = null;
      score_opponent = null;
    }

    if (oddID_opponent && eventOddData_opponent?.oddID) {
      setObjVal(event, `odds.${oddID_opponent}.started`, !!started);
      setObjVal(event, `odds.${oddID_opponent}.cancelled`, !!cancelled);
      setObjVal(event, `odds.${oddID_opponent}.ended`, !!ended);
      setObjVal(event, `odds.${oddID_opponent}.score`, score_opponent);
    }
    if (oddID_main && eventOddData_main?.oddID) {
      setObjVal(event, `odds.${oddID_main}.started`, !!started);
      setObjVal(event, `odds.${oddID_main}.cancelled`, !!cancelled);
      setObjVal(event, `odds.${oddID_main}.ended`, !!ended);
      setObjVal(event, `odds.${oddID_main}.score`, score_main);
    }
  });

  return event;
};
