const { Database } = require("@wagerlab/utils/database/Database");
const { logError } = require("@wagerlab/utils/logging");

const MONGO_CONFIG = {
  adapterType: "mongo",
};
exports.MONGO_CONFIG = MONGO_CONFIG;

class MongoDatabase extends Database {
  static singletonInstance = null;
  static type = "mongo";

  client = null;
  db = null;

  constructor() {
    super();
    this.client = null;
    this.db = null;
  }

  static getInstance() {
    if (!MongoDatabase.singletonInstance) {
      MongoDatabase.singletonInstance = new MongoDatabase();
    }
    return MongoDatabase.singletonInstance;
  }

  connect(options) {
    this.connectPromise = this.connectPromise || this.connectToMongo(options);
    return this.connectPromise;
  }

  disconnect() {
    return this.disconnectFromMongo();
  }

  async disconnectFromMongo() {
    if (!this.connected) return true;
    const success = await this.client
      .close()
      .then(() => true)
      .catch((error) => {
        logError("MongoDatabase:disconnect error", { error });
        return false;
      });
    this.connected = false;
    this.connectPromise = null;
    this.client = null;
    this.db = null;
    return success;
  }

  async connectToMongo(options) {
    if (this.connected) return true;
    const mongodb = options?.mongodb;
    const { uri, dbName } = options?.mongoInitOptions || {};

    if (!uri || !dbName) {
      logError("MongoDatabase:connect error: connection string and database name are required", { uri, dbName });
      return false;
    }
    if (!this.client && !mongodb?.MongoClient) {
      logError("MongoDatabase:connect error: MongoDB client module not available", { uri, dbName });
      return false;
    }
    const MONGO_CONNECT_OPTIONS = {
      minPoolSize: 5, //default is 0, should be small
      w: 1, // default is 'majority'
      maxConnecting: 3,
      connectTimeoutMS: 45000, // 1 minute
      // maxIdleTimeMS: 3600000, // 1 hour
      // socketTimeoutMS: 3600000, // 1 hour
      // timeoutMS: 600000, // 10 minutes
      retryReads: true,
      retryWrites: true,
    };
    this.client = this.client || new mongodb.MongoClient(uri, MONGO_CONNECT_OPTIONS);
    try {
      await this.client.connect();
      this.db = this.client.db(dbName);
      this.connected = true;
      return true;
    } catch (error) {
      logError("MongoDatabase:connect error: MongoDB connection error", { uri, dbName, error });
      return false;
    }
  }
}

exports.MongoDatabase = MongoDatabase;
