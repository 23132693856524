import React, { useState, useMemo } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Select, MenuItem, Typography, Box, FormControlLabel, Checkbox } from "@mui/material";
import { Formik, Form } from "formik";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";
import { useQueryClient } from "@tanstack/react-query";
import { updateApiKey } from "@wagerlab/admin/src/apiKeys/database";
import { copyData, setObjVal } from "@wagerlab/utils/data/mutations";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { TIER_CONFIG } from "@wagerlab/utils/sgo/apiConfig";
import { SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { BOOKMAKER_CONFIG } from "@wagerlab/utils/odds/bookmakerConfig";
import { SelectMenu } from "@wagerlab/admin/src/shared/inputs/SelectMenu";
import { isEmpty } from "@wagerlab/utils/data/types";
import { RATE_LIMITING_INTERVALS, RATE_LIMITING_TYPES } from "@wagerlab/utils/sgo/apiConfig";
import { parsePositiveInteger } from "@wagerlab/utils/data/numbers";
import { TextField } from "@wagerlab/admin/src/shared/inputs/TextField";
import _ from "lodash";

//TODO: Still need to do the "other" fields. Probably also worth checking to ensure those fields are still properly hooked up too.

const SPORT_OPTIONS = Object.entries(SPORT_CONFIG).reduce((sportOpts, [sportID, sportConfig]) => ({ ...sportOpts, [sportID]: sportConfig?.name || sportConfig?.shortName || sportID }), {});
const LEAGUE_OPTIONS = Object.entries(LEAGUE_CONFIG).reduce((leagueOpts, [leagueID, leagueConfig]) => ({ ...leagueOpts, [leagueID]: leagueConfig?.name || leagueConfig?.shortName || leagueID }), {});
const BOOKMAKER_OPTIONS = Object.entries(BOOKMAKER_CONFIG).reduce((bookmakerOpts, [bookmakerID, bookmakerConfig]) => ({ ...bookmakerOpts, [bookmakerID]: bookmakerConfig?.name || bookmakerID }), {});

const TIER_OPTIONS = Object.keys(TIER_CONFIG).reduce((tierOpts, tier) => ({ ...tierOpts, [tier]: tier.toUpperCase() }), {});

const expandIncludeField = (contractedData, options) => {
  const expandedData = {};
  Object.keys(options).forEach((optionID) => {
    const isEnabled = contractedData?.[optionID] ?? contractedData?.DEFAULT ?? false;
    expandedData[optionID] = !!isEnabled;
  });
  return expandedData;
};

const contractIncludeField = (expandedData, options) => {
  const contractedData = { DEFAULT: false };
  let anyDisabled = false;
  let anyEnabled = false;
  Object.keys(options).forEach((optionID) => {
    const isEnabled = !!expandedData?.[optionID];
    contractedData[optionID] = isEnabled;
    if (isEnabled) anyEnabled = true;
    else anyDisabled = true;
  });
  if (!anyEnabled && !anyDisabled) return null;
  else if (anyEnabled && !anyDisabled) return { DEFAULT: true };
  else if (!anyEnabled && anyDisabled) return { DEFAULT: false };

  return contractedData;
};

export const TierUpdater = ({ apiKeyData }) => {
  const queryClient = useQueryClient();
  const [isVisible, setIsVisible] = useState(false);

  const initialValues = useMemo(() => {
    return {
      tier: apiKeyData?.tier,
      includeSports: expandIncludeField(apiKeyData?.accessLimiting?.includeSports || {}, SPORT_OPTIONS),
      includeLeagues: expandIncludeField(apiKeyData?.accessLimiting?.includeLeagues || {}, LEAGUE_OPTIONS),
      includeBookmakers: expandIncludeField(apiKeyData?.accessLimiting?.includeBookmakers || {}, BOOKMAKER_OPTIONS),
      // includeOthers: copyData(apiKeyData?.accessLimiting?.includeOthers || {}),
      rateLimits: copyData(apiKeyData?.rateLimiting?.limits || {}),
    };
  }, [apiKeyData]);

  const handleTierChange = (newTier, setFieldValue) => {
    const tierConfig = TIER_CONFIG[newTier];
    if (!tierConfig) return;

    setFieldValue("tier", newTier);
    setFieldValue("includeSports", expandIncludeField(tierConfig.accessLimiting?.includeSports || {}, SPORT_OPTIONS));
    setFieldValue("includeLeagues", expandIncludeField(tierConfig.accessLimiting?.includeLeagues || {}, LEAGUE_OPTIONS));
    setFieldValue("includeBookmakers", expandIncludeField(tierConfig.accessLimiting?.includeBookmakers || {}, BOOKMAKER_OPTIONS));
    // setFieldValue("includeOthers", copyData(tierConfig.accessLimiting?.includeOthers || {}));
    setFieldValue("rateLimits", copyData(tierConfig.rateLimiting?.limits || {}));
  };

  const handleSubmit = async (values) => {
    const updates = {};
    if (values?.tier) updates.tier = values.tier;
    if (!isEmpty(values?.rateLimits)) updates[`rateLimiting.limits`] = values.rateLimits;

    const newIncludeSports = contractIncludeField(values?.includeSports, SPORT_OPTIONS);
    if (newIncludeSports) updates[`accessLimiting.includeSports`] = newIncludeSports;
    const newIncludeLeagues = contractIncludeField(values?.includeLeagues, LEAGUE_OPTIONS);
    if (newIncludeLeagues) updates[`accessLimiting.includeLeagues`] = newIncludeLeagues;
    const newIncludeBookmakers = contractIncludeField(values?.includeBookmakers, BOOKMAKER_OPTIONS);
    if (newIncludeBookmakers) updates[`accessLimiting.includeBookmakers`] = newIncludeBookmakers;

    const apiKeyUpdated = await updateApiKey(apiKeyData?.apiKeyHash, updates);
    if (!apiKeyUpdated) return alert("Failed to update API key");

    alert("Success!");
    queryClient.invalidateQueries({ queryKey: ["apiKeys"] });
    setIsVisible(false);
  };

  return (
    <>
      <IconButton Icon={ImportExportIcon} onClick={() => setIsVisible(true)} title="Update Tier & Limits" />

      <Dialog open={isVisible} onClose={() => setIsVisible(false)} maxWidth="md" fullWidth>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <DialogTitle>Update Tier & Limits</DialogTitle>
              <DialogContent>
                <Stack spacing={3} sx={{ width: "100%", mt: 2 }}>
                  <SelectMenu
                    name="tier"
                    label="Tier"
                    options={TIER_OPTIONS}
                    onChange={(newTier) => handleTierChange(newTier, setFieldValue)}
                    disabled={isSubmitting}
                    tooltip="Changing the tier will update the access to Sports, Leagues, and Bookmakers to be the TIER'S DEFAULT FOR ALL FIELDS"
                  />

                  <RateLimitsSection rateLimits={values.rateLimits} setFieldValue={setFieldValue} />

                  <IncludeSection title="Sports" includeValues={values.includeSports} options={SPORT_OPTIONS} setFieldValue={setFieldValue} name="includeSports" />

                  <IncludeSection title="Leagues" includeValues={values.includeLeagues} options={LEAGUE_OPTIONS} setFieldValue={setFieldValue} name="includeLeagues" />

                  <IncludeSection title="Bookmakers" includeValues={values.includeBookmakers} options={BOOKMAKER_OPTIONS} setFieldValue={setFieldValue} name="includeBookmakers" />
                </Stack>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setIsVisible(false)}>Cancel</Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const IncludeSection = ({ title, includeValues, options, setFieldValue, name }) => {
  const handleToggleAll = (enabled) => {
    const newValues = {};
    Object.keys(options).forEach((key) => {
      newValues[key] = enabled;
    });
    setFieldValue(name, newValues);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Button size="small" sx={{ ml: 2 }} onClick={() => handleToggleAll(true)}>
          Enable All
        </Button>
        <Button size="small" sx={{ ml: 1 }} onClick={() => handleToggleAll(false)}>
          Disable All
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {Object.entries(options).map(([key, label]) => (
          <FormControlLabel key={key} control={<Checkbox checked={includeValues[key] || false} onChange={(e) => setFieldValue(`${name}.${key}`, e.target.checked)} />} label={label} />
        ))}
      </Box>
    </Box>
  );
};

const RateLimitsSection = ({ rateLimits, setFieldValue }) => {
  const handleLimitChange = (interval, limitType, value) => {
    const parsedValue = parsePositiveInteger(value);
    const newRateLimits = copyData(rateLimits);
    if (parsedValue) setObjVal(newRateLimits, `${interval}.${limitType}`, parsedValue);
    else _.unset(newRateLimits, `${interval}.${limitType}`);
    setFieldValue("rateLimits", newRateLimits);
  };

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Rate Limits
      </Typography>
      <Stack spacing={2}>
        {RATE_LIMITING_INTERVALS.map((interval) => (
          <Box key={interval} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ width: 100, mt: 2, textTransform: "capitalize", fontWeight: "700" }}>{interval}</Typography>
            <Stack direction="row" spacing={2}>
              {RATE_LIMITING_TYPES.map((limitType) => (
                <Box key={limitType}>
                  <TextField
                    name={`rateLimits.${interval}.${limitType}`}
                    label={limitType.toUpperCase()}
                    type="number"
                    size="small"
                    inputProps={{ min: "1" }}
                    value={rateLimits?.[interval]?.[limitType] || ""}
                    onChange={(e) => handleLimitChange(interval, limitType, e.target.value)}
                    variant="outlined"
                    tooltip={`max ${limitType} per ${interval}`}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
