exports.generateRemovedDoc = (id, isDeletedFromDB = null, newData = null) => {
  // If deletedFromDB is true/false then we can use newData.
  // Otherwise, it's unknown whether the removal was due to the document being deleted or no longer matching the query
  if (id) return { _removed: id, _deletedFromDB: isDeletedFromDB, _newData: isDeletedFromDB === true ? null : newData };
  return null;
};

// Used to tell when a document has either been deleted OR no longer matches the query.
exports.listenerRemovedDocWithID = (doc) => doc?._removed || null;

// Used to tell when a document has been deleted from the database - Returns the id of the doc if we know for certain. Otherwise, null;
exports.listenerDeletedDocWithID = (doc) => (doc?._deletedFromDB === true ? doc?._removed || null : null);
// Used to tell when a document no longer matches the query. Returns the id of the doc if we know for certain. Otherwise, null;
exports.listenerUnmatchedDocWithID = (doc) => (doc?._deletedFromDB === false ? doc?._removed || null : null);
exports.listenerRemovedDocNewData = (doc) => (doc?._removed ? doc?._newData || null : null);
