import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { copyData } from "@wagerlab/utils/data/mutations";
import { serializeEventFormData } from "@wagerlab/utils/events/form";

export const queryEvents = async (queryParts, limit, selectFields) => {
  if (!queryParts?.length) return null;
  const result = await callAdminAPI("getEvents", { queryParts, limit, selectFields });
  const { success, events } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return events;
};

export const getEvent = async (eventID) => {
  const result = await callAdminAPI("getEvents", { eventID });
  const { success, event } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return event;
};

export const saveEventForm = async (eventFormData) => {
  const serializedEventFormData = serializeEventFormData(eventFormData);
  if (!serializedEventFormData) return "Failed to serialize event form data";
  const result = await callAdminAPI("saveEventForm", serializedEventFormData);
  const { success, error } = result || {};
  console.log(result);
  if (!success) return error || "Failed to save event form";
  return "";
};
