const { Collection } = require("../Collection");

const LEAGUES_CONFIG = {
  collectionType: "leagues",
  collectionName: "Leagues",
  adapterType: "mongo",
  primaryKey: "leagueID",
};
exports.LEAGUES_CONFIG = LEAGUES_CONFIG;

class LeaguesCollection extends Collection {
  config = LEAGUES_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.LeaguesCollection = LeaguesCollection;
