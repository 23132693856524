const _ = require("lodash");

const isArray = _.isArray;
exports.isArray = isArray;

const isObject = (item) => item !== null && typeof item === "object" && !isArray(item) && !isError(item) && !isDate(item);
exports.isObject = isObject;

const isNumber = (item) => typeof item === "number" && isFinite(item);
exports.isNumber = isNumber;

//Remember when checking for floats numbers like 1.0 are integers but also may be valid floats so only use isNumber to check for floats
const isInteger = (item) => typeof item === "number" && Number.isInteger(item);
exports.isInteger = isInteger;

const isDate = (item) => _.isDate(item) && isNumber(item?.getTime?.());
exports.isDate = isDate;

const isString = _.isString;
exports.isString = isString;

const isBoolean = (item) => item === true || item === false;
exports.isBoolean = isBoolean;

const isError = _.isError;
exports.isError = isError;

const isEmptyObject = (item, checkNested = false) => isObject(item) && Object.keys(item).length === 0;
exports.isEmptyObject = isEmptyObject;

const isEmpty = (item, checkNested = false) => {
  if (item == null || item === "") return true;
  if (isArray(item)) {
    if (item.length === 0) return true;
    if (checkNested) {
      for (const nestedItem of item) {
        if (!isEmpty(nestedItem, checkNested)) return false;
      }
      return true;
    }
    return false;
  }
  if (isObject(item)) {
    const nestedValues = Object.values(item);
    if (nestedValues.length === 0) return true;
    if (checkNested) {
      for (const nestedValue of nestedValues) {
        if (!isEmpty(nestedValue, checkNested)) return false;
      }
      return true;
    }
    return false;
  }
  return false;
};
exports.isEmpty = isEmpty;

const isPositiveInteger = (item) => isInteger(item) && item > 0;
exports.isPositiveInteger = isPositiveInteger;
const isNegativeInteger = (item) => isInteger(item) && item < 0;
exports.isNegativeInteger = isNegativeInteger;
const isNonNegativeInteger = (item) => item === 0 || isPositiveInteger(item);
exports.isNonNegativeInteger = isNonNegativeInteger;

const isPositiveNumber = (item) => isNumber(item) && item > 0;
exports.isPositiveNumber = isPositiveNumber;
const isNegativeNumber = (item) => isNumber(item) && item < 0;
exports.isNegativeNumber = isNegativeNumber;
const isNonNegativeNumber = (item) => item === 0 || isPositiveNumber(item);
exports.isNonNegativeNumber = isNonNegativeNumber;

const stringLength = (item) => (!isString(item) || !isPositiveInteger(item?.length) ? 0 : item.length);
exports.stringLength = stringLength;

const parseBoolean = (item, defaultValue = false, nonBinaryNumbersUseDefault = false) => {
  if (isBoolean(item)) return item;
  if (isString(item)) {
    if (item.toLowerCase() === "true") return true;
    if (item.toLowerCase() === "false") return false;
  }
  if (isNumber(item)) {
    if (item === 0) return false;
    if (item === 1) return true;
    if (nonBinaryNumbersUseDefault) return defaultValue;
    return item !== 0;
  }
  return defaultValue;
};
exports.parseBoolean = parseBoolean;
