import { EventManager } from "@wagerlab/admin/src/events/manage/EventManager";
import { useParams } from "react-router-dom";

export const EditEventPage = () => {
  const pageParams = useParams();

  const eventID = pageParams?.formType === "new" ? null : pageParams?.eventID;

  return <EventManager action={"edit"} eventID={eventID} />;
};
