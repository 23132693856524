import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const MainMenuPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack sx={{ marginTop: 4 }} spacing={2}>
        <Button onClick={() => navigate(`/events/new`)} variant="contained">
          Create New Prop (Custom) Event
        </Button>
        <Button onClick={() => navigate(`/events/list`)} variant="contained">
          Search Events
        </Button>
        <Button onClick={() => navigate(`/config/edit`)} variant="contained">
          Edit App Config
        </Button>
        <Button onClick={() => navigate(`/link/teams`)} variant="contained">
          Link Team IDs
        </Button>
        <Button onClick={() => navigate(`/link/players`)} variant="contained">
          Link Player IDs
        </Button>
        <Button onClick={() => navigate(`/api/keys`)} variant="contained">
          View API Keys
        </Button>
        <Button onClick={() => navigate(`/execute`)} variant="contained">
          Execute Admin Action
        </Button>
      </Stack>
    </Box>
  );
};
