const { Database } = require("@wagerlab/utils/database/Database");

const FIRESTORE_CONFIG = {
  adapterType: "firestore",
};
exports.FIRESTORE_CONFIG = FIRESTORE_CONFIG;

class FirestoreDatabase extends Database {
  static singletonInstance = null;
  static type = FIRESTORE_CONFIG.adapterType;

  firebase = null;

  constructor() {
    super();
  }

  static getInstance() {
    if (!FirestoreDatabase.singletonInstance) {
      FirestoreDatabase.singletonInstance = new FirestoreDatabase();
    }
    return FirestoreDatabase.singletonInstance;
  }

  connect(options) {
    this.firebase = this.firebase || options?.firebase;
    this.connected = !!this.firebase;
    return Promise.resolve(this.connected);
  }

  disconnect() {
    return Promise.resolve(true);
  }
}

exports.FirestoreDatabase = FirestoreDatabase;
