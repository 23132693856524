const { Collection } = require("../Collection");

const UNKNOWN_PLAYERS_CONFIG = {
  collectionType: "unknownplayers",
  collectionName: "UnknownPlayers",
  adapterType: "mongo",
  primaryKey: "unknownPlayerID",
};
exports.UNKNOWN_PLAYERS_CONFIG = UNKNOWN_PLAYERS_CONFIG;

class UnknownPlayersCollection extends Collection {
  config = UNKNOWN_PLAYERS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}

exports.UnknownPlayersCollection = UnknownPlayersCollection;
