import React from "react";
import { createRoot } from "react-dom/client";
import "./css/global.css";
// import "react-app-polyfill/stable";
import "@fontsource/roboto/300";
import "@fontsource/roboto/400";
import "@fontsource/roboto/500";
import "@fontsource/roboto/700";
import "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import { App } from "@wagerlab/admin/src/App";
import firebase from "firebase/compat/app";
import { getLogger } from "@wagerlab/admin/src/utils/logger";
import { initialize } from "@wagerlab/utils/initialize";

initialize({
  logger: getLogger(),
  firebase,
  shouldInitFirebase: true,
  firebaseInitOptions: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },
  shouldInitDatabase: false,
  dbTargetTestCollections: false,
  isServer: false,
});

// firebase.functions().useEmulator("localhost", 5001);

createRoot(document.getElementById("root")).render(<App />);
