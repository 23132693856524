const { refreshAndSyncOdds } = require("@wagerlab/utils/events/refresh/refreshAndSyncOdds");
const { refreshEventFinalized } = require("@wagerlab/utils/events/refresh/refreshEventFinalized");
const { refreshEventScores } = require("@wagerlab/utils/events/refresh/refreshEventScores");
const { refreshEventStatus } = require("@wagerlab/utils/events/refresh/refreshEventStatus");
const { refreshOddsOverview } = require("@wagerlab/utils/events/refresh/refreshOddsOverview");
const { refreshEventMetadata } = require("@wagerlab/utils/events/refresh/refreshEventMetadata");
const { refreshEventIndexes } = require("@wagerlab/utils/events/refresh/refreshEventIndexes");
const { validateEvent } = require("@wagerlab/utils/events/refresh/validateEvent");

const refreshEvent = (event, sourceMap = null, canAutoFinalize = false) => {
  if (!event?.eventID) return null;

  event = refreshEventMetadata(event);
  event = refreshEventStatus(event); // Must come after: refreshAndSyncMetadata
  event = refreshEventScores(event); // Must come after: refreshAndSyncMetadata

  event = refreshAndSyncOdds(event, sourceMap); // Must come after: refreshAndSyncMetadata, refreshEventStatus
  event = refreshEventFinalized(event, canAutoFinalize); // Must come after refreshAndSyncMetadata, refreshEventStatus, refreshAndSyncOdds
  event = refreshOddsOverview(event); // Must come after: refreshAndSyncOdds & refreshEventStatus
  event = refreshEventIndexes(event);
  event = validateEvent(event); // Must come after: refreshAndSyncMetadata & refreshAndSyncOdds (both must run. changes needed to run this without running both)

  return event;
};
exports.refreshEvent = refreshEvent;
