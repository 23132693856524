import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { TextButton } from "@wagerlab/admin/src/shared/Buttons";
import { presentErrorMessage, presentQuestion, presentSuccessMessage } from "@wagerlab/admin/src/utils/alerts";

export const ExecutePage = ({}) => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 12, textAlign: "center" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Execute Admin Actions
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "center" }}>
        <ExecuteButton
          signalName="fixWagers"
          buttonText="Fix Wagers"
          color="primary"
          confirmText="Are you sure you want to trigger the Fix Wagers job? This will process all wagers that need fixing."
        />

        <ExecuteButton
          signalName="syncUnfinalizedEvents"
          buttonText="Sync Unfinalized Events"
          color="secondary"
          confirmText="Are you sure you want to trigger the Sync Unfinalized Events job? This will synchronize all events that haven't been finalized yet."
        />
      </Box>
    </Container>
  );
};

const ExecuteButton = ({ signalName, buttonText, color, confirmText }) => {
  const [isExcecuting, setIsExecuting] = useState(false);

  const onError = (error) => {
    console.error(error);
    presentErrorMessage(`Failed to trigger ${signalName}: ${error || ""}`);
    setIsExecuting(false);
  };

  const onSuccess = () => {
    presentSuccessMessage(`Successfully triggered: ${signalName}`);
    setIsExecuting(false);
  };

  const onPress = async () => {
    if (isExcecuting) return;
    const confirmResponse = await presentQuestion(confirmText, ["Cancel", "Submit"]);
    if (confirmResponse !== "Submit") return;
    setIsExecuting(true);
    const result = await callAdminAPI("triggerSignal", { name: signalName, signalName });
    const { success } = result || {};
    if (!success) return onError(result?.error);
    return onSuccess();
  };

  return <TextButton text={buttonText} onClick={onPress} loading={isExcecuting} color={color} />;
};
