import { logError, logInfo } from "@wagerlab/utils/logging";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";

export const callAdminAPI = async (functionName, requestData) => {
  if (!functionName) return { success: false };
  try {
    const adminApiEndpoint = firebase.functions().httpsCallable(`WagerLabAdminAPI-${functionName}`);
    const result = await adminApiEndpoint(requestData);
    logInfo(`response from WagerLabAdminAPI-${functionName}:`, { result });
    return result?.data;
  } catch (error) {
    logError(`Error calling function: ${functionName}`, { error });
    return { success: false, error: error?.message };
  }
};
