const { getIDString } = require("@wagerlab/utils/aggregator/lookups");
const { isPositiveInteger, isDate } = require("@wagerlab/utils/data/types");

const formatPlayerID = (playerFullName, leagueID, playerNum) => {
  if (!playerFullName || !leagueID || !isPositiveInteger(playerNum)) return "";
  const playerNameID = getIDString(playerFullName);
  return `${playerNameID}_${playerNum}_${leagueID}`;
};
exports.formatPlayerID = formatPlayerID;

const getNewPlayerID = (playerFullName, leagueID, getIsPlayerIDAvailable, maxNum = 10) => {
  let playerNameNum = 1;
  while (playerNameNum <= maxNum) {
    const potentialPlayerID = formatPlayerID(playerFullName, leagueID, playerNameNum);
    if (!potentialPlayerID) return "";
    if (getIsPlayerIDAvailable(potentialPlayerID)) return potentialPlayerID;
    playerNameNum++;
  }
  return "";
};
exports.getNewPlayerID = getNewPlayerID;

exports.getPlayerName = (playerData) => {
  return playerData?.names?.display || getFirstLastName(playerData) || "";
};

exports.getAllPlayerFullNames = (playerData) => {
  const displayFullName = playerData?.names?.display;

  const allFullNames = displayFullName ? [displayFullName] : [];

  const firstLastName = getFirstLastName(playerData);
  if (firstLastName && firstLastName !== displayFullName) allFullNames.push(firstLastName);

  return allFullNames;
};

exports.getPlayerSortName = (playerData) => {
  const { display, firstName, lastName, suffix } = playerData?.names || {};

  if (lastName) {
    let lastFirstName = firstName ? `${lastName} ${firstName}` : lastName;
    if (suffix) lastFirstName += ` ${suffix}`;
    return lastFirstName;
  }

  return display || "";
};

const getFirstLastName = (playerData) => {
  const { firstName, lastName, suffix } = playerData?.names || {};
  let firstLastName = firstName && lastName ? `${firstName} ${lastName}` : "";
  if (firstLastName && suffix) firstLastName += ` ${suffix}`;
  return firstLastName;
};
