const { isArray } = require("@wagerlab/utils/data/types");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");

const BET_TYPE_ENUM = {
  ml: "ml",
  sp: "sp",
  ou: "ou",
  eo: "eo",
  yn: "yn",
  ml3way: "ml3way",
  prop: "prop",
};
exports.BET_TYPE_ENUM = BET_TYPE_ENUM;

const SIDE_ID_ENUM = {
  "home": "home",
  "away": "away",
  "draw": "draw",
  "over": "over",
  "under": "under",
  "even": "even",
  "odd": "odd",
  "yes": "yes",
  "no": "no",
  "home+draw": "home+draw",
  "away+draw": "away+draw",
  "not_draw": "not_draw",
  "side1": "side1",
  "side2": "side2",
};
exports.SIDE_ID_ENUM = SIDE_ID_ENUM;

const BET_TYPE_CONFIG = {
  ml: {
    betTypeID: "ml",
    sideIDs: {
      home: { sideID: "home", display: "Home", opponentSideID: "away" },
      away: { sideID: "away", display: "Away", opponentSideID: "home" },
    },
    hasLine: false,
    name: "Moneyline",
    sideOrder: ["away", "home"],
  },
  sp: {
    betTypeID: "sp",
    sideIDs: {
      home: { sideID: "home", display: "Home", opponentSideID: "away" },
      away: { sideID: "away", display: "Away", opponentSideID: "home" },
    },
    hasLine: true,
    lineKey: "spread",
    name: "Spread",
    sideOrder: ["away", "home"],
  },
  ou: {
    betTypeID: "ou",
    sideIDs: {
      over: { sideID: "over", display: "Over", opponentSideID: "under" },
      under: { sideID: "under", display: "Under", opponentSideID: "over" },
    },
    hasLine: true,
    lineKey: "overUnder",
    canDeactivateWhenOver: true,
    name: "Over/Under",
    sideOrder: ["over", "under"],
  },
  eo: {
    betTypeID: "eo",
    sideIDs: {
      even: { sideID: "even", display: "Even", opponentSideID: "odd" },
      odd: { sideID: "odd", display: "Odd", opponentSideID: "even" },
    },
    hasLine: false,
    name: "Even/Odd",
    sideOrder: ["even", "odd"],
  },
  yn: {
    betTypeID: "yn",
    sideIDs: {
      yes: { sideID: "yes", display: "Yes", opponentSideID: "no", deactivatesWhenOver: true },
      no: { sideID: "no", display: "No", opponentSideID: "yes" },
    },
    hasLine: false,
    impliedLine: 0.5,
    canDeactivateWhenOver: true,
    name: "Yes/No",
    sideOrder: ["yes", "no"],
  },
  ml3way: {
    betTypeID: "ml3way",
    sideIDs: {
      "away": { sideID: "away", display: "Away", opponentSideID: "home+draw" },
      "home": { sideID: "home", display: "Home", opponentSideID: "away+draw" },
      "draw": { sideID: "draw", display: "Draw", opponentSideID: "not_draw" },
      "away+draw": { sideID: "away+draw", display: "Away/Draw", opponentSideID: "home" },
      "home+draw": { sideID: "home+draw", display: "Home/Draw", opponentSideID: "away" },
      "not_draw": { sideID: "not_draw", display: "Home/Away", opponentSideID: "draw" },
    },
    hasLine: false,
    name: "3-Way Moneyline",
    sideOrder: ["away", "home+draw", "home", "away+draw", "draw", "not_draw"],
  },
  prop: {
    betTypeID: "prop",
    sideIDs: {
      side1: { sideID: "side1", display: "Option 1", opponentSideID: "side2" },
      side2: { sideID: "side2", display: "Option 2", opponentSideID: "side1" },
    },
    hasLine: false,
    name: "Prop Bet",
    sideOrder: ["side1", "side2"],
  },
};
exports.BET_TYPE_CONFIG = BET_TYPE_CONFIG;

const ALL_SIDE_IDS = Object.values(BET_TYPE_CONFIG).reduce((allSideIDs, betTypeConfig) => ({ ...allSideIDs, ...(betTypeConfig?.sideIDs || {}) }), {});
exports.ALL_SIDE_IDS = ALL_SIDE_IDS;

const BET_TYPE_ID_ORDER_STANDARD = {
  // Lower values come first
  sp: 1,
  ml: 2,
  ou: 3,
  ml3way: 4,
  yn: 5,
  eo: 6,
  prop: 7,
};
const BET_TYPE_ID_ORDER_PREFERS_3WAY_ML = {
  // Lower values come first
  ml3way: 1,
  sp: 2,
  ou: 3,
  ml: 4,
  yn: 5,
  eo: 6,
  prop: 7,
};
const betTypeIDSorter = (oddData_A, oddData_B, eventData) => {
  const prefers3WayMoneyline = SPORT_CONFIG?.[eventData?.sportID]?.prefers3WayMoneyline;
  const betTypeIDOrder = prefers3WayMoneyline ? BET_TYPE_ID_ORDER_PREFERS_3WAY_ML : BET_TYPE_ID_ORDER_STANDARD;
  return (betTypeIDOrder[oddData_A?.betTypeID] || 0) - (betTypeIDOrder[oddData_B?.betTypeID] || 0);
};
exports.betTypeIDSorter = betTypeIDSorter;

const sideIDSorter = (oddData_A, oddData_B, eventData) => {
  const betTypeIDSortValue = betTypeIDSorter(oddData_A, oddData_B);
  if (betTypeIDSortValue !== 0) return betTypeIDSortValue;
  const sideOrder = BET_TYPE_CONFIG[oddData_A?.betTypeID]?.sideOrder || BET_TYPE_CONFIG[oddData_B?.betTypeID]?.sideOrder || [];
  const sideOrderIndex_A = sideOrder.indexOf(oddData_A?.sideID);
  const sideOrderIndex_B = sideOrder.indexOf(oddData_B?.sideID);
  if (sideOrderIndex_A === sideOrderIndex_B) return 0;
  if (sideOrderIndex_A === -1) return 1;
  if (sideOrderIndex_B === -1) return -1;
  return sideOrderIndex_A - sideOrderIndex_B;
};
exports.sideIDSorter = sideIDSorter;

const sortOddsBySideID = (oddDataList) => {
  if (!isArray(oddDataList)) return oddDataList;
  return oddDataList.sort(sideIDSorter);
};
exports.sortOddsBySideID = sortOddsBySideID;
