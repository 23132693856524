const { isNumber, isObject, isEmptyObject } = require("@wagerlab/utils/data/types");
const { checkStatIsSupportedByEvent, getStatConfig } = require("@wagerlab/utils/sports/stats");
const { isExtraPeriodForLeague, getPeriodConfig } = require("@wagerlab/utils/sports/periods");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");
const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { eventRequiresManualScoring } = require("@wagerlab/utils/events/getters");
const { sourceStatusEnabled } = require("@wagerlab/utils/aggregator/sourceStatus");

// TODO: Consider sweeping through this file and looking at whether we should be using isNumber(x) or x != null. Right now they are treated as equal

const supportsOddScore = (oddData, eventData, sourceMap = null) => {
  const { sportID, leagueID, sourceContext, type } = eventData || {};
  const { statID, statEntityID, periodID, playerID, teamID, betTypeID } = oddData || {};
  if (!sportID || !leagueID || !statID || !statEntityID || !periodID) return false;

  if (eventRequiresManualScoring(eventData)) return true;

  if (isSpecialCaseScoring(oddData, eventData)) return supportsOddScore_handleSpecialCase(oddData, eventData, sourceMap);

  if (!checkStatIsSupportedByEvent(statID, statEntityID, eventData)) return false;

  const statConfig = getStatConfig(statID, sportID);
  if (!statConfig?.statID) return false;

  const highLevelSupported = Object.entries(sourceContext || {}).some(([sourceID, context]) => {
    const { supportsEventScores, supportsEventStats, supportsPlayerStats, supportsUniqueProps, sourceStatus } = context || {};
    if (!sourceStatusEnabled(sourceStatus)) return false;
    if (statEntityID === "home" || statEntityID === "away" || statEntityID === "all") {
      if (statConfig?.isScoreStat && !supportsEventScores) return false;
      if (!statConfig?.isScoreStat && !supportsEventStats) return false;
    } else if (statEntityID === "side1" || statEntityID === "side2") {
      if (!supportsUniqueProps) return false;
    } else if (statEntityID === playerID) {
      if (!supportsPlayerStats) return false;
    } else if (statEntityID === teamID) {
      if (type !== EVENT_TYPES.tournament) return false;
      if (statConfig?.isScoreStat && !supportsEventScores) return false;
      if (!statConfig?.isScoreStat && !supportsEventStats) return false;
    } else {
      return false;
    }
    return true;
  });
  if (!highLevelSupported) return false;

  // If no sourceMap is provided, assume that the stat is supported
  if (!sourceMap || !isObject(sourceMap)) return true;

  const periodConfig = getPeriodConfig(periodID);
  const sumParts = [[periodID], ...(periodConfig?.sumParts || [])];
  const calcConfigs = [{ dependencies: [statID], performCalc: (statVals) => statVals?.[statID] }, ...(statConfig?.calculate || [])];

  for (let sumPartsIndex = 0; sumPartsIndex < sumParts.length; sumPartsIndex++) {
    const periodIDs = sumParts[sumPartsIndex];
    if (!periodIDs?.length) continue;
    for (let calcIndex = 0; calcIndex < calcConfigs.length; calcIndex++) {
      const calcConfig = calcConfigs[calcIndex];
      if (!calcConfig) continue;
      if (calcConfig?.canCalc && !calcConfig.canCalc(oddData, eventData)) continue;
      const canCalculate = canCalculateScore(calcConfig, periodIDs, statEntityID, oddData, eventData, sourceMap);
      if (canCalculate) return true;
    }
  }

  return false;
};
exports.supportsOddScore = supportsOddScore;

const getOddScore = (oddData, eventData, nullDefault = false) => {
  const { periodID, statEntityID, statID, playerID, teamID } = oddData || {};
  if (!periodID || !statEntityID || !statID) return null;
  if (eventData?.status?.cancelled) return null;
  if (!eventData?.status?.started && !eventData?.status?.ended && !eventData?.status?.completed) return null;

  if (eventRequiresManualScoring(eventData)) return eventData?.results?.[periodID]?.[statEntityID]?.[statID] ?? null;
  if (isSpecialCaseScoring(oddData, eventData)) return getOddScore_handleSpecialCase(oddData, eventData);

  const periodConfig = getPeriodConfig(periodID);
  const statConfig = getStatConfig(statID, eventData?.sportID);
  const sumParts = [[periodID], ...(periodConfig?.sumParts || [])];
  const calcConfigs = [{ dependencies: [statID], performCalc: (statVals) => statVals?.[statID] }, ...(statConfig?.calculate || [])];

  for (let sumPartsIndex = 0; sumPartsIndex < sumParts.length; sumPartsIndex++) {
    const periodIDs = sumParts[sumPartsIndex];
    if (!periodIDs?.length) continue;
    for (let calcIndex = 0; calcIndex < calcConfigs.length; calcIndex++) {
      const calcConfig = calcConfigs[calcIndex];
      if (!calcConfig) continue;
      if (calcConfig?.canCalc && !calcConfig.canCalc(oddData, eventData)) continue;
      const calculatedScore = calculateScore(calcConfig, periodIDs, statEntityID, oddData, eventData, nullDefault);
      if (isNumber(calculatedScore)) return calculatedScore;
    }
  }

  return getDefaultStatValue(periodID, statEntityID, statID, oddData, eventData, nullDefault);
};
exports.getOddScore = getOddScore;

const getDefaultStatValue = (periodID, statEntityID, statID, origOddData, eventData, nullDefault = false) => {
  if (!periodID || !statEntityID || !statID || !eventData?.status?.ended || nullDefault) return null;
  const { playerID, teamID } = origOddData || {};
  if (playerID && statEntityID === playerID) {
    // We could make this dependent on them being on the same team if we encounter cases where a source has stats on one team but not the other
    const hasStatForOtherPlayer = Object.keys(eventData?.results?.[periodID] || {}).some(
      (entityID) => entityID && entityID !== playerID && eventData?.players?.[entityID] && isNumber(eventData?.results?.[periodID]?.[entityID]?.[statID])
    );
    if (hasStatForOtherPlayer) return 0;
  } else if (teamID && statEntityID === teamID) {
    const hasStatForOtherTeam = Object.keys(eventData?.results?.[periodID] || {}).some(
      (entityID) => entityID && entityID !== teamID && eventData?.teams?.[entityID] && isNumber(eventData?.results?.[periodID]?.[entityID]?.[statID])
    );
    if (hasStatForOtherTeam) return 0;
  } else if (statEntityID === "home" || statEntityID === "away") {
    const opposingStatEntityID = statEntityID === "home" ? "away" : "home";
    const hasOpposingValue = isNumber(eventData?.results?.[periodID]?.[opposingStatEntityID]?.[statID]);
    if (hasOpposingValue) return 0;
  }
  return null;
};

const calculateScore = (calcConfig, periodIDs, statEntityID, origOddData, eventData, nullDefault = false) => {
  const defaultDependencyValue = calcConfig?.defaultDependencyValue;
  const hasDefaultDependencyValue = isNumber(defaultDependencyValue);
  let hasAnyValue = false;
  const requirementValues = { currentEntity: {}, otherEntities: {} };
  const otherEntityEntries = Object.entries(calcConfig?.otherEntityDependencies || {});
  for (let otherEntityIndex = 0; otherEntityIndex < otherEntityEntries.length; otherEntityIndex++) {
    const [reqStatEntityID, reqStatList] = otherEntityEntries[otherEntityIndex] || [];
    if (!reqStatEntityID || !reqStatList?.length) return null;
    for (let reqStatIndex = 0; reqStatIndex < reqStatList.length; reqStatIndex++) {
      const reqStatID = reqStatList[reqStatIndex];
      const reqStatValue = combineStatValues(periodIDs, reqStatEntityID, reqStatID, origOddData, eventData, nullDefault);
      if (isNumber(reqStatValue)) {
        hasAnyValue = true;
        setObjVal(requirementValues, `otherEntities.${reqStatEntityID}.${reqStatID}`, reqStatValue);
      } else if (hasDefaultDependencyValue) {
        setObjVal(requirementValues, `otherEntities.${reqStatEntityID}.${reqStatID}`, defaultDependencyValue);
      } else {
        return null;
      }
    }
  }
  for (let depStatIndex = 0; depStatIndex < calcConfig?.dependencies?.length || 0; depStatIndex++) {
    const reqStatID = calcConfig?.dependencies[depStatIndex];
    const reqValue = combineStatValues(periodIDs, statEntityID, reqStatID, origOddData, eventData, nullDefault);
    if (isNumber(reqValue)) {
      hasAnyValue = true;
      setObjVal(requirementValues, `currentEntity.${reqStatID}`, reqValue);
    } else if (hasDefaultDependencyValue) {
      setObjVal(requirementValues, `currentEntity.${reqStatID}`, defaultDependencyValue);
    } else {
      return null;
    }
  }
  if (!hasAnyValue) return null;
  return calcConfig?.performCalc?.(requirementValues.currentEntity, requirementValues.otherEntities);
};

const combineStatValues = (periodIDs, statEntityID, statID, origOddData, eventData, nullDefault = false) => {
  if (!periodIDs?.length || !statEntityID || !statID) return null;

  const statConfig = getStatConfig(statID, eventData?.sportID);
  const isBinary = statConfig?.binary;
  const aggregationMethod = statConfig?.aggregationMethod;
  let periodsToCombine = [];
  let combinationMethod = "sum";
  if (aggregationMethod === "first") {
    const firstPeriodID = periodIDs[0];
    periodsToCombine = firstPeriodID ? [firstPeriodID] : [];
  } else if (aggregationMethod === "last") {
    const lastPeriodID = periodIDs.findLast((pid) => {
      const periodConfig = getPeriodConfig(pid);
      if (!periodConfig || periodConfig?.sumPartsOnePointMode) return false;
      if (eventData?.results?.[pid] && !isEmptyObject(eventData.results[pid])) return true;
      return !isExtraPeriodForLeague(pid, eventData?.sportID, eventData?.leagueID);
    });
    periodsToCombine = lastPeriodID ? [lastPeriodID] : [];
  } else if (aggregationMethod === "ratio" || aggregationMethod === "percent" || aggregationMethod === "none") {
    periodsToCombine = periodIDs.length === 1 ? periodIDs : [];
  } else if (aggregationMethod === "min") {
    combinationMethod = "min";
    periodsToCombine = periodIDs;
  } else if (aggregationMethod === "max") {
    combinationMethod = "max";
    periodsToCombine = periodIDs;
  } else {
    periodsToCombine = periodIDs;
  }
  if (!periodsToCombine.length) return null;
  let hasAnyValue = false;
  let score = null;
  for (let periodIndex = 0; periodIndex < periodsToCombine.length; periodIndex++) {
    const periodID = periodsToCombine[periodIndex];
    const lookupValue = lookupSingleStatValue(periodID, statEntityID, statID, origOddData, eventData, nullDefault);
    if (lookupValue == null) continue;
    hasAnyValue = true;
    if (score == null) {
      score = lookupValue;
    } else if (combinationMethod === "min") {
      score = Math.min(score, lookupValue);
    } else if (combinationMethod === "max") {
      score = Math.max(score, lookupValue);
    } else {
      score += lookupValue;
    }
  }
  if (!hasAnyValue) return null;
  if (isBinary) return score > 0 ? 1 : 0;
  return score;
};

const lookupSingleStatValue = (periodID, statEntityID, statID, origOddData, eventData, nullDefault = false) => {
  const periodConfig = getPeriodConfig(periodID);
  if (!periodID || !statEntityID || !statID || !periodConfig) return null;

  let statValue = eventData?.results?.[periodID]?.[statEntityID]?.[statID] ?? null;
  if (periodConfig?.sumPartsOnePointMode) {
    if (statID !== "points") return null;
    if (statEntityID === "all") {
      if (!isNumber(statValue)) {
        // Note: The aggregationMethod for the points statID should always be "sum". If this ever changes, we'll need to change the below code
        const homeValue = eventData?.results?.[periodID]?.["home"]?.[statID] ?? null;
        const awayValue = eventData?.results?.[periodID]?.["away"]?.[statID] ?? null;
        if (isNumber(homeValue) || isNumber(awayValue)) statValue = (homeValue ?? 0) + (awayValue ?? 0);
      }
      if (!isNumber(statValue)) return null;
      return statValue > 0 ? 1 : 0;
    } else if (statEntityID === "home" || statEntityID === "away") {
      const opposingStatEntityID = statEntityID === "home" ? "away" : "home";
      const opposingStatValue = eventData?.results?.[periodID]?.[opposingStatEntityID]?.[statID] ?? null;
      if (!isNumber(statValue) && !isNumber(opposingStatValue)) return null;
      return (statValue ?? 0) > (opposingStatValue ?? 0) ? 1 : 0;
    } else {
      return null;
    }
  }

  if (!isNumber(statValue)) {
    if (statEntityID === "all") {
      const aggregationMethod = getStatConfig(statID, eventData?.sportID)?.aggregationMethod || "sum";
      const canAggregateHomeAway = aggregationMethod === "sum" || aggregationMethod === "min" || aggregationMethod === "max";
      let homeRawValue = eventData?.results?.[periodID]?.["home"]?.[statID] ?? null;
      let awayRawValue = eventData?.results?.[periodID]?.["away"]?.[statID] ?? null;
      const hasValueToAggregate = isNumber(homeRawValue) || isNumber(awayRawValue);
      if (canAggregateHomeAway && hasValueToAggregate) {
        const homeStatValue = homeRawValue ?? 0;
        const awayStatValue = awayRawValue ?? 0;
        if (aggregationMethod === "min") return Math.min(homeStatValue, awayStatValue);
        if (aggregationMethod === "max") return Math.max(homeStatValue, awayStatValue);
        return homeStatValue + awayStatValue;
      }
    } else {
      statValue = getDefaultStatValue(periodID, statEntityID, statID, origOddData, eventData, nullDefault);
    }
  }

  return statValue;
};

const canCalculateScore = (calcConfig, periodIDs, statEntityID, origOddData, eventData, sourceMap) => {
  const defaultDependencyValue = calcConfig?.defaultDependencyValue;
  const hasDefaultDependencyValue = isNumber(defaultDependencyValue);
  const otherEntityEntries = Object.entries(calcConfig?.otherEntityDependencies || {});
  let canCalcSomeValue = false;
  for (let otherEntityIndex = 0; otherEntityIndex < otherEntityEntries.length; otherEntityIndex++) {
    const [reqStatEntityID, reqStatList] = otherEntityEntries[otherEntityIndex] || [];
    if (!reqStatEntityID || !reqStatList?.length) return false;
    for (let reqStatIndex = 0; reqStatIndex < reqStatList.length; reqStatIndex++) {
      const reqStatID = reqStatList[reqStatIndex];
      const canCalcAcrossPeriods = canCombineStatValues(periodIDs, reqStatEntityID, reqStatID, origOddData, eventData, sourceMap);
      if (canCalcAcrossPeriods) {
        canCalcSomeValue = true;
      } else if (!hasDefaultDependencyValue) {
        return false;
      }
    }
  }
  for (let depStatIndex = 0; depStatIndex < calcConfig?.dependencies?.length || 0; depStatIndex++) {
    const reqStatID = calcConfig?.dependencies[depStatIndex];
    const canCalcAcrossPeriods = canCombineStatValues(periodIDs, statEntityID, reqStatID, origOddData, eventData, sourceMap);
    if (canCalcAcrossPeriods) {
      canCalcSomeValue = true;
    } else if (!hasDefaultDependencyValue) {
      return false;
    }
  }
  if (!canCalcSomeValue) return false;
  return true;
};

const canCombineStatValues = (periodIDs, statEntityID, statID, origOddData, eventData, sourceMap) => {
  if (!periodIDs?.length || !statEntityID || !statID) return false;

  const statConfig = getStatConfig(statID, eventData?.sportID);
  const aggregationMethod = statConfig?.aggregationMethod;
  let periodsToCombine = [];
  if (aggregationMethod === "first") {
    const firstPeriodID = periodIDs[0];
    periodsToCombine = firstPeriodID ? [firstPeriodID] : [];
  } else if (aggregationMethod === "last") {
    const lastPeriodID = periodIDs.findLast((pid) => {
      const periodConfig = getPeriodConfig(pid);
      if (!periodConfig || periodConfig?.sumPartsOnePointMode) return false;
      if (eventData?.results?.[pid] && !isEmptyObject(eventData.results[pid])) return true;
      return !isExtraPeriodForLeague(pid, eventData?.sportID, eventData?.leagueID);
    });
    periodsToCombine = lastPeriodID ? [lastPeriodID] : [];
  } else if (aggregationMethod === "ratio" || aggregationMethod === "percent" || aggregationMethod === "none") {
    periodsToCombine = periodIDs.length === 1 ? periodIDs : [];
  } else {
    periodsToCombine = periodIDs;
  }
  if (!periodsToCombine.length) return false;
  for (let periodIndex = 0; periodIndex < periodsToCombine.length; periodIndex++) {
    const periodID = periodsToCombine[periodIndex];
    const canGet = canLookupSingleStatValue(periodID, statEntityID, statID, origOddData, eventData, sourceMap);
    if (!canGet) return false;
  }
  return true;
};

const canLookupSingleStatValue = (periodID, statEntityID, statID, origOddData, eventData, sourceMap) => {
  const periodConfig = getPeriodConfig(periodID);
  if (!periodID || !statEntityID || !statID || !periodConfig) return false;

  const isSupported = Object.entries(eventData?.sourceContext || {}).some(([sourceID, sourceLevelContext]) => {
    if (!sourceStatusEnabled(sourceLevelContext?.sourceStatus)) return false;
    const source = sourceMap[sourceID];
    if (!source?.willProvideStat) return false;
    return source.willProvideStat(statID, statEntityID, periodID, origOddData?.teamID, origOddData?.playerID, eventData?.sportID, eventData?.leagueID);
  });

  if (!isSupported && statEntityID === "all") {
    const aggregationMethod = getStatConfig(statID, eventData?.sportID)?.aggregationMethod || "sum";
    const canAggregateHomeAway = aggregationMethod === "sum" || aggregationMethod === "min" || aggregationMethod === "max";
    if (!canAggregateHomeAway) return false;
    return canLookupSingleStatValue(periodID, "home", statID, origOddData, eventData, sourceMap) && canLookupSingleStatValue(periodID, "away", statID, origOddData, eventData, sourceMap);
  }

  return isSupported;
};

// If we start using statEntityID.js we should configure this there
const is3WaySpecialCase = (oddData, eventData) => oddData?.statEntityID === "all" && oddData?.betTypeID === "ml3way";

const isSpecialCaseScoring = (oddData, eventData) => {
  if (is3WaySpecialCase(oddData, eventData)) return true;
  return false;
};
exports.isSpecialCaseScoring = isSpecialCaseScoring;

const getOddScore_handleSpecialCase = (oddData, eventData) => {
  if (is3WaySpecialCase(oddData, eventData)) {
    const homeScore = getOddScore({ ...oddData, statEntityID: "home" }, eventData);
    const awayScore = getOddScore({ ...oddData, statEntityID: "away" }, eventData);
    if (!isNumber(homeScore) && !isNumber(awayScore)) return null;
    return (homeScore ?? 0) - (awayScore ?? 0);
  }
  return null;
};

const supportsOddScore_handleSpecialCase = (oddData, eventData, sourceMap) => {
  if (is3WaySpecialCase(oddData, eventData)) {
    return supportsOddScore({ ...oddData, statEntityID: "home" }, eventData, sourceMap) && supportsOddScore({ ...oddData, statEntityID: "away" }, eventData, sourceMap);
  }
  return false;
};

// return getPeriodStatSumParts(oddData?.periodID, oddData?.statEntityID, oddData?.statID)
//     .map((sumPartPeriodIDs) => {
//       let score = null;
//       let numNonExtraPeriods = 0;
//       let numNonExtraPeriodsWithScores = 0;
//       let numTotalPeriods = 0;
//       let numTotalPeriodsWithScores = 0;
//       (sumPartPeriodIDs || []).forEach((sumPartPeriodID) => {
//         const sumPartScore = calculatePeriodSumPartScore(sumPartPeriodID, oddData, (o) => getOddScore(o, eventData, canCalculate, false));
//         const hasSumPartScore = isNumber(sumPartScore);
//         numTotalPeriods++;
//         const isNonExtra = sumPartPeriodID && !isExtraPeriodForLeague(sumPartPeriodID, eventData?.sportID, eventData?.leagueID);
//         if (isNonExtra) numNonExtraPeriods++;
//         if (hasSumPartScore) {
//           numTotalPeriodsWithScores++;
//           score = (score ?? 0) + sumPartScore;
//           if (isNonExtra) numNonExtraPeriodsWithScores++;
//         }
//       });
//       return { score, numNonExtraPeriods, numNonExtraPeriodsWithScores, numTotalPeriods, numTotalPeriodsWithScores };
//     })
//     .sort((scoreDataA, scoreDataB) => {
//       const hasScoreA = isNumber(scoreDataA?.score) ? 1 : 0;
//       const hasScoreB = isNumber(scoreDataB?.score) ? 1 : 0;
//       if (!hasScoreA && !hasScoreB) return 0;
//       if (hasScoreA !== hasScoreB) return hasScoreB - hasScoreA;
//       const aNonExtraWithScoresCount = isNumber(scoreDataA?.numNonExtraPeriodsWithScores) ? scoreDataA.numNonExtraPeriodsWithScores : 0;
//       const bNonExtraWithScoresCount = isNumber(scoreDataB?.numNonExtraPeriodsWithScores) ? scoreDataB.numNonExtraPeriodsWithScores : 0;
//       const aNonExtraPercentage = isNumber(scoreDataA?.numNonExtraPeriods) ? aNonExtraWithScoresCount / scoreDataA.numNonExtraPeriods : 0;
//       const bNonExtraPercentage = isNumber(scoreDataB?.numNonExtraPeriods) ? bNonExtraWithScoresCount / scoreDataB.numNonExtraPeriods : 0;
//       if (aNonExtraPercentage !== bNonExtraPercentage) return bNonExtraPercentage - aNonExtraPercentage;
//       const aTotalWithScoresCount = isNumber(scoreDataA?.numTotalPeriodsWithScores) ? scoreDataA.numTotalPeriodsWithScores : 0;
//       const bTotalWithScoresCount = isNumber(scoreDataB?.numTotalPeriodsWithScores) ? scoreDataB.numTotalPeriodsWithScores : 0;
//       const aTotalPercentage = isNumber(scoreDataA?.numTotalPeriods) ? aTotalWithScoresCount / scoreDataA.numTotalPeriods : 0;
//       const bTotalPercentage = isNumber(scoreDataB?.numTotalPeriods) ? bTotalWithScoresCount / scoreDataB.numTotalPeriods : 0;
//       if (aTotalPercentage !== bTotalPercentage) return bTotalPercentage - aTotalPercentage;
//       if (aNonExtraWithScoresCount !== bNonExtraWithScoresCount) return bNonExtraWithScoresCount - aNonExtraWithScoresCount;
//       if (aTotalWithScoresCount !== bTotalWithScoresCount) return bTotalWithScoresCount - aTotalWithScoresCount;
//       return 0;
//     })[0]?.score;
