const { TEAM_TYPES } = require("@wagerlab/utils/sports/enums");

const LEAGUE_CONFIG = {
  AHL: {
    enabled: true,
    name: "AHL",
    shortName: "AHL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_imageicon.png?alt=media&token=fbc6cc0d-139f-46d4-99a8-0d2a91aa6462",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_squareImage.jpg?alt=media&token=dc3235dd-dd79-48ef-a266-4a28fbf3d11d",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_backgroundImage.jpg?alt=media&token=75587b97-577c-466a-b2a8-4d0de3797050",
    leagueID: "AHL",
    longName: "American Hockey League",
    sportID: "HOCKEY",
    defaultPopularityScore: 3.0,
  },
  ATP: {
    enabled: true,
    name: "ATP",
    shortName: "ATP",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
    leagueID: "ATP",
    longName: "ATP - Men's Tennis",
    sportID: "TENNIS",
    defaultPopularityScore: 5.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  BR_SERIE_A: {
    enabled: true,
    name: "Brasileiro Série A",
    shortName: "Brasileiro",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "BR_SERIE_A",
    sportID: "SOCCER",
    defaultPopularityScore: 3.0,
  },
  BUNDESLIGA: {
    enabled: true,
    name: "Bundesliga",
    shortName: "Bundesliga",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "BUNDESLIGA",
    sportID: "SOCCER",
    defaultPopularityScore: 4.0,
  },
  CFL: {
    enabled: true,
    name: "CFL",
    shortName: "CFL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_imageicon.png?alt=media&token=90476918-cc97-44da-ab6b-c0c92963fbba",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_squareImage.jpg?alt=media&token=7f6d0b40-97aa-4bd7-8be6-b4ab38aed5a7",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_backgroundImage.jpg?alt=media&token=f45229ba-09f7-450d-9727-9a5be40aef07",
    leagueID: "CFL",
    longName: "Canadian Football League",
    sportID: "FOOTBALL",
    defaultPopularityScore: 3.0,
  },
  CPBL: {
    enabled: true,
    name: "CPBL",
    shortName: "CPBL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "CPBL",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  EPL: {
    enabled: true,
    name: "Premier League",
    shortName: "EPL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_imageIcon.png?alt=media&token=34de639c-5101-4be1-8fe4-e6a8ae46a775",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_squareImage.jpg?alt=media&token=ae87a421-9f57-4b48-9c66-da0f127c0d1b",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_backgroundImage.jpg?alt=media&token=9433368c-5322-44a6-b9ba-bdf18c833533",
    leagueID: "EPL",
    sportID: "SOCCER",
    defaultPopularityScore: 7.5,
  },
  FR_LIGUE_1: {
    enabled: true,
    name: "Ligue 1",
    shortName: "Ligue 1",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "FR_LIGUE_1",
    sportID: "SOCCER",
    defaultPopularityScore: 3.0,
  },
  INTERNATIONAL_SOCCER: {
    enabled: true,
    name: "International Soccer",
    shortName: "Intl. Soccer",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "INTERNATIONAL_SOCCER",
    sportID: "SOCCER",
    defaultPopularityScore: 5.0,
  },
  IT_SERIE_A: {
    enabled: true,
    name: "Serie A Italy",
    shortName: "Serie A",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "IT_SERIE_A",
    sportID: "SOCCER",
    defaultPopularityScore: 3.0,
  },
  ITF: {
    enabled: false,
    name: "ITF",
    shortName: "ITF",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
    leagueID: "ITF",
    longName: "International Tennis Federation",
    sportID: "TENNIS",
    defaultPopularityScore: 3.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  KBO: {
    enabled: true,
    name: "KBO",
    shortName: "KBO",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "KBO",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  KHL: {
    enabled: true,
    name: "KHL",
    shortName: "KHL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_imageicon.png?alt=media&token=fbc6cc0d-139f-46d4-99a8-0d2a91aa6462",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_squareImage.jpg?alt=media&token=dc3235dd-dd79-48ef-a266-4a28fbf3d11d",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_backgroundImage.jpg?alt=media&token=75587b97-577c-466a-b2a8-4d0de3797050",
    leagueID: "KHL",
    longName: "Kontinental Hockey League",
    sportID: "HOCKEY",
    defaultPopularityScore: 2.0,
  },
  LA_LIGA: {
    enabled: true,
    name: "La Liga",
    shortName: "La Liga",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "LA_LIGA",
    sportID: "SOCCER",
    defaultPopularityScore: 3.0,
  },
  LBPRC: {
    enabled: true,
    name: "LBPRC",
    shortName: "LBPPRC",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "LBPRC",
    longName: "Liga de Béisbol Profesional Roberto Clemente - Puerto Rico",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  LIDOM: {
    enabled: true,
    name: "LIDOM",
    shortName: "LIDOM",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "LIDOM",
    longName: "Liga de Béisbol Profesional de la República Dominicana",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  LIGA_MX: {
    enabled: true,
    name: "Liga MX",
    shortName: "Liga MX",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "LIGA_MX",
    sportID: "SOCCER",
    defaultPopularityScore: 3.0,
  },
  LMP: {
    enabled: true,
    name: "LMP",
    shortName: "LMP",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "LMP",
    longName: "Liga Mexicana del Pacífico",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  LVBP: {
    enabled: true,
    name: "LVBP",
    shortName: "LVBP",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "LVBP",
    longName: "Liga Venezolana de Béisbol Profesional",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  MLB: {
    enabled: true,
    name: "MLB",
    shortName: "MLB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMLB_imageicon.png?alt=media&token=86edf8d5-f5fc-4f47-9ba2-fdd14ce775fa",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMLB_squareImage.jpg?alt=media&token=4e208ab0-a925-4c7d-8f82-63ce8fbcc880",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmlb_backgroundImage.jpg?alt=media&token=7d34fc9c-afa5-4487-8b2b-aa0e021bdc97",
    leagueID: "MLB",
    sportID: "BASEBALL",
    defaultPopularityScore: 7.0,
  },
  MLB_MINORS: {
    enabled: true,
    name: "MLB Minors",
    shortName: "MiLB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "MLB_MINORS",
    longName: "Minor League Baseball",
    sportID: "BASEBALL",
    defaultPopularityScore: 4.0,
  },
  MLS: {
    enabled: true,
    name: "MLS",
    shortName: "MLS",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "MLS",
    sportID: "SOCCER",
    defaultPopularityScore: 6.0,
  },
  NBA: {
    enabled: true,
    name: "NBA",
    shortName: "NBA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNBA_imageicon.png?alt=media&token=2f709a78-02f2-4b7f-9e7f-64974d43b7ec",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNBA_squareImage.jpg?alt=media&token=9e810f26-4c00-49a0-bd44-14902fa59c24",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fnba_backgroundImage.jpg?alt=media&token=2ef50e72-b0a5-49f4-b61e-06d7f02db785",
    leagueID: "NBA",
    sportID: "BASKETBALL",
    defaultPopularityScore: 9.0,
  },
  NBA_G_LEAGUE: {
    enabled: true,
    name: "NBA G-League",
    shortName: "NBA-G",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_imageicon.png?alt=media&token=d8fff787-34b2-4a6c-b0cd-d758a58a1087",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_squareImage.jpg?alt=media&token=0e30ce03-b382-42fb-b1bf-a0f2adc72fea",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_backgroundImage.jpg?alt=media&token=a04ba9e7-6f96-4d94-9f64-48fed55a89f1",
    leagueID: "NBA_G_LEAGUE",
    sportID: "BASKETBALL",
    defaultPopularityScore: 3.0,
  },
  NCAAB: {
    enabled: true,
    name: "College Basketball",
    shortName: "NCAAB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_imageicon.png?alt=media&token=f49d52ff-498f-463d-af42-3a42521c7ba6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_squareImage.jpg?alt=media&token=2d71c002-1b04-488a-a5d3-bb9233e0b661",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_backgroundImage.jpg?alt=media&token=5d9dd590-a8f5-4111-b295-b26c8d63b5fd",
    leagueID: "NCAAB",
    sportID: "BASKETBALL",
    defaultPopularityScore: 7.0,
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot"],
  },
  NCAAF: {
    enabled: true,
    name: "College Football",
    shortName: "NCAAF",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_imageicon.png?alt=media&token=40cdd5c5-5774-40bf-b043-4615b1aa21d6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_squareImage.jpg?alt=media&token=73cf1af9-3fb1-47fa-a6d3-fcb6ca39ac11",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_backgroundImage.jpg?alt=media&token=0a971f6c-6432-4a2c-be28-e8881bc430d2",
    leagueID: "NCAAF",
    sportID: "FOOTBALL",
    defaultPopularityScore: 8.0,
  },
  NFL: {
    enabled: true,
    name: "NFL",
    shortName: "NFL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_imageIcon.png?alt=media&token=7521aca6-d8d6-4264-a513-0b0cddc7d9c5",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_squareImage.jpg?alt=media&token=1dc1ed37-75ef-4cbc-99cd-174602d5bb81",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_backgroundImage.jpg?alt=media&token=ec1b24ce-1a30-44ff-bfc4-ad618bbc4a8c",
    leagueID: "NFL",
    sportID: "FOOTBALL",
    defaultPopularityScore: 10.0,
  },
  NHL: {
    enabled: true,
    name: "NHL",
    shortName: "NHL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_imageicon.png?alt=media&token=9b2c2053-9e17-49fa-add6-874f504e818f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_squareImage.jpg?alt=media&token=89409c21-994e-46bf-b22f-40929da23182",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_backgroundImage.jpg?alt=media&token=6689d1d3-2212-46e9-aeb9-775772524bf3",
    leagueID: "NHL",
    sportID: "HOCKEY",
    defaultPopularityScore: 6.0,
  },
  NPB: {
    enabled: true,
    name: "NPB",
    shortName: "NPB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "NPB",
    longName: "Nippon Professional Baseball",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  PGA_MEN: {
    enabled: true,
    name: "PGA Men",
    shortName: "PGA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_imageicon.png?alt=media&token=3bd873bc-bf22-4e85-9d8c-242af468549b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_squareImage.jpg?alt=media&token=4c4f3ebd-2b45-4184-87ab-6a24d4050cb2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_backgroundImage.jpg?alt=media&token=aa7de464-56a5-49a9-87cd-390221035834",
    leagueID: "PGA_MEN",
    longName: "PGA Tour Golf - Men",
    sportID: "GOLF",
    defaultPopularityScore: 6.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  LIV_TOUR: {
    enabled: true,
    name: "LIV Golf",
    shortName: "LIV",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_imageicon.png?alt=media&token=3bd873bc-bf22-4e85-9d8c-242af468549b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_squareImage.jpg?alt=media&token=4c4f3ebd-2b45-4184-87ab-6a24d4050cb2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_backgroundImage.jpg?alt=media&token=aa7de464-56a5-49a9-87cd-390221035834",
    leagueID: "LIV_TOUR",
    longName: "LIV Tour Golf - Men",
    sportID: "GOLF",
    defaultPopularityScore: 6.0,
    basePeriods: ["1r", "2r", "3r"],
    extraPeriods: ["4r"],
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  PGA_WOMEN: {
    enabled: false,
    name: "PGA Women",
    shortName: "LPGA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_imageicon.png?alt=media&token=3bd873bc-bf22-4e85-9d8c-242af468549b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_squareImage.jpg?alt=media&token=4c4f3ebd-2b45-4184-87ab-6a24d4050cb2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_backgroundImage.jpg?alt=media&token=aa7de464-56a5-49a9-87cd-390221035834",
    leagueID: "PGA_WOMEN",
    longName: "PGA Tour Golf - Women",
    sportID: "GOLF",
    defaultPopularityScore: 4.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  SHL: {
    enabled: true,
    name: "SHL",
    shortName: "SHL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_imageicon.png?alt=media&token=fbc6cc0d-139f-46d4-99a8-0d2a91aa6462",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_squareImage.jpg?alt=media&token=dc3235dd-dd79-48ef-a266-4a28fbf3d11d",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_backgroundImage.jpg?alt=media&token=75587b97-577c-466a-b2a8-4d0de3797050",
    leagueID: "SHL",
    longName: "Swedish Hockey League",
    sportID: "HOCKEY",
    defaultPopularityScore: 2.0,
  },
  UEFA_CHAMPIONS_LEAGUE: {
    enabled: true,
    name: "Champions League",
    shortName: "UCL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "UEFA_CHAMPIONS_LEAGUE",
    sportID: "SOCCER",
    defaultPopularityScore: 6.0,
  },
  UEFA_EUROPA_LEAGUE: {
    enabled: true,
    name: "UEFA Europa League",
    shortName: "EURO",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    leagueID: "UEFA_EUROPA_LEAGUE",
    sportID: "SOCCER",
    defaultPopularityScore: 5.0,
  },
  UFC: {
    enabled: true,
    name: "UFC",
    shortName: "UFC",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_imageicon.png?alt=media&token=1492fefa-1547-43df-a5a5-4ed81f8ffe65",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_squareImage.jpg?alt=media&token=10c02ea6-bfc8-4995-b9f1-b0653f0d37e8",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmma_backgroundImage.jpg?alt=media&token=2dbae8d6-4c16-41f2-b088-40cc565b07fc",
    leagueID: "UFC",
    sportID: "MMA",
    defaultPopularityScore: 6.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  USFL: {
    enabled: true,
    name: "USFL",
    shortName: "USFL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_imageicon.png?alt=media&token=90476918-cc97-44da-ab6b-c0c92963fbba",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_squareImage.jpg?alt=media&token=7f6d0b40-97aa-4bd7-8be6-b4ab38aed5a7",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_backgroundImage.jpg?alt=media&token=f45229ba-09f7-450d-9727-9a5be40aef07",
    leagueID: "USFL",
    sportID: "FOOTBALL",
    defaultPopularityScore: 4.0,
  },
  WBC: {
    enabled: true,
    name: "WBC",
    shortName: "WBC",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    leagueID: "WBC",
    longName: "World Baseball Classic",
    sportID: "BASEBALL",
    defaultPopularityScore: 2.0,
  },
  WNBA: {
    enabled: true,
    name: "WNBA",
    shortName: "WNBA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_imageicon.png?alt=media&token=d8fff787-34b2-4a6c-b0cd-d758a58a1087",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_squareImage.jpg?alt=media&token=0e30ce03-b382-42fb-b1bf-a0f2adc72fea",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_backgroundImage.jpg?alt=media&token=a04ba9e7-6f96-4d94-9f64-48fed55a89f1",
    leagueID: "WNBA",
    sportID: "BASKETBALL",
    defaultPopularityScore: 4.0,
  },
  WTA: {
    enabled: true,
    name: "Women's Tennis",
    shortName: "WTA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
    leagueID: "WTA",
    longName: "WTA - Women's Tennis",
    sportID: "TENNIS",
    defaultPopularityScore: 4.0,
    teamType: TEAM_TYPES.SINGLE_PLAYER,
  },
  XFL: {
    enabled: true,
    name: "XFL",
    shortName: "XFL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_imageicon.png?alt=media&token=90476918-cc97-44da-ab6b-c0c92963fbba",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_squareImage.jpg?alt=media&token=7f6d0b40-97aa-4bd7-8be6-b4ab38aed5a7",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_backgroundImage.jpg?alt=media&token=f45229ba-09f7-450d-9727-9a5be40aef07",
    leagueID: "XFL",
    sportID: "FOOTBALL",
    defaultPopularityScore: 5.0,
  },
  IHF_SUPER_GLOBE: {
    enabled: true,
    name: "IHF Super Globe",
    shortName: "Super Globe",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    leagueID: "IHF_SUPER_GLOBE",
    sportID: "HANDBALL",
    defaultPopularityScore: 2.0,
  },
  EHF_EURO_CUP: {
    enabled: true,
    name: "EHF European Cup",
    shortName: "European Cup",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    leagueID: "EHF_EURO_CUP",
    sportID: "HANDBALL",
    defaultPopularityScore: 3.0,
  },
  ASOBAL: {
    enabled: true,
    name: "Liga ASOBAL",
    shortName: "ASOBAL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    leagueID: "ASOBAL",
    sportID: "HANDBALL",
    defaultPopularityScore: 2.0,
  },
  SEHA: {
    enabled: true,
    name: "SEHA Liga",
    shortName: "SEHA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    leagueID: "SEHA",
    sportID: "HANDBALL",
    defaultPopularityScore: 2.0,
  },
  EHF_EURO: {
    enabled: true,
    name: "EHF European League",
    shortName: "EHF",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    leagueID: "EHF_EURO",
    sportID: "HANDBALL",
    defaultPopularityScore: 3.0,
  },
  POLITICS: {
    enabled: true,
    name: "Politics",
    shortName: "Politics",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_imageicon.png?alt=media&token=5edb8d7b-72aa-4356-94ed-162551eca737",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_squareImage.jpg?alt=media&token=edaa9987-1086-4c3f-88c7-80a9ad927ccd",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_backgroundImage.jpg?alt=media&token=bbad2d78-8092-46ea-b669-eee3c5092470",
    leagueID: "POLITICS",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 8.0,
  },
  TV: {
    enabled: true,
    name: "TV",
    shortName: "TV",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_imageicon.png?alt=media&token=6714c47d-f70c-4737-b8f2-ce4577cb5af1",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_squareImage.jpg?alt=media&token=5476ef3d-8286-4352-8c83-4e68ca1f0a14",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_backgroundImage.jpg?alt=media&token=cf61b5ef-b68f-4b87-9ba0-dd69713727ac",
    leagueID: "TV",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 8.0,
  },
  MOVIES: {
    enabled: true,
    name: "Movies",
    shortName: "Movies",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMovie_imageicon.png?alt=media&token=f9e5280d-64bd-4bbf-805e-bf9de2199b5c",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOVIES_squareImage.jpg?alt=media&token=163c6447-3074-4e31-b8ea-a97abbfa2cda",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOVIES_backgroundImage.jpg?alt=media&token=1774d16f-864b-45c3-a571-cac165cff76e",
    leagueID: "MOVIES",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
  MUSIC: {
    enabled: true,
    name: "Music",
    shortName: "Music",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMusic_imageicon.png?alt=media&token=9a9329c1-137e-4cf3-b5ef-c62ae5a0146e",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMUSIC_squareImage.jpg?alt=media&token=e2d226e5-f12f-4900-a038-39c8e8b8b472",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMUSIC_backgroundImage.jpg?alt=media&token=31615a04-6ab5-4527-93cd-7541eb87a86b",
    leagueID: "MUSIC",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
  FUN: {
    enabled: true,
    name: "Fun",
    shortName: "Fun",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFun_imageicon.png?alt=media&token=aca12de4-dcea-40e2-bfa1-a33cb7c0c497",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFUN_squareImage.jpg?alt=media&token=6cba6081-b11d-47f5-a53c-3a79d7e4a5a2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Ffun_backgroundImage.jpg?alt=media&token=ef898d6e-a016-4c89-94dd-15851eb7ee9c",
    leagueID: "FUN",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
  EVENTS: {
    enabled: true,
    name: "Events",
    shortName: "Events",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEvent_imageicon.png?alt=media&token=8455c897-da88-4699-b394-29d3714af6a3",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEVENT_squareImage.jpg?alt=media&token=7af1bb18-ef36-4176-a54a-5b7f9288b2ba",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fevents_backgroundImage.jpg?alt=media&token=01381cbb-7e76-4f0e-9655-fe5431771d8c",
    leagueID: "EVENTS",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
  CELEBRITY: {
    enabled: true,
    name: "Celebrities",
    shortName: "Celebs",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCelebrity_imageIcon.png?alt=media&token=56694746-9bb4-4c2b-9f12-01a340956610",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCELEBRITY_squareImage.jpg?alt=media&token=1d936e51-59a5-4919-9321-5df92616cebe",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fcelebrity_backgroundImage.jpg?alt=media&token=84f46d78-9774-40f5-84e1-08a85ac60ad3",
    leagueID: "CELEBRITY",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
  MARKETS: {
    enabled: true,
    name: "Markets",
    shortName: "Markets",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMarkets_imageicon.png?alt=media&token=fbde35db-3956-4454-af44-8fb30ac1d2f6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMARKETS_squareImage.jpg?alt=media&token=e3008875-9455-47f4-a036-a8ef646f1a48",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmarkets_backgroundImage.jpg?alt=media&token=04447fe0-2909-46eb-bce4-572ce4d8a5cf",
    leagueID: "MARKETS",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 8.0,
  },
  WEATHER: {
    enabled: true,
    name: "Weather",
    shortName: "Weather",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFun_imageicon.png?alt=media&token=aca12de4-dcea-40e2-bfa1-a33cb7c0c497",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFUN_squareImage.jpg?alt=media&token=6cba6081-b11d-47f5-a53c-3a79d7e4a5a2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Ffun_backgroundImage.jpg?alt=media&token=ef898d6e-a016-4c89-94dd-15851eb7ee9c",
    leagueID: "WEATHER",
    sportID: "NON_SPORTS",
    defaultPopularityScore: 5.0,
  },
};
exports.LEAGUE_CONFIG = LEAGUE_CONFIG;

const ENABLED_LEAGUES = Object.values(LEAGUE_CONFIG).filter((leagueConfigData) => leagueConfigData?.leagueID && leagueConfigData?.enabled);
exports.ENABLED_LEAGUES = ENABLED_LEAGUES;

const ENABLED_LEAGUE_IDS = ENABLED_LEAGUES.map((leagueConfigData) => leagueConfigData?.leagueID);
exports.ENABLED_LEAGUE_IDS = ENABLED_LEAGUE_IDS;

const SPORT_ALL_LEAGUES = Object.values(LEAGUE_CONFIG).reduce((sportLeaguesMap, leagueConfigData) => {
  if (!leagueConfigData?.sportID || !leagueConfigData?.leagueID) return sportLeaguesMap;
  if (!sportLeaguesMap[leagueConfigData.sportID]) sportLeaguesMap[leagueConfigData.sportID] = [];
  sportLeaguesMap[leagueConfigData.sportID].push(leagueConfigData.leagueID);
  return sportLeaguesMap;
}, {});
exports.SPORT_ALL_LEAGUES = SPORT_ALL_LEAGUES;

const SPORT_ENABLED_LEAGUES = Object.values(LEAGUE_CONFIG).reduce((sportLeaguesMap, leagueConfigData) => {
  if (!leagueConfigData?.sportID || !leagueConfigData?.leagueID || !leagueConfigData?.enabled) return sportLeaguesMap;
  if (!sportLeaguesMap[leagueConfigData.sportID]) sportLeaguesMap[leagueConfigData.sportID] = [];
  sportLeaguesMap[leagueConfigData.sportID].push(leagueConfigData.leagueID);
  return sportLeaguesMap;
}, {});
exports.SPORT_ENABLED_LEAGUES = SPORT_ENABLED_LEAGUES;

const LEAGUES_BY_NAME = [...ENABLED_LEAGUES].sort((a, b) => {
  if (!a?.name && !b?.name) return 0;
  if (!a?.name) return 1;
  if (!b?.name) return -1;
  return a.name.localeCompare(b.name);
});
exports.LEAGUES_BY_NAME = LEAGUES_BY_NAME;

const LEAGUES_BY_DEFAULT_POPULARITY = [...ENABLED_LEAGUES].sort((a, b) => (b?.defaultPopularityScore || 0) - (a?.defaultPopularityScore || 0));
exports.LEAGUES_BY_DEFAULT_POPULARITY = LEAGUES_BY_DEFAULT_POPULARITY;
