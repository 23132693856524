class Database {
  connected = false;
  connectPromise = null;

  constructor() {
    if (new.target === Database) {
      throw new TypeError("Cannot construct Database instances directly");
    }
  }

  static getInstance() {
    throw new Error("getInstance method must be implemented");
  }

  connect(options) {
    throw new Error("connect method must be implemented");
  }

  disconnect() {
    throw new Error("disconnect method must be implemented");
  }
}
exports.Database = Database;
