const { getLastRequestAt, getUsageValue } = require("@wagerlab/admin/src/apiKeys/usage");

export const filterAndSortApiKeys = (apiKeysList, sortBy, sortOrder, searchString) => {
  if (!apiKeysList?.length) return [];

  let filteredApiKeys = apiKeysList;
  const searchListLower = searchString
    .toLowerCase()
    .trim()
    .split(" ")
    .filter((word) => word?.length)
    .map((word) => {
      if (word === "inactive") return "a_false";
      else if (word === "active") return "a_true";
      else return word;
    });
  if (searchListLower?.length) {
    filteredApiKeys = apiKeysList.filter((apiKeyData) => {
      const apiKeySearchHash = `${apiKeyData?.email || ""} ${apiKeyData?.tier || ""} a_${!!apiKeyData?.active}`.toLowerCase();
      return searchListLower.every((word) => apiKeySearchHash.includes(word));
    });
  }

  const sortedApiKeys = filteredApiKeys.sort((keyA, keyB) => {
    if (sortBy === "last_request") {
      const lastRequestTime_A = getLastRequestAt(keyA)?.getTime?.() || 0;
      const lastRequestTime_B = getLastRequestAt(keyB)?.getTime?.() || 0;
      if (!lastRequestTime_A) return 1;
      if (!lastRequestTime_B) return -1;
      return sortOrder === "asc" ? lastRequestTime_A - lastRequestTime_B : lastRequestTime_B - lastRequestTime_A;
    }
    if (sortBy === "created") {
      const createdAt_A = keyA.createdAt || "";
      const createdAt_B = keyB.createdAt || "";
      return sortOrder === "asc" ? createdAt_A.localeCompare(createdAt_B) : createdAt_B.localeCompare(createdAt_A);
    }
    if (sortBy === "usage") {
      const monthlyUsage_A = getUsageValue(keyA);
      const monthlyUsage_B = getUsageValue(keyB);
      return sortOrder === "asc" ? monthlyUsage_A - monthlyUsage_B : monthlyUsage_B - monthlyUsage_A;
    }
    if (sortBy === "active") {
      const active_A = keyA.active ? 1 : 0;
      const active_B = keyB.active ? 1 : 0;
      return sortOrder === "asc" ? active_A - active_B : active_B - active_A;
    }
    if (sortBy === "tier") {
      const tier_A = keyA.tier || "";
      const tier_B = keyB.tier || "";
      return sortOrder === "asc" ? tier_A.localeCompare(tier_B) : tier_B.localeCompare(tier_A);
    }
    if (sortBy === "email") {
      const email_A = keyA.email || "";
      const email_B = keyB.email || "";
      return sortOrder === "asc" ? email_A.localeCompare(email_B) : email_B.localeCompare(email_A);
    }
  });

  return sortedApiKeys;
};
