const { TEAM_TYPES } = require("@wagerlab/utils/sports/enums");
const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");
const _ = require("lodash");

exports.generateLLMPrompt = (unknownEntities, existingEntities, unknownConfig, leagueID) => {
  const { name: leagueName, shortName: leagueShortName, teamType } = LEAGUE_CONFIG[leagueID] || {};
  let leagueDisplay = leagueName || leagueShortName || leagueID;
  if (leagueName && leagueShortName && leagueName !== leagueShortName) leagueDisplay = `${leagueName} (${leagueShortName})`;
  let typeDisplay = unknownConfig.typeDisplay;
  if (typeDisplay === "Teams" && teamType === TEAM_TYPES.SINGLE_PLAYER) typeDisplay = "Players";

  const typeSingLow = typeDisplay.toLowerCase().slice(0, -1);
  const leagueTypeDisplay = `${leagueDisplay} ${typeDisplay}`;
  if (!existingEntities?.length || !unknownEntities?.length || !leagueDisplay) return "";

  const getNamesForEntity = (namesObj) => {
    const { long, medium, short, firstName, lastName, display, nickname } = namesObj || {};
    const nameParts = [];
    if (long) nameParts.push(`long name: ${long}`);
    if (medium) nameParts.push(`medium name: ${medium}`);
    if (short) nameParts.push(`short name: ${short}`);
    if (firstName) nameParts.push(`first name: ${firstName}`);
    if (lastName) nameParts.push(`last name: ${lastName}`);
    if (display) nameParts.push(`display name: ${display}`);
    if (nickname) nameParts.push(`nickname: ${nickname}`);
    return nameParts.join(", ");
  };

  const unknownEntityLines = [];
  unknownEntities.forEach((unknownEntityData, i) => {
    const unknownEntityNamesObj = _.get(unknownEntityData, unknownConfig.namesPath);
    const unknownEntityNames = getNamesForEntity(unknownEntityNamesObj);
    unknownEntityLines.push(`Item #${i + 1}  =  Names: ${unknownEntityNames || "???"}`);
  });
  const unknownEntitiesSection = `These are the ${leagueTypeDisplay} that need to be added to the database:\n${unknownEntityLines.join("\n")}`;

  const existingEntityLines = [];
  existingEntities.forEach((existingEntityData) => {
    const existingEntityID = unknownConfig.getEntityID(existingEntityData);
    const existingEntityNames = getNamesForEntity(existingEntityData?.names);
    existingEntityLines.push(`ID: ${existingEntityID || "???"}  =  Names: ${existingEntityNames || "???"}`);
  });
  const existingEntitiesSection = existingEntityLines.join("\n");

  return `You are an expert system designed to manage a database of ${leagueTypeDisplay}. Your task is to process a list of new ${leagueTypeDisplay} and determine whether they should be linked to an existing ${typeSingLow} in the database or added as new entries.

First, review the following lists:

1. New ${leagueTypeDisplay} to be processed:
<New_${typeDisplay}>
${unknownEntitiesSection}
</New_${typeDisplay}>

2. Existing ${leagueTypeDisplay} in the database:
<Existing_${typeDisplay}>
${existingEntitiesSection}
</Existing_${typeDisplay}>

For each ${typeSingLow} in the New_${typeDisplay} list, follow these steps:

1. Analyze the ${typeSingLow} name and consider all possible variations or alternate names.
2. Compare the ${typeSingLow} with the Existing_${typeDisplay} list, looking for potential matches.
3. Use your extensive knowledge of ${leagueTypeDisplay} to ensure accurate matching.
4. Determine whether to link the new ${typeSingLow} to an existing entry or create a new one.
5. Assign an accuracy score (1-100) based on your confidence in the decision.
6. Assign a popularity score (1-100) based on the ${typeSingLow}'s prominence/popularity

Before making a final decision for each ${typeSingLow}, wrap your thought process in <${typeSingLow}_analysis> tags. In this analysis:

1. List possible variations of the ${typeSingLow} name.
2. Identify potential matches from the existing ${typeDisplay} list.
3. Note any relevant information about the ${typeSingLow}'s history or recent changes.
4. Explicitly state arguments for and against linking to an existing ${typeSingLow} or creating a new entry.
5. Explain the reasoning behind the accuracy and popularity scores.

After your analysis, provide your final decision in a table format with the following columns:
1. Item #
2. Item Names
2. Matched ID (use existing ID if matched, or "NEW - X" for new entries, where X is a reasonable new ID to use)
3. Accuracy Score (1-100)
4. Popularity Score (1-100)

Example output format:

| Item # | Item Names         | Matched ID                 | Accuracy Score | Popularity Score |
|--------|--------------------|----------------------------|----------------|------------------|
| 1      | Example U, Example | EXAMPLE_U_NCAAB            | 95             | 80               |
| 2      | New State, NSU     | NEW - NEW_STATE_NCAAB      | 100            | 60               |

Ensure that you process all ${typeDisplay} in the New_${typeDisplay} list and present them in the table format shown above.

Begin your analysis of the new ${typeDisplay} now.`;
};
