const { Collection } = require("../Collection");

const CONFIG_CONFIG = {
  collectionType: "config",
  collectionName: "Config",
  adapterType: "mongo",
  primaryKey: "configID",
};
exports.CONFIG_CONFIG = CONFIG_CONFIG;

class ConfigCollection extends Collection {
  config = CONFIG_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.ConfigCollection = ConfigCollection;
