import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Box, Typography, Container, AppBar, Toolbar, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { getAllApiKeys } from "@wagerlab/admin/src/apiKeys/database";
import { ApiKeyRow } from "@wagerlab/admin/src/apiKeys/ApiKeyRow";
import { LoadingSpinner } from "@wagerlab/admin/src/shared/Loading";
import { filterAndSortApiKeys } from "@wagerlab/admin/src/apiKeys/sorter";

export const ApiKeysPage = ({}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [sorting, setSorting] = useState({ sortBy: "", sortOrder: "" });

  const {
    data: apiKeys = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["apiKeys"],
    queryFn: () => getAllApiKeys(),
    staleTime: Infinity,
  });

  const filteredAndSortedKeys = useMemo(() => filterAndSortApiKeys(apiKeys, sorting.sortBy, sorting.sortOrder, searchQuery), [apiKeys, searchQuery, sorting]);

  if (isLoading) {
    return (
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoadingSpinner />
      </Container>
    );
  }
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
      <Box sx={{ pl: 2, pr: 6 }}>
        <TextField
          label="Filter by Email, Tier, or Active/Inactive"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          sx={{ m: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <HeaderCell title={"Email"} sortByKey={"email"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"Created"} sortByKey={"created"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"Last Request"} sortByKey={"last_request"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"Usage"} sortByKey={"usage"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"Tier"} sortByKey={"tier"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"Active"} sortByKey={"active"} sorting={sorting} setSorting={setSorting} />
              <HeaderCell title={"ID"} />
              <HeaderCell title={"Actions"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedKeys.map((apiKeyData, i) => (
              <ApiKeyRow key={`${apiKeyData?.apiKeyHash || i}`} apiKeyData={apiKeyData} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const HeaderCell = ({ title, sortByKey = null, sorting = null, setSorting = null }) => {
  const isCurrentSort = !!sortByKey && sorting.sortBy === sortByKey;

  if (!sortByKey) return <TableCell>{title}</TableCell>;

  return (
    <TableCell>
      <TableSortLabel
        active={isCurrentSort}
        direction={isCurrentSort ? sorting.sortOrder : "desc"}
        onClick={() => {
          const isAsc = isCurrentSort ? sorting.sortOrder === "asc" : false;
          setSorting({ sortBy: sortByKey, sortOrder: isAsc ? "desc" : "asc" });
        }}
      >
        {title}
      </TableSortLabel>
    </TableCell>
  );
};
