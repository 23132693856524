const SOURCE_STATUS = {
  enabled: "enabled", // Everything is enabled.
  deprioritized: "deprioritized", // Can't create new events. Can provide full updates
  detached: "detached",
  deleted: "deleted",
};
exports.SOURCE_STATUS = SOURCE_STATUS;

const isValidSourceStatus = (sourceStatus) => {
  return sourceStatus === SOURCE_STATUS.enabled || sourceStatus === SOURCE_STATUS.deprioritized || sourceStatus === SOURCE_STATUS.detached || sourceStatus === SOURCE_STATUS.deleted;
};
exports.isValidSourceStatus = isValidSourceStatus;

const sourceStatusEnabled = (sourceStatus) => {
  return (sourceStatus || SOURCE_STATUS.enabled) === SOURCE_STATUS.enabled;
};
exports.sourceStatusEnabled = sourceStatusEnabled;

const sourceStatusProvidingUpdates = (sourceStatus) => {
  if (sourceStatus === SOURCE_STATUS.detached) return false;
  if (sourceStatus === SOURCE_STATUS.deleted) return false;
  return true;
};
exports.sourceStatusProvidingUpdates = sourceStatusProvidingUpdates;

const getSourceStatusPriority = (sourceStatus) => {
  if (!sourceStatus || sourceStatus === SOURCE_STATUS.enabled) return 4;
  if (sourceStatus === SOURCE_STATUS.deprioritized) return 3;
  if (sourceStatus === SOURCE_STATUS.detached) return 2;
  if (sourceStatus === SOURCE_STATUS.deleted) return 1;
  return 0;
};
exports.getSourceStatusPriority = getSourceStatusPriority;
