const { Collection } = require("../Collection");

const API_KEYS_CONFIG = {
  collectionType: "apikeys",
  collectionName: "ApiKeys",
  adapterType: "mongo",
  primaryKey: "apiKeyHash",
};
exports.API_KEYS_CONFIG = API_KEYS_CONFIG;

class ApiKeysCollection extends Collection {
  config = API_KEYS_CONFIG;

  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.ApiKeysCollection = ApiKeysCollection;
