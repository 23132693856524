import React from "react";
import { TableCell, TableRow, Box, Typography, Chip } from "@mui/material";
import { ObjectDataDialog } from "@wagerlab/admin/src/shared/ObjectDataDialog";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "@wagerlab/admin/src/shared/clipboard";
import { getLastRequestAt, UsageCell } from "@wagerlab/admin/src/apiKeys/usage";
import moment from "moment";
import { OverridesEditor } from "@wagerlab/admin/src/apiKeys/OverridesEditor";
import { KeyRegenerator } from "@wagerlab/admin/src/apiKeys/KeyRegenerator";
import { TierUpdater } from "@wagerlab/admin/src/apiKeys/TierUpdater";

export const ApiKeyRow = ({ apiKeyData }) => {
  if (!apiKeyData?.apiKeyHash) return null;

  const lastRequestAtDate = getLastRequestAt(apiKeyData);
  const lastRequestAtString = lastRequestAtDate && moment(lastRequestAtDate).isValid() ? moment(lastRequestAtDate).format("MMM D YYYY [@] h:mma") : "N/A";
  const createdAtString = apiKeyData?.createdAt && moment(apiKeyData.createdAt).isValid() ? moment(apiKeyData.createdAt).format("MMM D YYYY [@] h:mma") : "-";

  return (
    <TableRow key={apiKeyData.apiKeyHash} hover>
      <TableCell>{apiKeyData.email}</TableCell>
      <TableCell>{createdAtString}</TableCell>
      <TableCell>{lastRequestAtString}</TableCell>
      <TableCell>
        <UsageCell apiKeyData={apiKeyData} />
      </TableCell>

      <TableCell>{apiKeyData.tier}</TableCell>
      <TableCell>
        <Chip label={apiKeyData.active ? "Active" : "Inactive"} color={apiKeyData.active ? "success" : "error"} size="small" />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{apiKeyData.apiKeyHash.substring(0, 8)}...</Typography>
          <IconButton Icon={ContentCopyIcon} size="small" onClick={() => copyToClipboard(apiKeyData.apiKeyHash)} sx={{ ml: 1 }} />
        </Box>
      </TableCell>
      <TableCell sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
        <ObjectDataDialog data={apiKeyData} title="View Full API Key Data" />
        <OverridesEditor apiKeyData={apiKeyData} />
        <KeyRegenerator apiKeyData={apiKeyData} />
        <TierUpdater apiKeyData={apiKeyData} />
      </TableCell>
    </TableRow>
  );
};
