const { Collection } = require("../Collection");

const EVENT_SHORTS_CONFIG = {
  collectionType: "eventshorts",
  collectionName: "EventShorts",
  adapterType: "firestore",
  primaryKey: "eventID",
};
exports.EVENT_SHORTS_CONFIG = EVENT_SHORTS_CONFIG;

class EventShortsCollection extends Collection {
  config = EVENT_SHORTS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.EventShortsCollection = EventShortsCollection;
