import moment from "moment-timezone";
import _ from "lodash";
import { parsePositiveInteger } from "@wagerlab/utils/data/numbers";
import { Box } from "@mui/material";

const USAGE_PERIODS = ["month", "week", "day", "hour", "minute", "second"];
const USAGE_PERIODS_REVERSED = USAGE_PERIODS.slice().reverse();

export const getLastRequestAt = (apiKeyData) => {
  if (apiKeyData?.cache?.lastRequest) return new Date(apiKeyData?.cache?.lastRequest);
  if (apiKeyData?.cache?.resets?.second?.requests) return moment(apiKeyData.cache.resets.second.requests).subtract(1, "seconds").toDate();
  if (apiKeyData?.cache?.resets?.second?.entities) return moment(apiKeyData.cache.resets.second.entities).subtract(1, "seconds").toDate();
  if (apiKeyData?.cache?.resets?.minute?.requests) return moment(apiKeyData.cache.resets.minute.requests).subtract(1, "minutes").toDate();
  if (apiKeyData?.cache?.resets?.minute?.entities) return moment(apiKeyData.cache.resets.minute.entities).subtract(1, "minutes").toDate();
  if (apiKeyData?.cache?.resets?.hour?.requests) return moment(apiKeyData.cache.resets.hour.requests).subtract(1, "hours").toDate();
  if (apiKeyData?.cache?.resets?.hour?.entities) return moment(apiKeyData.cache.resets.hour.entities).subtract(1, "hours").toDate();
  if (apiKeyData?.cache?.resets?.day?.requests) return moment(apiKeyData.cache.resets.day.requests).subtract(1, "days").toDate();
  if (apiKeyData?.cache?.resets?.day?.entities) return moment(apiKeyData.cache.resets.day.entities).subtract(1, "days").toDate();
  if (apiKeyData?.lastRequestAt) return new Date(apiKeyData?.lastRequestAt);
  return null;
};
export const getUsageValue = (apiKeyData) => {
  const dailyRequests = apiKeyData?.cache?.usage?.day?.requests;

  const monthlyRequests = apiKeyData?.cache?.usage?.month?.requests;
  const dailyRequests_fromMon = monthlyRequests && monthlyRequests / 30;

  const hourlyRequests = apiKeyData?.cache?.usage?.hour?.requests;
  const dailyRequests_fromHour = hourlyRequests && hourlyRequests * 24;

  const minuteRequests = apiKeyData?.cache?.usage?.minute?.requests;
  const dailyRequests_fromMin = minuteRequests && minuteRequests * 60 * 24;

  return dailyRequests || dailyRequests_fromMon || dailyRequests_fromHour || dailyRequests_fromMin;
};

export const UsageCell = ({ apiKeyData }) => {
  const displayParts = [];
  let hasAnyUsageInCurrentPeriod = false;
  let hasAnyUsage = false;
  [true, false].forEach((isCurrentPeriod) => {
    const usageData = isCurrentPeriod ? apiKeyData?.cache?.usage : apiKeyData?.rateLimiting?.usage;
    const resetData = isCurrentPeriod ? apiKeyData?.cache?.resets : apiKeyData?.rateLimiting?.resets;
    ["month", "week", "day", "hour", "minute", "second"].forEach((interval) => {
      ["requests", "entities"].forEach((entityType) => {
        const usageValue = usageData?.[interval]?.[entityType];
        const resetValue = resetData?.[interval]?.[entityType];
        if (!usageValue) return;
        const resetDisplay = resetValue ? `(reset ${moment(resetValue).fromNow()})` : "";
        const valueDisplay = `${isCurrentPeriod ? "Current" : "Last active"} ${interval}: ${usageValue.toLocaleString()} ${entityType} ${resetDisplay}`;
        displayParts.push(valueDisplay);
        hasAnyUsage = true;
        if (isCurrentPeriod) hasAnyUsageInCurrentPeriod = true;
      });
    });
  });
  if (!hasAnyUsage) displayParts.unshift("No Usage");
  else if (!hasAnyUsageInCurrentPeriod) displayParts.unshift("No Usage in Current Month");
  const usageDisplay = displayParts.join("\n\n");
  return (
    <Box
      sx={{
        "whiteSpace": "pre-line",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": 3,
        "overflow": "hidden",
        "&:hover": {
          WebkitLineClamp: "unset",
          overflow: "visible",
        },
      }}
    >
      {usageDisplay}
    </Box>
  );
};
