import React, { useMemo, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { EventTableRow } from "@wagerlab/admin/src/events/list/EventTableRow";
import { getEventDisplay } from "@wagerlab/utils/displays";

const DEFAULT_SORT_DIRECTION = "desc";
const SORT_FUNCTIONS = {
  sport: {
    asc: (a, b) => (a.sportID < b.sportID ? -1 : a.sportID > b.sportID ? 1 : 0),
    desc: (a, b) => (a.sportID > b.sportID ? -1 : a.sportID < b.sportID ? 1 : 0),
  },
  league: {
    asc: (a, b) => (a.leagueID < b.leagueID ? -1 : a.leagueID > b.leagueID ? 1 : 0),
    desc: (a, b) => (a.leagueID > b.leagueID ? -1 : a.leagueID < b.leagueID ? 1 : 0),
  },
  title: {
    asc: (a, b) => {
      const aTitle = getEventDisplay("title_long", a);
      const bTitle = getEventDisplay("title_long", b);
      return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
    },
    desc: (a, b) => {
      const aTitle = getEventDisplay("title_long", a);
      const bTitle = getEventDisplay("title_long", b);
      return aTitle > bTitle ? -1 : aTitle < bTitle ? 1 : 0;
    },
  },
  startsAt: {
    asc: (a, b) => (a.status?.startsAt < b.status?.startsAt ? -1 : a.status?.startsAt > b.status?.startsAt ? 1 : 0),
    desc: (a, b) => (a.status?.startsAt > b.status?.startsAt ? -1 : a.status?.startsAt < b.status?.startsAt ? 1 : 0),
  },
};

export const EventTable = ({ events }) => {
  const [sortHeader, setSortHeader] = useState("startsAt");
  const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);

  const sortedEvents = useMemo(() => {
    const unsortedEvents = [...events];
    const sortFunction = SORT_FUNCTIONS?.[sortHeader]?.[sortDirection];
    const sortedEvents = sortFunction ? unsortedEvents.sort(sortFunction) : unsortedEvents;
    return sortedEvents;
  }, [events, sortHeader, sortDirection]);

  const handleSortLabelClick = (headerName) => {
    if (sortHeader === headerName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortHeader(headerName);
      setSortDirection(DEFAULT_SORT_DIRECTION);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Event ID</TableCell>
            <TableCell align="left">
              <TableSortLabel active={sortHeader === "title"} direction={sortHeader === "title" ? sortDirection : DEFAULT_SORT_DIRECTION} onClick={() => handleSortLabelClick("title")}>
                Event Title
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel active={sortHeader === "sport"} direction={sortHeader === "sport" ? sortDirection : DEFAULT_SORT_DIRECTION} onClick={() => handleSortLabelClick("sport")}>
                Sport ID
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel active={sortHeader === "league"} direction={sortHeader === "league" ? sortDirection : DEFAULT_SORT_DIRECTION} onClick={() => handleSortLabelClick("league")}>
                League ID
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel active={sortHeader === "startsAt"} direction={sortHeader === "startsAt" ? sortDirection : "asc"} onClick={() => handleSortLabelClick("startsAt")}>
                Starts At
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{sortedEvents.map((event) => (event?.eventID ? <EventTableRow key={event.eventID} event={event} /> : null))}</TableBody>
      </Table>
    </TableContainer>
  );
};
