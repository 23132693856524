import { useState, useEffect } from "react";
import { LoadingPage } from "@wagerlab/admin/src/landing/Loading_Page";
import { SignInPage } from "@wagerlab/admin/src/landing/SignIn_Page";
import firebase from "firebase/compat/app";
import Snackbar from "@mui/material/Snackbar";
import { Routes, Route } from "react-router-dom";
import { AppLayout } from "@wagerlab/admin/src/AppLayout";
import { EventsListPage } from "@wagerlab/admin/src/events/list/EventsList_Page";
import { ApiKeysPage } from "@wagerlab/admin/src/apiKeys/ApiKeys_Page";
import { MainMenuPage } from "@wagerlab/admin/src/landing/MainMenu_Page";
import { EditConfigPage } from "@wagerlab/admin/src/config/EditConfig_Page";
import { LinkTeamsPage } from "@wagerlab/admin/src/unknowns/LinkTeams_Page";
import { LinkPlayersPage } from "@wagerlab/admin/src/unknowns/LinkPlayers_Page";
import { CreateEventPage } from "@wagerlab/admin/src/events/manage/CreateEvent_Page";
import { EditEventPage } from "@wagerlab/admin/src/events/manage/EditEvent_Page";
import { ExecutePage } from "@wagerlab/admin/src/execute/Execute_Page";

export const AppRouter = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            if (!!idTokenResult.claims.admin) {
              setLoggedIn(true);
              setLoading(false);
            } else {
              setSnackMessage("You must be an admin");
              setLoading(true);
              setLoggedIn(false);
              firebase.auth().signOut();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : loggedIn ? (
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<MainMenuPage />} />
            <Route path="events/new" element={<CreateEventPage />} />
            <Route path="events/list" element={<EventsListPage />} />
            <Route path="events/edit/:eventID" element={<EditEventPage />} />
            <Route path="config/edit" element={<EditConfigPage />} />
            <Route path="link/teams/:leagueID?" element={<LinkTeamsPage />} />
            <Route path="link/players/:leagueID?" element={<LinkPlayersPage />} />
            <Route path="api/keys" element={<ApiKeysPage />} />
            <Route path="execute" element={<ExecutePage />} />
          </Route>
        </Routes>
      ) : (
        <SignInPage />
      )}
      <Snackbar open={!!snackMessage} onClose={() => setSnackMessage("")} message={snackMessage} autoHideDuration={10000} />
    </>
  );
};
