// All methods return a promise unless otherwise specified
// All methods are usually implemented in the Adapter but can be overridden in the type-specific Collection (ex. EventsCollection)

const { parseNumber } = require("@wagerlab/utils/data/numbers");
const { isObject, isEmptyObject, isArray } = require("@wagerlab/utils/data/types");

// To access adapter-specific methods: db("someCollection").adapter.someMethod() (ex: db("events").adapter.getMongoClient())

class Collection {
  adapter = null;

  constructor(adapter) {
    this.adapter = adapter;
  }

  async query(queryParts, sorting = [], limit = null, selectFields = []) {
    if (!queryParts?.length) return this.returnError("query", queryParts, sorting, limit);
    if (selectFields?.length && selectFields.every((s) => !s)) return this.returnError("query", queryParts, sorting, limit, selectFields);

    let primaryOrderField = null;
    for (let i = 0; i < queryParts?.length || 0; i++) {
      const queryPart = queryParts?.[i] || {};
      if (!queryPart.key || !queryPart.operator || !("value" in queryPart)) return this.returnError("query", queryPart, sorting, limit, selectFields);
      if (!primaryOrderField && PRIMARY_ORDER_OPERATORS[queryPart.operator]) primaryOrderField = queryPart.key;
    }

    const sortingArray = [];
    for (let j = 0; j < sorting?.length || 0; j++) {
      const { sortBy, order, startAfter, endBefore } = sorting?.[j] || {};
      if (!sortBy) return this.returnError("query", sorting?.[j]);
      sortingArray.push({ sortBy, startAfter, endBefore, order: order?.toLowerCase?.() === "desc" ? "desc" : "asc" });
    }

    const { sortBy: firstSortBy, order: firstOrder } = sortingArray[0] || {};
    if (primaryOrderField && primaryOrderField !== firstSortBy) sortingArray.unshift({ sortBy: primaryOrderField, order: firstOrder || "asc" });

    const limitInt = limit ? parseNumber(limit, null, "integer", "positive") : null;

    return this.adapter.query(queryParts, sortingArray, limitInt, selectFields);
  }

  queryListener(queryParts, onUpdate, onError, onlyChanges = false) {
    // In this function queryParts can be an empty array. This is valid and causes you to subscribe to all documents in the collection.
    // Also this method is not async and it returns null rather than a function in an erorr case
    if (!isArray(queryParts) || typeof onUpdate !== "function" || typeof onError !== "function" || queryParts.some((part) => !part?.key || !part?.operator || !("value" in part))) {
      return this.returnError("queryListener", queryParts, onUpdate, onError);
    }
    return this.adapter.queryListener(queryParts, onUpdate, onError, onlyChanges);
  }

  docListener(id, onUpdate, onError) {
    if (!id || typeof onUpdate !== "function" || typeof onError !== "function") return this.returnError("docListener", id, onUpdate, onError);
    return this.adapter.docListener(id, onUpdate, onError);
  }

  async getAll() {
    return this.adapter.getAll();
  }

  async get(id) {
    if (!id) return this.returnError("get", id);
    return this.adapter.get(id);
  }

  async set(id, data, returnModified = false) {
    if (!id || !data || data[this.adapter.primaryKey] !== id) return this.returnError("set", id, data, returnModified);
    return this.adapter.set(id, data, returnModified);
  }

  async delete(id, returnModified = false) {
    if (!id) return this.returnError("delete", id, returnModified);
    return this.adapter.delete(id, returnModified);
  }

  async update(id, updates, returnModified = false) {
    if (!id || !isObject(updates) || isEmptyObject(updates) || (this.adapter.primaryKey in updates && updates[this.adapter.primaryKey] !== id))
      return this.returnError("update", id, updates, returnModified);
    return this.adapter.update(id, updates, returnModified);
  }

  // Each item in batchList should have:
  // method: "set" | "update" | "delete"
  // id: string
  // payload: object (for set and update)
  async batchWrite(batchList) {
    const batch = [];
    for (let i = 0; i < batchList?.length || 0; i++) {
      if (!batchList?.[i]) continue;
      const { method, id, payload } = batchList[i];
      if (!["set", "update", "delete"].includes(method) || !id) return this.returnError("batchWrite", `write ${i} contains invalid operation`, batchList);
      if (method !== "delete" && !isObject(payload)) return this.returnError("batchWrite", `write ${i} has empty payload`, batchList);
      if (method === "update" && (isEmptyObject(payload) || (this.adapter.primaryKey in payload && payload[this.adapter.primaryKey] !== id)))
        return this.returnError("batchWrite", `write ${i} has empty payload or overwrites the collections primary key`, batchList);
      if (method === "set" && payload[this.adapter.primaryKey] !== id) return this.returnError("batchWrite", `write ${i} contains overwrite to primary key`, batchList);
      batch.push({ method, id, payload });
    }
    if (!batch.length) return this.returnError("batchWrite", batchList);
    return this.adapter.batchWrite(batch);
  }

  async transaction(inputData, transactionFunction) {
    if (!inputData?.id || !transactionFunction) return this.returnError("transaction", inputData, transactionFunction);
    return this.adapter.transaction(inputData, transactionFunction);
  }

  async batchTransaction(inputList, transactionFunction) {
    if (!inputList?.length || !transactionFunction) return this.returnError("batchTransaction", inputList, transactionFunction);
    for (const input of inputList) {
      if (!input?.id) return this.returnError("batchTransaction", inputList, transactionFunction);
    }

    return this.adapter.batchTransaction(inputList, transactionFunction);
  }

  //* This can only be used in update calls (both single and batchWrite) and NOT in set calls (single or batchWrite)
  deleteValue() {
    return this.adapter.deleteValue();
  }

  //* This can only be used in update calls (both single and batchWrite) and NOT in set calls (single or batchWrite)
  serverTimeValue() {
    return this.adapter.serverTimeValue();
  }

  //* This can only be used in update calls (both single and batchWrite) and NOT in set calls (single or batchWrite)
  incrementValue(incrementBy = 1) {
    const incrementByInt = parseNumber(incrementBy, 1, "integer", "any");
    return this.adapter.incrementValue(incrementByInt);
  }

  newID() {
    return this.adapter.newID();
  }

  returnError(functionName, ...args) {
    console.error(`Invalid DB request: ${functionName}`, ...args);
    return null;
  }
}

exports.Collection = Collection;

const PRIMARY_ORDER_OPERATORS = {
  "<": true,
  "<=": true,
  ">": true,
  ">=": true,
  "!=": true,
};
