const { LOG } = require("@wagerlab/utils/logging");

const getLogger = () => {
  return {
    log: console.log,
    info: console.info,
    warn: console.warn,
    error: console.error,
    debug: console.debug,
    minLogLevel: LOG.debug.level,
    logAs: "message,payload",
    metrics: { enabled: false },
  };
};
module.exports = { getLogger };
