const refreshEventIndexes = (event) => {
  const { oddIDs, teamIDs, playerIDs, bookmakerIDs } = event?.indexes || {};

  const eventPlayerIDs = Object.keys(event?.players || {});
  const eventTeamIDs = Object.values(event?.teams || {}).reduce((acc, team) => {
    if (team?.teamID) acc.push(team?.teamID);
    return acc;
  }, []);

  const teamIDsSet = new Set([...(teamIDs || []), ...eventTeamIDs]);
  const playerIDsSet = new Set([...(playerIDs || []), ...eventPlayerIDs]);
  const bookmakerIDsSet = new Set(bookmakerIDs || []);
  const oddIDsSet = new Set(oddIDs || []);
  Object.entries(event?.odds || {}).forEach(([oddID, oddData]) => {
    const { playerID, teamID, byBookmaker } = oddData || {};
    if (!oddID) return;

    oddIDsSet.add(oddID);
    if (playerID) {
      oddIDsSet.add(oddID.replace(playerID, "PLAYER_ID"));
      playerIDsSet.add(playerID);
    }
    if (teamID) {
      oddIDsSet.add(oddID.replace(teamID, "TEAM_ID"));
      teamIDsSet.add(teamID);
    }

    const byBookmakerIDs = Object.keys(byBookmaker || {});
    byBookmakerIDs.forEach((bookmakerID) => {
      bookmakerIDsSet.add(bookmakerID);
    });
  });

  event.indexes = {
    teamIDs: Array.from(teamIDsSet),
    playerIDs: Array.from(playerIDsSet),
    bookmakerIDs: Array.from(bookmakerIDsSet),
    oddIDs: Array.from(oddIDsSet),
  };
  return event;
};
exports.refreshEventIndexes = refreshEventIndexes;
