import React, { useEffect, useMemo, useState } from "react";
import { Alert, Container, Snackbar, Typography, Button, Box } from "@mui/material";
import { copyData } from "@wagerlab/utils/data/mutations";
import { Formik } from "formik";
import { LoadingSpinner } from "@wagerlab/admin/src/shared/Loading";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { EventForm } from "@wagerlab/admin/src/events/manage/EventForm";
import { getEvent, saveEventForm } from "@wagerlab/admin/src/events/database";
import { getEventFormConfig, getEventFormSchema, initializeEventFormData } from "@wagerlab/utils/events/form";
import { logInfo } from "@wagerlab/utils/logging";

const RESET_FORM_ON_REFRESH = false;
const EVENT_REFRESH_MINUTES = RESET_FORM_ON_REFRESH ? 120 : 20;

export const EventManager = ({ action, eventID }) => {
  const queryClient = useQueryClient();

  const {
    data: fetchedEvent,
    isLoading: isEventLoading,
    isFetching: isEventFetching,
  } = useQuery({
    queryKey: ["event", eventID || "new"],
    queryFn: () => (eventID ? getEvent(eventID) : null),
    staleTime: (EVENT_REFRESH_MINUTES - 1) * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchInterval: RESET_FORM_ON_REFRESH ? undefined : EVENT_REFRESH_MINUTES * 60 * 1000,
    refetchIntervalInBackground: false,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successful, setSuccessful] = useState(false);

  const screenLoading = RESET_FORM_ON_REFRESH ? isEventLoading || isEventFetching : isEventLoading;

  const { event, eventForm, formConfig } = useMemo(() => {
    const eventData = screenLoading ? null : copyData(fetchedEvent);
    const eventFormConfig = getEventFormConfig(eventData, action, eventID);
    const eventFormData = initializeEventFormData(eventData, eventFormConfig);
    return { event: eventData, eventForm: eventFormData, formConfig: eventFormConfig };
  }, [screenLoading, fetchedEvent, action, eventID]);

  const handleFormSubmit = async (formData, formikBag) => {
    logInfo("Saving event form...", { formData });
    const eventSaveError = await saveEventForm(formData);
    if (eventSaveError) return setErrorMessage(eventSaveError);

    if (action === "new") {
      queryClient.removeQueries({ queryKey: ["event", "new"] });
      queryClient.invalidateQueries({ queryKey: ["event", "new"] });
      queryClient.removeQueries({ queryKey: ["events"] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
    } else {
      queryClient.removeQueries({ queryKey: ["event", eventID] });
      queryClient.invalidateQueries({ queryKey: ["event", eventID] });
    }

    setSuccessful(true);
  };

  useEffect(() => {
    if (eventID) {
      setSuccessful(false);
    }
  }, [eventID]);

  //Should these be added to formikProps: validateOnChange={false} validateOnBlur={true}
  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
        {screenLoading ? (
          <LoadingSpinner />
        ) : successful ? (
          <FormSuccess eventID={eventID} />
        ) : formConfig?.error ? (
          <FormUnavailable errorMessage={formConfig.error} />
        ) : (
          <Formik initialValues={eventForm} onSubmit={handleFormSubmit} validationSchema={getEventFormSchema("moment")} validateOnChange={false} validateOnBlur={true}>
            <EventForm remoteEvent={event} formConfig={formConfig} />
          </Formik>
        )}
      </Container>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!errorMessage?.text} onClose={() => setErrorMessage(null)} autoHideDuration={10000}>
        <Alert onClose={() => setErrorMessage(null)} severity={"error"} sx={{ width: "100%" }}>
          {errorMessage || "Something went wrong"}
        </Alert>
      </Snackbar>
    </>
  );
};

export const FormUnavailable = ({ errorMessage }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "77vh",
      }}
    >
      <Typography variant="h6">{errorMessage || "An unknown error occurred"}</Typography>
      <Button onClick={() => navigate(`/`)} variant="contained" sx={{ height: "77vh" }}>
        Go Back
      </Button>
    </Box>
  );
};

export const FormSuccess = ({ eventID }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ textAlign: "center", mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          Event Saved Successfully: {eventID}
        </Typography>
        <Button variant="contained" onClick={() => navigate(`/`)}>
          Go Back
        </Button>
      </Box>
    </>
  );
};
