const { logError } = require("@wagerlab/utils/logging");
const { Client } = require("node-mailjet");

let mailJetClient;

exports.buildAPIKeyWelcomeEmailRequest = (apiKey, apiKeyData) => {
  if (!apiKey || !apiKeyData?.email) return null;
  return { fromEmail: "api@sportsgameodds.com", fromName: "SportsGameOdds API", toEmail: apiKeyData?.email, subject: "Your SportsGameOdds API Key", templateID: 5742120, variables: { apiKey } };
};

exports.sendAPIKeyWelcomeEmail = async (apiKey, apiKeyData) => {
  const email = apiKeyData?.email;

  if (!apiKey) return "Can't find this API key. Contact support.";
  if (!email) return "Can't find this API key's email address. Contact support.";

  const mjClient = initializeMailJetClient();

  return await mjClient
    .post("send")
    .request({
      "FromEmail": "api@sportsgameodds.com",
      "FromName": "SportsGameOdds API",
      "Subject": "Your SportsGameOdds API Key",
      "Mj-TemplateID": 5742120,
      "Mj-TemplateLanguage": true,
      "Recipients": [{ Email: email }],
      "Vars": {
        apiKey: apiKey,
      },
    })
    .then((response) => {
      return response?.body?.Sent?.[0]?.MessageID ? "" : "API key email did not send";
    })
    .catch((error) => {
      logError("Error sending API key email", { error, apiKeyData });
      return "Error sending API key email";
    });
};

const initializeMailJetClient = () => {
  mailJetClient =
    mailJetClient ||
    new Client({
      apiKey: process.env.MAILJET_API_KEY_PUBLIC,
      apiSecret: process.env.MAILJET_SECRET_KEY_PRIVATE,
    });
  return mailJetClient;
};
