const { Collection } = require("../Collection");

const PLAYERS_CONFIG = {
  collectionType: "players",
  collectionName: "Players",
  adapterType: "mongo",
  primaryKey: "playerID",
};
exports.PLAYERS_CONFIG = PLAYERS_CONFIG;

class PlayersCollection extends Collection {
  config = PLAYERS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.PlayersCollection = PlayersCollection;
