const RATE_LIMITING_INTERVALS = ["second", "minute", "hour", "day", "month"];
exports.RATE_LIMITING_INTERVALS = RATE_LIMITING_INTERVALS;

const RATE_LIMITING_TYPES = ["requests", "entities"];
exports.RATE_LIMITING_TYPES = RATE_LIMITING_TYPES;

const RATE_LIMITING_DEFAULTS = {
  hour: {
    requests: 50000,
    entities: 300000,
  },
  day: {
    entities: 7000000,
  },
};
exports.RATE_LIMITING_DEFAULTS = RATE_LIMITING_DEFAULTS;

const API_KEY_CACHE_FOR_SECONDS = 86400; // 1 day
exports.API_KEY_CACHE_FOR_SECONDS = API_KEY_CACHE_FOR_SECONDS;

const FALLBACK_TIER = "amateur";
exports.FALLBACK_TIER = FALLBACK_TIER;

const TIER_CONFIG = {
  amateur: {
    tier: "amateur",
    accessLimiting: {
      includeSports: {
        DEFAULT: false,
        FOOTBALL: true,
        BASKETBALL: true,
        BASEBALL: true,
        HOCKEY: true,
        SOCCER: true,
      },
      includeLeagues: {
        DEFAULT: false,
        NFL: true,
        NCAAF: true,
        NBA: true,
        NCAAB: true,
        MLB: true,
        NHL: true,
        INTERNATIONAL_SOCCER: true,
        MLS: true,
        EPL: true,
        UEFA_CHAMPIONS_LEAGUE: true,
      },
      includeBookmakers: {
        DEFAULT: true,
      },
      includeOthers: {
        DEFAULT: true,
        // [EVENT_ODDS_FILTERS.PROP]: false,
        // [EVENT_ODDS_FILTERS.LIVE]: false,
        // [EVENT_ODDS_FILTERS.FALLBACK]: false,
        // [EVENT_ODDS_FILTERS.PARTIAL]: false,
      },
      sportsLimited: true,
      leaguesLimited: true,
      bookmakersLimited: false,
      othersLimited: false,
    },
    rateLimiting: {
      limits: {
        month: {
          entities: 1000,
        },
        minute: {
          requests: 10,
        },
      },
    },
  },
  rookie: {
    tier: "rookie",
    accessLimiting: {
      includeSports: {
        DEFAULT: true,
      },
      includeLeagues: {
        DEFAULT: true,
      },
      includeBookmakers: {
        DEFAULT: true,
      },
      includeOthers: {
        DEFAULT: true,
      },
      sportsLimited: false,
      leaguesLimited: false,
      bookmakersLimited: false,
      othersLimited: false,
    },
    rateLimiting: {
      limits: {
        month: {
          entities: 5000000,
        },
        minute: {
          requests: 60,
        },
      },
    },
  },
  pro: {
    tier: "pro",
    accessLimiting: {
      includeSports: {
        DEFAULT: true,
      },
      includeLeagues: {
        DEFAULT: true,
      },
      includeBookmakers: {
        DEFAULT: true,
      },
      includeOthers: {
        DEFAULT: true,
      },
      sportsLimited: false,
      leaguesLimited: false,
      bookmakersLimited: false,
      othersLimited: false,
    },
    rateLimiting: {
      limits: {
        minute: {
          requests: 1000,
        },
      },
    },
  },
  allstar: {
    tier: "allstar",
    accessLimiting: {
      includeSports: {
        DEFAULT: true,
      },
      includeLeagues: {
        DEFAULT: true,
      },
      includeBookmakers: {
        DEFAULT: true,
      },
      includeOthers: {
        DEFAULT: true,
      },
      sportsLimited: false,
      leaguesLimited: false,
      bookmakersLimited: false,
      othersLimited: false,
    },
    rateLimiting: {
      limits: {},
    },
  },
};
exports.TIER_CONFIG = TIER_CONFIG;
