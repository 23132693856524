const { sourceStatusProvidingUpdates } = require("@wagerlab/utils/aggregator/sourceStatus");
const { parseDate, parseTimestamp } = require("@wagerlab/utils/data/dates");
const { setObjVal, stripNullValues } = require("@wagerlab/utils/data/mutations");
const { isDate, isBoolean } = require("@wagerlab/utils/data/types");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");
const { hasAnyAutomatedScoring } = require("@wagerlab/utils/events/getters");
const { expandEventPeriods } = require("@wagerlab/utils/sports/periodStatus");
const _ = require("lodash");

const AUTO_CANCEL_WHEN_ALL_SOURCES_DETACHED_MS = 7 * 24 * 60 * 60 * 1000; // 7 days
// const FLAG_AS_DELAYED_MATCH_AFTER_MS = 65 * 60 * 1000; // 65 minutes
const FLAG_AS_DELAYED_MATCH_AFTER_MS = null; // Set this to a value to enable auto-delaying of matches

exports.refreshEventStatus = (eventData) => {
  eventData = handleAutoDelayCancellation(eventData);
  const currentEventStatus = eventData?.status || {};

  const eventType = eventData?.type;
  let { ended, started, cancelled, delayed, inBreak, currentPeriodID, previousPeriodID, displayShort, displayLong, hardStart, oddsCloseAtStart, oddsCloseAtDate, clock } = currentEventStatus;
  const { started: periodsStarted, ended: periodsEnded } = currentEventStatus?.periods || {};

  hardStart = hardStart ?? (eventType === "prop" ? false : true);

  if (!started && periodsStarted?.includes("game")) started = true;
  // LIVE and COMPLETED are calculated fields
  ended = !!(ended || cancelled || periodsEnded?.includes("game"));
  let completed = !!(ended && !cancelled);
  delayed = !!(delayed && !completed && !cancelled && !ended && !inBreak);
  let live = !!(started && !ended && !delayed && !inBreak);

  if (displayLong === "Upcoming" && (started || ended || live)) displayLong = "";

  if (!live) {
    currentPeriodID = "";
    previousPeriodID = "";
  }
  if (currentPeriodID && currentPeriodID === previousPeriodID) currentPeriodID = "";

  oddsCloseAtDate = isDate(oddsCloseAtDate) ? oddsCloseAtDate : null;
  if (oddsCloseAtDate || !oddsCloseAtStart) oddsCloseAtStart = null;

  if (!currentPeriodID && clock) clock = "";

  eventData.status = {
    ...currentEventStatus,
    started: !!started,
    completed: !!completed,
    cancelled: !!cancelled,
    delayed: !!delayed,
    currentPeriodID: currentPeriodID || "",
    previousPeriodID: previousPeriodID || "",
    displayLong: displayLong || "",
    displayShort: displayShort || "",
    ended: !!ended,
    live: !!live,
    hardStart: !!hardStart,
    oddsCloseAtStart: !!oddsCloseAtStart || null,
    oddsCloseAtDate: oddsCloseAtDate,
    clock: clock || null,
  };

  eventData.status.periods = expandEventPeriods(eventData);

  return eventData;
};

const handleAutoDelayCancellation = (eventData) => {
  const nowDate = new Date();
  const nowTimestamp = nowDate.getTime();
  const hasAutomatedScoring = hasAnyAutomatedScoring(eventData);
  const eventType = eventData?.type;
  let { finalized, startsAt, ended, completed, cancelled, delayed, previousStartsAt, hardStart, endsBy, oddsCloseAtDate } = eventData?.status || {};
  hardStart = hardStart ?? (eventType === "prop" ? false : true);

  if (!hasAutomatedScoring || ended || cancelled || completed || finalized || !isDate(startsAt)) return eventData;

  let latestStartsAtTimestamp = 0;
  [startsAt, ...(previousStartsAt || []), endsBy, oddsCloseAtDate].forEach((startsAtVal) => {
    const startsAtTimestamp = parseTimestamp(startsAtVal);
    if (startsAtTimestamp) latestStartsAtTimestamp = Math.max(latestStartsAtTimestamp, startsAtTimestamp);
  });

  if (!delayed && eventType === EVENT_TYPES.match && hardStart && FLAG_AS_DELAYED_MATCH_AFTER_MS) {
    const autoDelayedAfterTimestamp = latestStartsAtTimestamp + FLAG_AS_DELAYED_MATCH_AFTER_MS;
    if (nowTimestamp > autoDelayedAfterTimestamp) setObjVal(eventData, "status.delayed", true);
  }

  const eventSourceIDs = Object.keys(eventData?.sourceContext || {});
  for (let i = 0; i < eventSourceIDs.length; i++) {
    const sourceID = eventSourceIDs[i];
    const sourceContext = eventData?.sourceContext?.[sourceID];
    // If we have an enabled source that will (or has) provide scores, then we can avoid auto-cancelling
    if (sourceContext?.supportsEventScores && (sourceContext?.eventScoresFinal || sourceStatusProvidingUpdates(sourceContext?.sourceStatus))) {
      return eventData;
    }
  }

  const autoCancelAtTimestamp = latestStartsAtTimestamp + AUTO_CANCEL_WHEN_ALL_SOURCES_DETACHED_MS;

  if (nowTimestamp > autoCancelAtTimestamp) {
    setObjVal(eventData, "status.cancelled", true);
    setObjVal(eventData, "status.completed", false);
    setObjVal(eventData, "status.delayed", false);
    setObjVal(eventData, "status.ended", true);
    setObjVal(eventData, "status.live", false);
    setObjVal(eventData, "status.displayShort", "CANC");
    setObjVal(eventData, "status.displayLong", "Cancelled");
  }

  return eventData;
};
