import { Grid, Typography, Button, Box, Stack } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/shared/inputs/TextField";
import { SelectMenu } from "@wagerlab/admin/src/shared/inputs/SelectMenu";
import { SelectBoolean } from "@wagerlab/admin/src/shared/inputs/SelectBoolean";
import React, { Fragment } from "react";
import { useFormikContext, FieldArray } from "formik";
import { getOddsDisplay } from "@wagerlab/utils/displays";
import { isNumber } from "@wagerlab/utils/data/types";
import { ENABLED_SPORT_IDS, SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG, SPORT_ENABLED_LEAGUES } from "@wagerlab/utils/sports/leagueConfig";
import { DateTimePicker } from "@wagerlab/admin/src/shared/inputs/DateTimePicker";
import { NumberSlider } from "@wagerlab/admin/src/shared/inputs/NumberSlider";
import { getNewPropListItem, getOdds_fromDirectionValue, propSideWinnerResults } from "@wagerlab/utils/events/form";
import { SubmitButton } from "@wagerlab/admin/src/shared/inputs/SubmitButton";
import { TextButton } from "@wagerlab/admin/src/shared/Buttons";
import AddIcon from "@mui/icons-material/Add";

const sportOptions = ENABLED_SPORT_IDS.reduce((sportOpts, sportID) => ({ ...sportOpts, [sportID]: SPORT_CONFIG?.[sportID]?.name || SPORT_CONFIG?.[sportID]?.shortName || sportID }), {});

const ODDS_CLOSE_TYPE_OPTIONS = {
  start: "Automatically at event start time",
  date: "Automatically at specific time",
  manual: "When manually closed",
};

//TODO: need to clean up this component a bit and do a bit more testing/validation.

export const EventForm = ({ remoteEvent, formConfig }) => {
  const { values, initialValues, setFieldValue, setFieldTouched } = useFormikContext();
  const { editableFields } = formConfig || {};
  const propList = values?.propList || [];
  const matchResultsList = values?.matchResultsList || [];
  const eventEnded = !!values?.ended || !!values?.cancelled;

  const selectedSportID = values?.sportID;
  const leageIDOptions = SPORT_ENABLED_LEAGUES?.[selectedSportID] || [];
  const leagueOptions = leageIDOptions.reduce((leagueOpts, leagueID) => ({ ...leagueOpts, [leagueID]: LEAGUE_CONFIG?.[leagueID]?.name || LEAGUE_CONFIG?.[leagueID]?.shortName || leagueID }), {});

  const showOddsDatePicker = values.oddsCloseType === "date";

  const onCancelledChange = (newCancelled) => {
    setFieldTouched("cancelled", true, false);
    setFieldValue("cancelled", !!newCancelled);

    if (newCancelled) {
      if (!eventEnded) {
        setFieldTouched("ended", true, false);
        setFieldValue("ended", true);
      }
      propList.forEach((propOdd, index) => {
        if (!propOdd?.cancelled) {
          setFieldTouched(`propList[${index}].cancelled`, true, false);
          setFieldValue(`propList[${index}].cancelled`, true);
        }
        if (propOdd?.available) {
          setFieldTouched(`propList[${index}].available`, true, false);
          setFieldValue(`propList[${index}].available`, false);
        }
        if (!propOdd?.ended) {
          setFieldTouched(`propList[${index}].ended`, true, false);
          setFieldValue(`propList[${index}].ended`, true);
        }
      });
      matchResultsList.forEach((matchResultItem, index) => {
        if (!matchResultItem?.cancelled) {
          setFieldTouched(`matchResultsList[${index}].cancelled`, true, false);
          setFieldValue(`matchResultsList[${index}].cancelled`, true);
        }
        if (!matchResultItem?.ended) {
          setFieldTouched(`matchResultsList[${index}].ended`, true, false);
          setFieldValue(`matchResultsList[${index}].ended`, true);
        }
      });
    } else if (!initialValues?.cancelled) {
      propList.forEach((propOdd, index) => {
        const origPropOdd = initialValues?.propList?.[index];
        if (propOdd?.cancelled !== !!origPropOdd?.cancelled) {
          setFieldTouched(`propList[${index}].cancelled`, false, false);
          setFieldValue(`propList[${index}].cancelled`, !!origPropOdd?.cancelled);
        }
        if (!propOdd?.available !== !!origPropOdd?.available) {
          setFieldTouched(`propList[${index}].available`, false, false);
          setFieldValue(`propList[${index}].available`, !!origPropOdd?.available);
        }
        if (propOdd?.ended !== !!origPropOdd?.ended) {
          setFieldTouched(`propList[${index}].ended`, false, false);
          setFieldValue(`propList[${index}].ended`, !!origPropOdd?.ended);
        }
      });
      matchResultsList.forEach((matchResultItem, index) => {
        const origMatchResultItem = initialValues?.matchResultsList?.[index];
        if (matchResultItem?.cancelled !== !!origMatchResultItem?.cancelled) {
          setFieldTouched(`matchResultsList[${index}].cancelled`, false, false);
          setFieldValue(`matchResultsList[${index}].cancelled`, !!origMatchResultItem?.cancelled);
        }
        if (matchResultItem?.ended !== !!origMatchResultItem?.ended) {
          setFieldTouched(`matchResultsList[${index}].ended`, false, false);
          setFieldValue(`matchResultsList[${index}].ended`, !!origMatchResultItem?.ended);
        }
      });
    }
  };

  const onEndedChange = (newEnded) => {
    setFieldTouched("ended", true, false);
    setFieldValue("ended", !!newEnded);

    if (newEnded) {
      propList.forEach((propOdd, index) => {
        if (!propOdd?.ended) {
          setFieldTouched(`propList[${index}].ended`, true, false);
          setFieldValue(`propList[${index}].ended`, true);
        }
        if (propOdd?.available) {
          setFieldTouched(`propList[${index}].available`, true, false);
          setFieldValue(`propList[${index}].available`, false);
        }
      });
      matchResultsList.forEach((matchResultItem, index) => {
        if (!matchResultItem?.ended) {
          setFieldTouched(`matchResultsList[${index}].ended`, true, false);
          setFieldValue(`matchResultsList[${index}].ended`, true);
        }
      });
    } else if (!initialValues?.ended) {
      propList.forEach((propOdd, index) => {
        const origPropOdd = initialValues?.propList?.[index];
        if (propOdd?.ended !== !!origPropOdd?.ended) {
          setFieldTouched(`propList[${index}].ended`, false, false);
          setFieldValue(`propList[${index}].ended`, !!origPropOdd?.ended);
        }
        if (propOdd?.available !== !!origPropOdd?.available) {
          setFieldTouched(`propList[${index}].available`, false, false);
          setFieldValue(`propList[${index}].available`, !!origPropOdd?.available);
        }
      });
      matchResultsList.forEach((matchResultItem, index) => {
        const origMatchResultItem = initialValues?.matchResultsList?.[index];
        if (matchResultItem?.ended !== !!origMatchResultItem?.ended) {
          setFieldTouched(`matchResultsList[${index}].ended`, false, false);
          setFieldValue(`matchResultsList[${index}].ended`, !!origMatchResultItem?.ended);
        }
      });
    }
  };

  return (
    <Grid container spacing={3} sx={{ maxWidth: 1200, margin: "0 auto", padding: 2 }}>
      <Grid item xs={12} md={6}>
        <TextField name="eventID" label="Event ID" disabled={!editableFields?.eventID} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField name="eventName" label="Event Name" disabled={!editableFields?.eventName} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMenu name="sportID" label="Sport ID" options={sportOptions} disabled={!editableFields?.sportID} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMenu name="leagueID" label="League ID" options={leagueOptions} disabled={!editableFields?.leagueID} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Schedule/Timing</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateTimePicker name="startsAt" label="Start Time" required disabled={!editableFields?.startsAt} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectBoolean name="hardStart" label="Hard Start" disabled={!editableFields?.hardStart} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectMenu name="oddsCloseType" label="Close Odds At" options={ODDS_CLOSE_TYPE_OPTIONS} disabled={!editableFields?.oddsCloseType} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {showOddsDatePicker ? <DateTimePicker name="oddsCloseAtDate" label="Odds Close Date" required disabled={!editableFields?.oddsCloseAtDate} /> : null}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Status</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <SelectBoolean name="started" label="Started" disabled={!editableFields?.started} />
      </Grid>
      <Grid item xs={6} sm={3}>
        <SelectBoolean name="delayed" label="Delayed" disabled={!editableFields?.delayed} />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectBoolean
          name="ended"
          label="Ended"
          disabled={!editableFields?.ended || !!values?.cancelled || propList.some((propListItem) => !propSideWinnerResults(propListItem?.propSideWinner))}
          onChange={onEndedChange}
          tooltip="Whether the event has ended. This is disabled because grading an event means you must be setting ended from NO/false to YES/true. Do not save the form if you don't want ended to become true"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectBoolean
          name="cancelled"
          label="Cancelled"
          disabled={!editableFields?.cancelled}
          onChange={onCancelledChange}
          tooltip="Whether ALL odds/bets should be set to cancelled. Be careful setting this as it will automatically set all odds' cancelled states to this value"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField name="displayShort" label="Short Update" tooltip="ex: G-1 or C-EX" disabled={!editableFields?.displayShort} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="displayLong" label="Long Update" tooltip="One sentence description: Graded as a win for side 1." disabled={!editableFields?.displayLong} />
      </Grid>

      <EventFormPropOdds remoteEvent={remoteEvent} editableFields={editableFields} />

      {editableFields?.propResultsList || editableFields?.matchResultsList ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Results</Typography>
          </Grid>
          <EventFormPropResults remoteEvent={remoteEvent} editableFields={editableFields} />
          <EventFormMatchResults remoteEvent={remoteEvent} editableFields={editableFields} />
        </>
      ) : null}

      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={"Save Event"} />
      </Box>
    </Grid>
  );
};

const EventFormPropOdds = ({ remoteEvent, editableFields }) => {
  const { values, initialValues } = useFormikContext();

  if (!editableFields?.propOddsList) return null;

  const eventEnded = !!(remoteEvent?.status?.ended || values?.ended || values?.cancelled || initialValues?.ended || initialValues?.cancelled);

  const propList = values?.propList || [];
  const canAddNewProp = !eventEnded;
  if (!canAddNewProp && !propList?.length) return null;

  return (
    <Grid item xs={12}>
      <FieldArray
        name="propList"
        render={(arrayHelpers) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Custom Props</Typography>
            </Grid>
            {propList.map((propListItem, index) => {
              const {
                periodID,
                statID,
                propTitle,
                propDetails,
                available,
                cancelled,
                numericScoring,
                ended,
                oddIDs,
                propSideWinner,
                side1Display,
                side2Display,
                side1OddsDirection,
                side1OddsValue,
                propIndex,
              } = propListItem || {};
              return (
                <Fragment key={statID ?? index}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{statID?.toUpperCase?.() || "???"}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name={`propList[${index}].propTitle`} label="Prop Title" required />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name={`propList[${index}].propDetails`} label="Prop Details" />
                    </Grid>

                    <Grid item xs={4}>
                      <SelectBoolean name={`propList[${index}].available`} label="Available" required disabled={eventEnded} />
                    </Grid>

                    <Grid item xs={4}>
                      <SelectBoolean name={`propList[${index}].numericScoring`} label="Numeric Scoring" disabled />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>{`Side 1`}</Typography>
                      <TextField name={`propList[${index}].side1Display`} label="Side 1 Display Name" required />
                      <Typography>{`Side 1 Odds: ${getOdds_fromDirectionValue(side1OddsDirection, side1OddsValue)}`}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>{`Side 2`}</Typography>
                      <TextField name={`propList[${index}].side2Display`} label="Side 2 Display Name" required />
                      <Typography>{`Side 2 Odds: ${getOdds_fromDirectionValue(side1OddsDirection === "pos" ? "neg" : "pos", side1OddsValue)}`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>{`Set Odds (Basis is Side 1)`}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectMenu name={`propList[${index}].side1OddsDirection`} label="Direction" options={{ pos: "+", neg: "-" }} disabled={eventEnded} />
                    </Grid>
                    <Grid item xs={8} sx={{ marginLeft: 2, marginTop: 2 }}>
                      <NumberSlider name={`propList[${index}].side1OddsValue`} defaultValue={100} step={10} min={100} max={1000} valueLabelDisplay="on" disabled={eventEnded} />
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}

            {canAddNewProp ? (
              <Grid item xs={12}>
                <TextButton
                  onClick={() => {
                    const newPropListItem = getNewPropListItem(propList.length);
                    if (!newPropListItem?.statID || propList.some((p) => p?.statID === newPropListItem?.statID)) return;
                    arrayHelpers.push(newPropListItem);
                  }}
                  text={"Add New Prop"}
                  centered={true}
                  style={{ marginTop: 16 }}
                  Icon={<AddIcon />}
                />
              </Grid>
            ) : null}
          </Grid>
        )}
      />
    </Grid>
  );
};

const EventFormMatchResults = ({ remoteEvent, editableFields }) => {
  const { values, setFieldValue } = useFormikContext();

  if (!editableFields?.matchResultsList) return null;

  const eventCancelled = values?.cancelled;

  const matchResultsList = values?.matchResultsList || [];
  return (
    <Grid item xs={12}>
      {matchResultsList.map((matchResultItem, index) => {
        const { periodID, statID, statEntityID, score, cancelled, oddIDs } = matchResultItem || {};
        const prevSavedScore = isNumber(remoteEvent?.results?.[periodID]?.[statEntityID]?.[statID]) ? remoteEvent?.results?.[periodID]?.[statEntityID]?.[statID] : "<NONE>";
        const firstOddData = remoteEvent?.odds?.[oddIDs?.[0]];
        const fallbackDisplayName = `${periodID} ${statEntityID} ${statID}`;
        const displayNameLong = getOddsDisplay("resultStatName_long", firstOddData, remoteEvent) || fallbackDisplayName;
        const displayNameShort = getOddsDisplay("resultStatName_short", firstOddData, remoteEvent) || fallbackDisplayName;
        const prevScoreContext = `prev: ${prevSavedScore}`;

        return (
          <Grid item xs={12} md={6} key={index}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle2" sx={{ width: "66%" }}>
                {displayNameLong}
              </Typography>
              <Typography variant="body2" sx={{ width: "34%" }}>
                {prevScoreContext}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField name={`matchResultsList[${index}].score`} label={displayNameShort} type={"number"} fullWidth sx={{ width: "66%" }} disabled={cancelled} />
              <SelectBoolean name={`matchResultsList[${index}].cancelled`} label="Cancelled" required sx={{ width: "34%" }} disabled={!!eventCancelled} />
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

const EventFormPropResults = ({ remoteEvent, editableFields }) => {
  const { values, initialValues } = useFormikContext();

  if (!editableFields?.propResultsList) return null;

  const propList = values?.propList || [];
  const eventCancelled = !!values?.cancelled;
  const eventEnded = !!values?.ended;

  const disableResultsInputs = eventCancelled || eventEnded;

  return (
    <Grid item xs={12}>
      {propList.map((propListItem, index) => {
        const prevOddsCancelled = !!initialValues?.propList?.[index]?.cancelled;
        const prevOddsEnded = prevOddsCancelled || !!initialValues?.propList?.[index]?.ended;

        const {
          periodID,
          statID,
          propTitle,
          propDetails,
          available,
          cancelled,
          numericScoring,
          ended,
          oddIDs,
          propSideWinner,
          side1Display,
          side2Display,
          side1OddsDirection,
          side1OddsValue,
          propIndex,
        } = propListItem || {};

        return (
          <Fragment key={`${periodID || ""}${statID || ""}` ?? index}>
            <Grid container spacing={2} sx={{ marginBottom: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h6">{propTitle || "ERROR - do not submit"}</Typography>
                {propDetails ? <Typography>{propDetails}</Typography> : null}
              </Grid>

              <Grid item xs={6}>
                <SelectBoolean name={`propList[${index}].ended`} label="Ended (finalized/graded)" required disabled={prevOddsEnded || disableResultsInputs || !propSideWinnerResults(propSideWinner)} />
              </Grid>

              <Grid item xs={6}>
                <SelectBoolean name={`propList[${index}].cancelled`} label="Odds Cancelled" required disabled={prevOddsEnded || disableResultsInputs} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SelectMenu
                  name={`propList[${index}].propSideWinner`}
                  label="Result"
                  options={{ side1: `${side1Display} WINS`, side2: `${side2Display} WINS`, push: "PUSH - no winner" }}
                  disabled={cancelled}
                />
              </Grid>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};
