import { Typography, Select, MenuItem } from "@mui/material";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { useNavigate, useParams } from "react-router-dom";

const LEAGUE_OPTIONS = Object.values(LEAGUE_CONFIG).sort((league1, league2) => (league1?.name || "").localeCompare(league2?.name || ""));

export const LeaguePicker = ({}) => {
  const navigate = useNavigate();
  const screenParams = useParams();
  const selectedLeagueID = screenParams?.leagueID || "";

  const selectNewLeagueID = (e) => {
    const newLeagueID = e?.target?.value || "";
    const currentPath = window.location.pathname;
    // Get base path (link/teams or link/players)
    const basePath = currentPath.match(/\/link\/(teams|players)/)[0];
    navigate(`${basePath}/${newLeagueID}`);
  };

  return (
    <div>
      <Typography>Select League</Typography>
      <Select value={selectedLeagueID} onChange={selectNewLeagueID} autoWidth={true}>
        {LEAGUE_OPTIONS.map((leagueConfig) => (
          <MenuItem key={leagueConfig.leagueID} value={leagueConfig.leagueID}>
            {leagueConfig.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
