import React, { useState } from "react";
import { Typography, Container, Box, Tabs, Tab } from "@mui/material";
import { AttachUnknownEntities } from "@wagerlab/admin/src/unknowns/AttachUnknownEntities";
import { LeaguePicker } from "@wagerlab/admin/src/unknowns/LeaguePicker";
import { UnknownEntitiesReport } from "./UnknownEntitiesReport";

export const LinkTeamsPage = ({}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
      <Typography variant="h4">Teams</Typography>

      <LeaguePicker />

      <Box sx={{ marginTop: 4, marginBottom: 4 }}>
        <Tabs value={activeTab} onChange={(e, newTab) => setActiveTab(newTab)}>
          <Tab label="Attach Unknown Teams" />
          <Tab label="Unknown Teams Report" />
          <Tab label="Manage Existing Teams" />
        </Tabs>

        {activeTab === 0 && <AttachUnknownEntities type="unknownteams" />}
        {activeTab === 1 && <UnknownEntitiesReport type="unknownteams" />}
        {activeTab === 2 && null}
      </Box>
    </Container>
  );
};
