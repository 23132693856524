import { getAllPlayerFullNames, getNewPlayerID, getPlayerName, getPlayerSortName } from "@wagerlab/utils/players";
import React, { useState } from "react";
import { Box, Badge, Typography } from "@mui/material";
import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { isEmptyObject, isObject } from "@wagerlab/utils/data/types";

export const type = "unknownplayers";
export const typeDisplay = "Players";

export const getUnknownEntityID = (unknownEntity) => unknownEntity?.unknownPlayerID;
export const getEntityID = (existingEntity) => existingEntity?.playerID;
export const getSourceEntityID = (unknownEntity) => unknownEntity?.sourcePlayerID;

export const dataPath = "playerData";
export const namesPath = "playerData.names";

export const refreshConfigKey = "queryPlayerLookups";

export const fetchUnknownEntities = async (leagueID) => {
  if (!leagueID) return null;
  const result = await callAdminAPI("getUnknownPlayers", { leagueID });
  const { success, unknownPlayers } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  const sortedUnknownPlayers = (unknownPlayers || []).sort((unknownPlayer_a, unknownPlayer_b) =>
    getPlayerSortName(unknownPlayer_a?.playerData).localeCompare(getPlayerSortName(unknownPlayer_b?.playerData))
  );
  return sortedUnknownPlayers;
};

export const fetchExistingEntities = async (leagueID) => {
  // Each item must at least have a playerID field;
  if (!leagueID) return null;
  const result = await callAdminAPI("getPlayers", { leagueID, onlyIDs: true });
  const { success, players } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return players || [];
};

export const deleteUnknownEntity = async (unknownEntity) => {
  const { unknownPlayerID } = unknownEntity || {};
  if (!unknownPlayerID) return false;
  const result = await callAdminAPI("deleteUnknownPlayer", { unknownPlayerID });
  const { success } = result || {};
  if (!success) {
    console.error(result);
    return false;
  }
  return true;
};

export const matchUnknownEntity = async (unknownEntity, entityID) => {
  const { unknownPlayerID } = unknownEntity || {};
  if (!unknownPlayerID || !entityID) return false;
  const result = await callAdminAPI("matchUnknownPlayer", { unknownPlayer: unknownEntity, playerID: entityID });
  const { success } = result || {};
  if (!success) {
    console.error(result);
    return false;
  }
  return true;
};

export const validateUnknownEntity = (unknownEntity) => {
  if (!unknownEntity?.unknownPlayerID) return false;
  if (!unknownEntity?.playerData) return false;
  if (!unknownEntity?.sourceID) return false;
  if (!unknownEntity?.sourcePlayerID) return false;
  return true;
};

export const getNewEntityID = (unknownEntity, existingEntitiesMap) => {
  const playerName = getPlayerName(unknownEntity?.playerData);
  const leagueID = unknownEntity?.playerData?.leagueID || unknownEntity?.leagueID;
  if (!playerName || !leagueID || !isObject(existingEntitiesMap)) return null;
  const getIsPlayerIDAvailable = (potentialPlayerID) => !!potentialPlayerID && !existingEntitiesMap[potentialPlayerID];
  return getNewPlayerID(playerName, leagueID, getIsPlayerIDAvailable);
};

export const getUnknownEntityDisplayNames = (unknownEntity) => {
  return getAllPlayerFullNames(unknownEntity?.playerData);
};

export const getUnknownEntityDisplaySource = (unknownEntity) => {
  const { sourceID, sourcePlayerID } = unknownEntity || {};
  return [sourceID, sourcePlayerID].filter((srcDisp) => !!srcDisp);
};

export const extraCellHeader_1 = "Team";
export const ExtraCellComponent_1 = ({ unknownEntity }) => {
  const { teamID, mayInsteadMatchTeamID } = unknownEntity || {};
  const [showMayInstead, setShowMayInstead] = useState(false);

  const handleToggle = () => {
    setShowMayInstead((prev) => !prev);
  };

  return (
    <Box>
      {mayInsteadMatchTeamID ? (
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleToggle}>
          <Typography sx={{ color: "red" }}>{teamID || "-"}</Typography>
          <Badge sx={{ ml: 2 }} color="error" badgeContent="!" overlap="circular" />
        </Box>
      ) : (
        <Typography>{teamID || "<no team>"}</Typography>
      )}
      {mayInsteadMatchTeamID && showMayInstead && <Typography sx={{ marginTop: 1, fontStyle: "italic" }}>May instead match: {mayInsteadMatchTeamID}</Typography>}
    </Box>
  );
};
