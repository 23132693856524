import React, { useMemo, useState } from "react";
import { EventTable } from "@wagerlab/admin/src/events/list/EventTable";
import { Typography, Container } from "@mui/material";
import { SearchBar } from "@wagerlab/admin/src/shared/inputs/SearchBar";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EventQueryBuilder } from "@wagerlab/admin/src/events/list/EventQueryBuilder";
import { filterEvents, generateEventSearchIndexMap } from "@wagerlab/admin/src/events/list/filterEvents";

export const EventsListPage = ({}) => {
  const [searchString, setSearchString] = useState("");
  const [events, setEvents] = useState([]);
  const [queryExpanded, setQueryExpanded] = useState(true);
  const [tableExpanded, setTableExpanded] = useState(false);

  const eventSearchIndexMap = useMemo(() => generateEventSearchIndexMap(events), [events]);

  const filteredEvents = useMemo(() => filterEvents(events, eventSearchIndexMap, searchString), [events, eventSearchIndexMap, searchString]);

  const handleQueryResult = (queryResult) => {
    if (!queryResult?.length) return;
    setEvents(queryResult);
    setTableExpanded(true);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
      <Accordion expanded={queryExpanded} sx={{ marginBottom: 8 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setQueryExpanded(!queryExpanded)}>
          <Typography variant="h4">Build Query</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EventQueryBuilder handleQueryResult={handleQueryResult} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={tableExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setTableExpanded(!tableExpanded)}>
          <Typography variant="h4">View Results</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchBar onSearchInputChange={setSearchString} label="Search Events" />
          <EventTable events={filteredEvents} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
