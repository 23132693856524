const { getIDString } = require("@wagerlab/utils/aggregator/lookups");
const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");

const formatTeamID = (teamNameLong, leagueID) => {
  const teamNameID = getIDString(teamNameLong);
  if (!teamNameID || !LEAGUE_CONFIG[leagueID]?.leagueID) return "";
  return `${teamNameID}_${leagueID}`;
};
exports.formatTeamID = formatTeamID;

const getNewTeamID = (teamNameLong, leagueID, getIsTeamIDAvailable) => {
  const potentialTeamID = formatTeamID(teamNameLong, leagueID);
  if (!potentialTeamID) return "";
  if (getIsTeamIDAvailable(potentialTeamID)) return potentialTeamID;
  return "";
};
exports.getNewTeamID = getNewTeamID;

exports.getTeamName = (teamData, allowMedium = true) => {
  const { long, medium } = teamData?.names || {};
  if (long) return long;
  if (medium && allowMedium) return medium;
  return "";
};

//TODO: add other name fields
exports.getAllTeamNames = (teamData) => {
  const { long, medium, short } = teamData?.names || {};

  const allNamesSet = new Set([long, medium, short].filter((name) => !!name));
  return Array.from(allNamesSet);
};
