const unidecode = require("unidecode");

const getNormalizedString = (str) => unidecode(str?.trim?.() || "");
exports.getNormalizedString = getNormalizedString;

const getSearchableString = (str) =>
  getNormalizedString(str)
    ?.toLowerCase?.()
    ?.replace?.(/[^a-z0-9]+/g, "") || "";
exports.getSearchableString = getSearchableString;

const getIDString = (str) =>
  getNormalizedString(str)
    .replace(/\s+/g, "_")
    .toUpperCase()
    .replace(/[^A-Z0-9_]+/g, "");
exports.getIDString = getIDString;
