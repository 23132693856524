import { createTheme } from "@mui/material/styles";
import {
  INFO_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
  ERROR_COLOR,
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_COLOR_DARK,
  SECONDARY_COLOR_LIGHT,
  SECONDARY_COLOR_DARK,
  ACCENT_COLOR,
  ACCENT_COLOR_LIGHT,
  ACCENT_COLOR_DARK,
  ALTERNATE_COLOR,
  LIGHT_CONTENT_COLOR,
} from "@wagerlab/utils/styles/colors";

export const DEFAULT_MATERIAL_THEME = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: PRIMARY_COLOR_LIGHT,
      dark: PRIMARY_COLOR_DARK,
    },
    secondary: {
      main: SECONDARY_COLOR,
      light: SECONDARY_COLOR_LIGHT,
      dark: SECONDARY_COLOR_DARK,
    },
    accent: {
      main: ACCENT_COLOR,
      light: ACCENT_COLOR_LIGHT,
      dark: ACCENT_COLOR_DARK,
      contrastText: LIGHT_CONTENT_COLOR,
    },
    alternate: {
      main: ALTERNATE_COLOR,
      light: ALTERNATE_COLOR,
      dark: ALTERNATE_COLOR,
      contrastText: LIGHT_CONTENT_COLOR,
    },
    error: {
      main: ERROR_COLOR,
    },
    warning: {
      main: WARNING_COLOR,
    },
    info: {
      main: INFO_COLOR,
    },
    success: {
      main: SUCCESS_COLOR,
    },
  },
});
