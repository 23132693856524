const { copyData, setObjVal } = require("@wagerlab/utils/data/mutations");
const { parseNumber } = require("@wagerlab/utils/data/numbers");
const { isBoolean, isDate, isNumber, isInteger, isObject, isString } = require("@wagerlab/utils/data/types");
const { newID } = require("@wagerlab/utils/database/legacyDatabaseUtils/firestore");
const { oddsSetSorter } = require("@wagerlab/utils/events/oddOrdering");
const { refreshEvent } = require("@wagerlab/utils/events/refresh");
const { asMoneylineInt, asMoneylineString, getInverseOdds } = require("@wagerlab/utils/odds/converters");
const { getOddID } = require("@wagerlab/utils/odds/identifiers");
const { getOddScore, isSpecialCaseScoring } = require("@wagerlab/utils/odds/scoring");
const _ = require("lodash");
const { parseDate } = require("@wagerlab/utils/data/dates");
const { ENABLED_LEAGUE_IDS } = require("@wagerlab/utils/sports/leagueConfig");
const { ENABLED_SPORT_IDS } = require("@wagerlab/utils/sports/sportConfig");
const Yup = require("yup");
const moment = require("moment-timezone");

const getEventFormSchema = (dateTypes) =>
  Yup.object().shape({
    eventID: Yup.string().required("Event ID is missing"),
    type: Yup.string().oneOf(["match", "prop"], "Type must be either 'match' or 'prop'").required("Event type is Required"),
    eventName: Yup.string(),

    sportID: Yup.string().oneOf(ENABLED_SPORT_IDS, "Sport ID is invalid or not enabled").required("Sport ID is Required"),
    leagueID: Yup.string().oneOf(ENABLED_LEAGUE_IDS, "League ID is invalid or not enabled").required("League ID is Required"),

    startsAt: dateTypes === "moment" ? momentDateValidation("Start date/time", false) : stringDateValidation("Start date/time", false),
    hardStart: Yup.boolean().required("Hard Start is Required"),
    oddsCloseAtDate: dateTypes === "moment" ? momentDateValidation("Odds close date/time", true) : stringDateValidation("Odds close date/time", true),
    oddsCloseType: Yup.string().required("Odds Close At Start is Required").oneOf(["start", "date", "manual"], "Odds close type must be either `start`, `date`, or `manual`"),

    displayShort: Yup.string(),
    displayLong: Yup.string(),

    started: Yup.boolean().required("Started must be Yes or No"),
    delayed: Yup.boolean().required("Delayed must be Yes or No"),
    cancelled: Yup.boolean().required("Event cancelled must be Yes or No"),
    ended: Yup.boolean().required("Event ended must be Yes or No"),

    matchResultsList: Yup.array()
      .of(
        Yup.object({
          periodID: Yup.string().required("periodID is required"),
          statID: Yup.string().required("statID is required"),
          statEntityID: Yup.string().required("statEntityID is required"),
          cancelled: Yup.boolean().required("Result cancelled is required"),
          score: Yup.mixed().when("cancelled", {
            is: (isCancelled) => !isCancelled,
            then: () => Yup.number().required("score is required"),
            otherwise: () => Yup.mixed().nullable(),
          }),
        })
      )
      .required("results are required"),

    propList: Yup.array()
      .of(
        Yup.object({
          periodID: Yup.string().required("periodID is required"),
          statID: Yup.string().required("statID is required"),
          cancelled: Yup.boolean().required("Result cancelled is required"),
          ended: Yup.boolean().required("Result ended is required"),
          propIndex: Yup.number().required("propIndex is required").min(0, "propIndex must be a positive integer").integer("propIndex must be an integer"),
          propTitle: Yup.string().required("propTitle is required"),
          propDetails: Yup.string(),
          numericScoring: Yup.boolean().required("numericScoring is required"),
          side1Display: Yup.string().required("side1Display is required"),
          side2Display: Yup.string().required("side2Display is required"),
          available: Yup.boolean().required("available is required"),
          side1OddsDirection: Yup.string().oneOf(["pos", "neg"], "side1OddsDirection must be either 'pos' or 'neg'").required("side1OddsDirection is required"),
          side1OddsValue: Yup.number().required("side1OddsValue is required").min(100, "side1OddsValue must be at least 100").integer("side1OddsValue must be an integer"),
          oddIDs: Yup.array().of(Yup.string().required("oddIDs is required")).min(1, "At least one odd is required").max(2, "At most two odds are allowed"),
          propSideWinner: Yup.string().oneOf(["side1", "side2", "push", "", null, undefined], "propSideWinner must be either 'side1', 'side2', 'push'"),
        })
      )
      .required("propList is required"),
    // .min(eventForm?.type === "prop" ? 1 : 0, "At least one prop is required"),
  });
exports.getEventFormSchema = getEventFormSchema;

const momentDateValidation = (fieldDisplayName, isNullable) => {
  const baseShape = isNullable ? Yup.mixed().nullable() : Yup.mixed();
  return baseShape.test("is-moment-object-or-null", `${fieldDisplayName} not a valid date`, (value) => {
    if (value == null) return isNullable;
    return moment.isMoment(value) && value?.isValid?.();
  });
};
const stringDateValidation = (fieldDisplayName, isNullable) => {
  const baseShape = isNullable ? Yup.mixed().nullable() : Yup.mixed();
  return baseShape.test("is-date-string-or-null", `${fieldDisplayName} not a valid date`, (value) => {
    if (value == null) return isNullable;
    const date = parseDate(value, null);
    return !!date;
  });
};

const getEventFormConfig = (eventData, action, remoteEventID) => {
  let error = "";

  let isNew = false;
  let isReGrade = false;

  if (action === "new") {
    isNew = true;
    if (eventData?.eventID) error = `event already exists: ${eventData?.eventID}`;
  } else if (action === "edit") {
    if (!remoteEventID) error = `eventID not provided`;
    else if (!eventData?.eventID) error = `Event not available in database: ${remoteEventID}`;
    else if (eventData?.eventID !== remoteEventID) error = `event ID is mismatched: ${remoteEventID} !== ${eventData?.eventID}`;
    else if (eventData?.type !== "prop" && eventData?.type !== "match") error = `Can't edit this event type here`;
    else if (eventData?.manual && eventData?.type !== "prop") error = `Can't edit a non-prop manual event here`;

    const { started, ended, cancelled, completed, finalized } = eventData?.status || {};
    if (!finalized && (ended || cancelled || completed))
      error =
        error || `This event has already been graded. Try re-grading it if you need to make changes. If it's not available for re-grading and you recently graded it, wait a moment and try again.`;
    isReGrade = !!finalized;
  } else {
    error = "Invalid event action";
  }
  const isEdit = !isNew && !isReGrade;
  const isPropEdit = isEdit && eventData?.type === "prop";

  const editableFields = {
    eventID: false,
    type: false,
    eventName: !isReGrade,
    sportID: isNew || (isEdit && isPropEdit),
    leagueID: isNew || (isEdit && isPropEdit),
    startsAt: !isReGrade,
    hardStart: !isReGrade,
    oddsCloseType: !isReGrade,
    oddsCloseAtDate: !isReGrade,
    started: isEdit || isReGrade,
    delayed: isEdit,
    ended: isEdit,
    cancelled: isEdit || isReGrade,
    displayShort: true,
    displayLong: true,
    propOddsList: isNew || (isEdit && isPropEdit), //tied to propList
    propResultsList: isEdit || isReGrade, //tied to propList
    matchResultsList: isEdit || isReGrade,
  };

  return {
    error,
    editableFields,
    isNew,
    isReGrade,
    isEdit,
  };
};
exports.getEventFormConfig = getEventFormConfig;

const serializeEventFormData = (eventForm) => {
  if (!isObject(eventForm)) return null;
  const serializedEventForm = copyData(eventForm);
  if (eventForm?.startsAt) {
    const startsAtMoment = moment(eventForm?.startsAt);
    serializedEventForm.startsAt = startsAtMoment.isValid() ? startsAtMoment.toISOString() : null;
  }
  if (eventForm?.oddsCloseAtDate) {
    const oddsCloseDateMoment = moment(eventForm?.oddsCloseAtDate);
    serializedEventForm.oddsCloseAtDate = oddsCloseDateMoment.isValid() ? oddsCloseDateMoment.toISOString() : null;
  }
  return JSON.parse(JSON.stringify(serializedEventForm));
};
exports.serializeEventFormData = serializeEventFormData;

const buildEventFromFormData = (eventForm, prevEvent) => {
  let event = prevEvent?.eventID
    ? copyData(prevEvent)
    : {
        eventID: eventForm.eventID,
        type: eventForm.type,
        sportID: eventForm.sportID,
        leagueID: eventForm.leagueID,
        manual: true,
        props: {},
        results: {},
        odds: {},
      };

  if (prevEvent?.status?.finalized) setObjVal(event, "status.reGrade", true);

  //if its a match event which is ended, consider setting reGrade

  setObjVal(event, "status.cancelled", eventForm.cancelled);
  setObjVal(event, "status.started", eventForm.started);
  setObjVal(event, "status.ended", eventForm.ended);
  setObjVal(event, "status.delayed", eventForm.delayed);
  setObjVal(event, "status.completed", eventForm.ended && !eventForm.cancelled);
  setObjVal(event, "status.live", eventForm.started && !eventForm?.ended && !eventForm.delayed);

  const startsAtFallback = parseDate(prevEvent?.status?.startsAt, null);
  const startsAt = parseDate(eventForm.startsAt, startsAtFallback);
  setObjVal(event, "status.startsAt", startsAt);
  setObjVal(event, "status.hardStart", eventForm.hardStart);

  const { oddsCloseAtStart, oddsCloseAtDate } = getEventOddsClose_fromFormOddsClose(eventForm.oddsCloseType, eventForm.oddsCloseAtDate);
  setObjVal(event, "status.oddsCloseAtStart", oddsCloseAtStart);
  setObjVal(event, "status.oddsCloseAtDate", oddsCloseAtDate);

  setObjVal(event, "status.displayShort", eventForm.displayShort);
  setObjVal(event, "status.displayLong", eventForm.displayLong);

  setObjVal(event, "activity.count", prevEvent?.activity?.count || 0);
  setObjVal(event, "activity.score", prevEvent?.activity?.score || 0);

  setObjVal(event, "manual", true);

  (eventForm.matchResultsList || []).forEach((resultsListItem) => {
    const { periodID, statID, statEntityID, cancelled, score, oddIDs } = resultsListItem || {};
    if (!periodID || !statID || !statEntityID) return;
    if (cancelled) {
      setObjVal(event, `results.${periodID}.${statEntityID}.${statID}`, null);
      Object.entries(event?.odds || {}).forEach(([oddID, oddData]) => {
        if (oddData?.statID === statID && oddData?.periodID === periodID && oddData?.statEntityID === statEntityID) {
          setObjVal(event, `odds.${oddID}.cancelled`, true);
        }
      });
    } else if (isNumber(score)) {
      setObjVal(event, `results.${periodID}.${statEntityID}.${statID}`, score);
    }
  });

  (eventForm?.propList || []).forEach((propListItem) => {
    const { periodID, statID, propTitle, propDetails, available, cancelled, numericScoring, ended, oddIDs, propSideWinner, side1Display, side2Display, side1OddsDirection, side1OddsValue, propIndex } =
      propListItem || {};

    setObjVal(event, `props.${statID}.statID`, statID);
    setObjVal(event, `props.${statID}.propTitle`, propTitle);
    setObjVal(event, `props.${statID}.propDetails`, propDetails);
    setObjVal(event, `props.${statID}.numericScoring`, numericScoring);
    setObjVal(event, `props.${statID}.sides.side1.sideID`, "side1");
    setObjVal(event, `props.${statID}.sides.side1.sideDisplay`, side1Display);
    setObjVal(event, `props.${statID}.sides.side2.sideID`, "side2");
    setObjVal(event, `props.${statID}.sides.side2.sideDisplay`, side2Display);

    const side1OddID = getOddID({ statID, periodID, betTypeID: "prop", sideID: "side1", statEntityID: "side1" });
    const side2OddID = getOddID({ statID, periodID, betTypeID: "prop", sideID: "side2", statEntityID: "side2" });

    const side1Odds = getOdds_fromDirectionValue(side1OddsDirection, side1OddsValue);
    const side2Odds = asMoneylineString(getInverseOdds(side1Odds));

    setObjVal(event, `odds.${side1OddID}.oddID`, side1OddID);
    setObjVal(event, `odds.${side1OddID}.statID`, statID);
    setObjVal(event, `odds.${side1OddID}.periodID`, periodID);
    setObjVal(event, `odds.${side1OddID}.betTypeID`, "prop");
    setObjVal(event, `odds.${side1OddID}.sideID`, "side1");
    setObjVal(event, `odds.${side1OddID}.statEntityID`, "side1");
    setObjVal(event, `odds.${side1OddID}.fairOddsAvailable`, !!available);
    setObjVal(event, `odds.${side1OddID}.started`, eventForm.started);
    setObjVal(event, `odds.${side1OddID}.fairOdds`, side1Odds);
    setObjVal(event, `odds.${side1OddID}.ended`, !!ended || !!cancelled || eventForm.ended || eventForm.cancelled);
    setObjVal(event, `odds.${side1OddID}.cancelled`, !!cancelled || eventForm.cancelled);

    setObjVal(event, `odds.${side2OddID}.oddID`, side2OddID);
    setObjVal(event, `odds.${side2OddID}.statID`, statID);
    setObjVal(event, `odds.${side2OddID}.periodID`, periodID);
    setObjVal(event, `odds.${side2OddID}.betTypeID`, "prop");
    setObjVal(event, `odds.${side2OddID}.sideID`, "side2");
    setObjVal(event, `odds.${side2OddID}.statEntityID`, "side2");
    setObjVal(event, `odds.${side2OddID}.fairOddsAvailable`, !!available);
    setObjVal(event, `odds.${side2OddID}.started`, eventForm.started);
    setObjVal(event, `odds.${side2OddID}.fairOdds`, side2Odds);
    setObjVal(event, `odds.${side2OddID}.ended`, !!ended || !!cancelled || eventForm.ended || eventForm.cancelled);
    setObjVal(event, `odds.${side2OddID}.cancelled`, !!cancelled || eventForm.cancelled);

    const propSideResults = propSideWinnerResults(propSideWinner);
    if (!propSideResults) return;
    setObjVal(event, "status.started", true);
    setObjVal(event, `results.${periodID}.side1.${statID}`, propSideResults.side1);
    setObjVal(event, `results.${periodID}.side2.${statID}`, propSideResults.side2);
  });

  event = refreshEvent(event);
  return event;
};
exports.buildEventFromFormData = buildEventFromFormData;

const initializeEventFormData = (eventData, formConfig) => {
  if (!eventData) {
    return {
      eventID: newID("Events"),
      type: "prop",
      eventName: "",
      sportID: "",
      leagueID: "",

      startsAt: null,
      hardStart: false,
      oddsCloseType: "start",
      oddsCloseAtDate: null,
      displayShort: "",
      displayLong: "",

      started: false,
      delayed: false,
      ended: false,
      cancelled: false,

      propList: [getNewPropListItem(0)],
      matchResultsList: [],
      initialFormConfig: formConfig,
    };
  }

  const { oddsCloseType, oddsCloseAtDate } = getFormOddsClose_fromEventOddsClose(eventData);

  return {
    eventID: eventData?.eventID,
    type: eventData?.type || "",
    eventName: eventData?.eventName || "",
    sportID: eventData?.sportID || "",
    leagueID: eventData?.leagueID || "",

    startsAt: moment(eventData?.status?.startsAt) || null,
    hardStart: isBoolean(eventData?.status?.hardStart) ? eventData?.status?.hardStart : eventData?.type === "match" ? true : false,
    oddsCloseType: oddsCloseType,
    oddsCloseAtDate: oddsCloseAtDate,
    displayShort: eventData?.status?.displayShort || "",
    displayLong: eventData?.status?.displayLong || "",
    started: !!eventData?.status?.started,
    delayed: !!eventData?.status?.delayed,
    ended: !!eventData?.status?.ended,
    cancelled: !!eventData?.status?.cancelled,
    propList: generatePropList(eventData),
    matchResultsList: generateMatchResultsList(eventData),
    initialFormConfig: formConfig,
  };
};
exports.initializeEventFormData = initializeEventFormData;

const NEW_PROP_PERIOD_ID = "game";
const getNewPropListItem = (propIndex) => {
  if (!isInteger(propIndex) || propIndex < 0) return null;
  const statID = `prop${propIndex}`;
  const side1OddID = getOddID({ statID, periodID: NEW_PROP_PERIOD_ID, betTypeID: "prop", sideID: "side1", statEntityID: "side1" });
  const side2OddID = getOddID({ statID, periodID: NEW_PROP_PERIOD_ID, betTypeID: "prop", sideID: "side2", statEntityID: "side2" });
  const oddIDs = [side1OddID, side2OddID];
  return {
    periodID: NEW_PROP_PERIOD_ID,
    propIndex,
    statID,
    propTitle: "",
    propDetails: "",
    numericScoring: false,
    side1Display: "",
    side2Display: "",
    available: true,
    side1OddsDirection: "pos",
    side1OddsValue: 100,
    cancelled: false,
    ended: false,
    oddIDs,
    propSideWinner: "",
  };
};
exports.getNewPropListItem = getNewPropListItem;

const getOdds_fromDirectionValue = (direction, value) => {
  let valueInt = Math.abs(parseInt(value) || 100);
  if (!valueInt || valueInt < 100) valueInt = 100;
  if (valueInt > 10000) valueInt = 10000;
  return asMoneylineString(`${direction === "neg" ? "-" : "+"}${valueInt}`);
};
exports.getOdds_fromDirectionValue = getOdds_fromDirectionValue;

const getDirectionValue_fromOdds = (odds) => {
  const oddsInt = asMoneylineInt(odds);
  const value = oddsInt ? Math.abs(oddsInt) : 100;
  const direction = oddsInt >= 0 ? "pos" : "neg";
  return { direction, value };
};
exports.getDirectionValue_fromOdds = getDirectionValue_fromOdds;

const propSideWinnerResults = (propSideWinner) => {
  if (propSideWinner === "side1") return { side1: 1, side2: -1 };
  if (propSideWinner === "side2") return { side1: -1, side2: 1 };
  if (propSideWinner === "push") return { side1: 0, side2: 0 };
  return null;
};
exports.propSideWinnerResults = propSideWinnerResults;

const getFormOddsClose_fromEventOddsClose = (eventData) => {
  const oddsCloseAtStart = eventData?.status?.oddsCloseAtStart;
  const oddsCloseAtDate = eventData?.status?.oddsCloseAtDate;
  const hasOddsCloseAtDate = isDate(oddsCloseAtDate);

  if (oddsCloseAtStart) return { oddsCloseType: "start", oddsCloseAtDate: null };
  if (hasOddsCloseAtDate) return { oddsCloseType: "date", oddsCloseAtDate: moment(oddsCloseAtDate) };
  return { oddsCloseType: "manual", oddsCloseAtDate: null };
};

const getEventOddsClose_fromFormOddsClose = (oddsCloseType, oddsCloseAtDateInput) => {
  let oddsCloseAtDate = null;
  if (oddsCloseAtDateInput && moment.isMoment(oddsCloseAtDateInput) && oddsCloseAtDateInput.isValid()) oddsCloseAtDate = oddsCloseAtDateInput.toDate();
  else if (oddsCloseAtDateInput && (isString(oddsCloseAtDateInput) || isDate(oddsCloseAtDateInput))) oddsCloseAtDate = parseDate(oddsCloseAtDateInput, null);

  if (oddsCloseType === "date" && isDate(oddsCloseAtDate)) return { oddsCloseAtStart: false, oddsCloseAtDate };
  if (oddsCloseType === "start") return { oddsCloseAtStart: true, oddsCloseAtDate: null };
  return { oddsCloseAtStart: false, oddsCloseAtDate: null };
};

const generateMatchResultsList = (eventData) => {
  let matchResultsList = [];
  const pathsAddedMap = {};

  if (eventData?.type === "match") {
    matchResultsList.push({ periodID: "game", statID: "points", statEntityID: "home", score: eventData?.results?.game?.home?.points });
    matchResultsList.push({ periodID: "game", statID: "points", statEntityID: "away", score: eventData?.results?.game?.away?.points });
    pathsAddedMap["game.home.points"] = true;
    pathsAddedMap["game.away.points"] = true;
  }

  let uncancelledPaths = {};
  let endedPaths = {};
  let cancelledPaths = {};
  let oddIDGroups = {};
  Object.entries(eventData?.odds || {}).forEach(([oddID, oddData]) => {
    const { periodID, statID, statEntityID, cancelled, ended, oddID: oid, betTypeID } = oddData || {};
    if (betTypeID === "prop") return;
    if (isSpecialCaseScoring(oddData, eventData)) return;
    const score = getOddScore(oddData, eventData);
    if (!periodID || !statID || !statEntityID || !oddID || oddID !== oid) return;
    if (ended) endedPaths[`${periodID}.${statEntityID}.${statID}`] = true;
    if (cancelled) cancelledPaths[`${periodID}.${statEntityID}.${statID}`] = true;
    const oddIDGroup = oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || [];
    if (!oddIDGroup.includes(oddID)) oddIDGroup.push(oddID);
    oddIDGroups[`${periodID}.${statEntityID}.${statID}`] = oddIDGroup;
    if (pathsAddedMap[`${periodID}.${statEntityID}.${statID}`]) return;
    pathsAddedMap[`${periodID}.${statEntityID}.${statID}`] = true;
    matchResultsList.push({ periodID, statID, statEntityID, score });
  });

  matchResultsList = matchResultsList
    .map((resultSummary) => {
      const { periodID, statID, statEntityID, score } = resultSummary || {};
      const cancelled = !!cancelledPaths[`${periodID}.${statEntityID}.${statID}`];
      const ended = !!endedPaths[`${periodID}.${statEntityID}.${statID}`];
      const oddIDs = (oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || []).sort();
      return { ...resultSummary, cancelled, ended, oddIDs };
    })
    .sort((a, b) => oddsSetSorter(eventData?.odds[a?.oddIDs?.[0]], eventData?.odds[b?.oddIDs?.[0]]));

  return matchResultsList;
};

const generatePropList = (eventData) => {
  const propList = [];
  Object.entries(eventData?.props || {}).map(([propStatID, propData]) => {
    const { statID, propTitle, propDetails, numericScoring, sides } = propData || {};
    if (!statID) return null;

    const oddObjectsSamePropStatID = Object.values(eventData?.odds || {}).filter(
      (odd) => odd?.statID === statID && odd.betTypeID === "prop" && (odd.statEntityID === "side1" || odd.statEntityID === "side2") && odd.statEntityID === odd.sideID
    );

    const propIndexString = statID.replace("prop", "");
    const propIndex = parseNumber(propIndexString, null, "integer", "non-negative");

    if (propIndex == null) return null;

    const periodIDs = _.uniq(oddObjectsSamePropStatID.map((odd) => odd?.periodID));

    periodIDs.forEach((periodID) => {
      const oddObjects = oddObjectsSamePropStatID.filter((odd) => odd?.periodID === periodID);

      const cancelled = oddObjects.some((odd) => odd?.cancelled);
      const available = oddObjects.every((odd) => odd?.available);
      const ended = oddObjects.some((odd) => odd?.cancelled || odd?.ended);
      const oddIDs = oddObjects.map((odd) => odd?.oddID);

      const side1OddID = getOddID({ statID, periodID, betTypeID: "prop", sideID: "side1", statEntityID: "side1" });
      const side1OddData = eventData?.odds?.[side1OddID];
      const side1DirectionValue = getDirectionValue_fromOdds(side1OddData?.odds);
      const side1OddsDirection = side1DirectionValue?.direction || "pos";
      const side1OddsValue = side1DirectionValue?.value || 100;

      const side1Display = sides?.side1?.sideDisplay || "Side 1";
      const side2Display = sides?.side2?.sideDisplay || "Side 2";

      const side1Score = eventData?.results?.[periodID]?.side1?.[statID];
      const side2Score = eventData?.results?.[periodID]?.side2?.[statID];
      const hasScore = isNumber(side1Score) && isNumber(side2Score);
      const propSideWinner = !hasScore ? "" : side1Score > side2Score ? "side1" : side2Score > side1Score ? "side2" : "push";
      propList.push({
        periodID,
        numericScoring,
        propTitle,
        propDetails,
        statID,
        cancelled,
        available,
        ended,
        oddIDs,
        propSideWinner,
        side1Display,
        side2Display,
        propIndex,
        side1OddsDirection,
        side1OddsValue,
      });
    });
  });
  return propList.sort((a, b) => a.propIndex - b.propIndex);
};
