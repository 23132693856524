import { Suspense } from "react";
import { LoadingPage } from "@wagerlab/admin/src/landing/Loading_Page";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "@wagerlab/admin/src/AppRouter";
import moment from "moment-timezone";
import { ThemeProvider } from "@mui/material/styles";
import { DEFAULT_MATERIAL_THEME } from "@wagerlab/admin/src/utils/styles";
import { GlobalDialog, GlobalToast } from "@wagerlab/admin/src/utils/alerts";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <ThemeProvider theme={DEFAULT_MATERIAL_THEME}>
            <Suspense fallback={<LoadingPage />}>
              <AppRouter />
              <GlobalToast />
              <GlobalDialog />
            </Suspense>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
