// bookmakerID values should never contain: . [ ]

const BOOKMAKER_IDS = {
  "ONEXBET": "1xbet",
  "1XBET": "1xbet",
  // "3ET": "3et",
  // "5DIMES": "5dimes",
  "SPORT888": "888sport",
  "888SPORT": "888sport",
  // "ACTION247": "action247",
  "BALLYBET": "ballybet",
  "BARSTOOL": "barstool",
  "BARSTOOL_SPORTSBOOK": "barstool",
  "BET365": "bet365",
  // "BETAMERICA": "betamerica",
  "BETANYSPORTS": "betanysports",
  "BETCLIC": "betclic",
  "BETFAIR_EXCHANGE": "betfairexchange",
  "BETFAIR_SPORTSBOOK": "betfairsportsbook",
  "BETMGM": "betmgm",
  "BETONLINE": "betonline",
  "BETONLINEAG": "betonline",
  "BETPARX": "betparx",
  // "BETR_DFS": "betrdfs",
  "BETRIVERS": "betrivers",
  "BETR_AU": "betrsportsbook",
  "BETR_SPORTSBOOK": "betrsportsbook",
  "BETSSON": "betsson",
  "BETUS": "betus",
  "BETVICTOR": "betvictor",
  "BETWAY": "betway",
  // "BETWILDWOOD": "betwildwood",
  "BLUEBET": "bluebet",
  "BOOKMAKEREU": "bookmakereu",
  // "BORGATA": "borgata",
  "BOVADA": "bovada",
  "BOYLESPORTS": "boylesports",
  // "BET_BUCKEYE_SPORTS": "bet_buckeye_sports",
  "CAESARS": "caesars",
  "WILLIAMHILL_US": "caesars",
  "CASUMO": "casumo",
  "CIRCA": "circa",
  "COOLBET": "coolbet",
  "CORAL": "coral",
  "DRAFTKINGS": "draftkings",
  // "ELITESPORTSBOOK": "elitesportsbook",
  "ESPNBET": "espnbet",
  "ESPN": "espnbet",
  "EVERYGAME": "everygame",
  "FANATICS": "fanatics",
  "FANDUEL": "fanduel",
  // "FIREKEEPERS": "firekeepers",
  "FLIFF": "fliff",
  "FOURWINDS": "fourwinds",
  "FOXBET": "foxbet",
  // "FUBO": "fubo",
  // "GAMBETDC": "gambetdc",
  "GROSVENOR": "grosvenor",
  // "GOLDENNUGGET": "goldennugget",
  "GTBETS": "gtbets",
  // "GUNLAKE": "gunlake",
  "HARDROCKBET": "hardrockbet",
  // "HUDDLE": "huddle",
  // "INTERTOPS": "intertops",
  "LADBROKES": "ladbrokes",
  "LEOVEGAS": "leovegas",
  "LIVESCOREBET": "livescorebet",
  "LOWVIG": "lowvig",
  "LOWVIGAG": "lowvig",
  "MARATHONBET": "marathonbet",
  "MATCHBOOK": "matchbook",
  // "MAXIMBET": "maximbet",
  "MRGREEN": "mrgreen",
  "MYBOOKIEAG": "mybookie",
  "MYBOOKIE": "mybookie",
  "NEDS": "neds",
  "NORDICBET": "nordicbet",
  // "OFFICEFOOTBALLPOOL": "officefootballpool",
  "PADDYPOWER": "paddypower",
  "PINNACLE": "pinnacle",
  // "MAVERICKSPORTS": "mavericksports",
  "PLAYUP": "playup",
  "POINTSBET": "pointsbet",
  "PRIZEPICKS": "prizepicks",
  // "RUNYOURPOOL": "runyourpool",
  // "SBK": "sbk",
  "SI": "si",
  "SI_SPORTSBOOK": "si",
  "SPORTS_ILLUSTRATED": "si",
  "SKYBET": "skybet",
  "SPORTSBET": "sportsbet",
  "SPORTSBETTING_AG": "sportsbetting_ag",
  // "SPORTSINTERACTION": "sportsinteraction",
  // "SPLASHSPORTS": "splashsports",
  "SPORTTRADE": "sporttrade",
  // "SUGARHOUSE": "sugarhouse",
  "SUPERBOOK": "superbook",
  "SUPRABETS": "suprabets",
  "TAB": "tab",
  "TABTOUCH": "tabtouch",
  "TIPICO": "tipico",
  "TOPSPORT": "topsport",
  "UNDERDOG": "underdog",
  "UNIBET": "unibet",
  "UNKNOWN": "unknown",
  "VIRGINBET": "virginbet",
  "WILLIAMHILL": "williamhill",
  "WINDCREEK": "windcreek",
  "WYNNBET": "wynnbet",
  "BETFRED": "betfred",
  "BETSAFE": "betsafe",
  "BOOMBET": "boombet",
  "HOTSTREAK": "hotstreak",
  "NORTHSTARBETS": "northstarbets",
  "PARLAYPLAY": "parlayplay",
  "SLEEPER": "sleeper",
  "STAKE": "stake",
  "THESCOREBET": "thescorebet",
  // "TWINSPIRES": "twinspires",
  "BODOG": "bodog",
  "PRIME_SPORTS": "primesports",
  "PROPHET": "prophetexchange",
  // "YOUWAGER": "youwager"
};
exports.BOOKMAKER_IDS = BOOKMAKER_IDS;

const BOOKMAKER_CONFIG = {
  "1xbet": {
    bookmakerID: "1xbet",
    name: "1xBet",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  // "3et": {
  //   bookmakerID: "3et",
  //   name: "3et",
  //   primaryRegion: "all",
  //   type: "broker"
  // },
  "888sport": {
    bookmakerID: "888sport",
    name: "888 Sport",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  // "action247": {
  //   bookmakerID: "action247",
  //   name: "Action 247",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "ballybet": {
    bookmakerID: "ballybet",
    name: "Bally Bet",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "barstool": {
    bookmakerID: "barstool",
    name: "Barstool",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "bet365": {
    bookmakerID: "bet365",
    name: "Bet365",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "betamerica": {
  //   bookmakerID: "betamerica",
  //   name: "BetAmerica",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "betanysports": {
    bookmakerID: "betanysports",
    name: "BetAnySports",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "betclic": {
    bookmakerID: "betclic",
    name: "BetClic",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "betfairexchange": {
    bookmakerID: "betfairexchange",
    name: "Betfair Exchange",
    primaryRegion: "uk",
    type: "exchange",
  },
  "betfairsportsbook": {
    bookmakerID: "betfairsportsbook",
    name: "Betfair Sportsbook",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "betmgm": {
    bookmakerID: "betmgm",
    name: "BetMGM",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "betonline": {
    bookmakerID: "betonline",
    longName: "BetOnline AG",
    name: "BetOnline",
    primaryRegion: "all",
    type: "sportsbook",
  },
  "betparx": {
    bookmakerID: "betparx",
    name: "BetPARX",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "betrdfs": {
  //   bookmakerID: "betrdfs",
  //   name: "Betr DFS",
  //   primaryRegion: "us",
  //   type: "dfs",
  //   website: "betr.app",
  // },
  "betrivers": {
    bookmakerID: "betrivers",
    name: "BetRivers",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "betrsportsbook": {
    bookmakerID: "betrsportsbook",
    name: "Betr Sportsbook",
    primaryRegion: "au",
    type: "sportsbook",
    website: "betr.com.au",
  },
  "betsson": {
    bookmakerID: "betsson",
    name: "Betsson",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "betus": {
    bookmakerID: "betus",
    name: "BetUS",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "betvictor": {
    bookmakerID: "betvictor",
    name: "Bet Victor",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "betway": {
    bookmakerID: "betway",
    name: "Betway",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  // "betwildwood": {
  //   bookmakerID: "betwildwood",
  //   name: "BetWildwood",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "bluebet": {
    bookmakerID: "bluebet",
    name: "BlueBet",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "bookmakereu": {
    bookmakerID: "bookmakereu",
    name: "Bookmaker.eu",
    primaryRegion: "all",
    type: "sportsbook",
  },
  // "borgata": {
  //   bookmakerID: "borgata",
  //   name: "Borgata",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "bovada": {
    bookmakerID: "bovada",
    name: "Bovada",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "boylesports": {
    bookmakerID: "boylesports",
    name: "BoyleSports",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  // "bet_buckeye_sports": {
  //   bookmakerID: "bet_buckeye_sports",
  //   name: "Bet Buckeye Sports",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "caesars": {
    bookmakerID: "caesars",
    name: "Caesars",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "casumo": {
    bookmakerID: "casumo",
    name: "Casumo",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "circa": {
    bookmakerID: "circa",
    name: "Circa",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "coolbet": {
    bookmakerID: "coolbet",
    name: "Coolbet",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "coral": {
    bookmakerID: "coral",
    name: "Coral",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  // "5dimes": {
  //   bookmakerID: "5dimes",
  //   name: "5Dimes",
  //   primaryRegion: "all",
  //   type: "sportsbook",
  // },
  "draftkings": {
    bookmakerID: "draftkings",
    name: "Draft Kings",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "elitesportsbook": {
  //   bookmakerID: "elitesportsbook",
  //   name: "Elite Sportsbook",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "espnbet": {
    bookmakerID: "espnbet",
    name: "ESPN BET",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "everygame": {
    bookmakerID: "everygame",
    name: "Everygame",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "fanatics": {
    bookmakerID: "fanatics",
    name: "Fanatics",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "fanduel": {
    bookmakerID: "fanduel",
    name: "FanDuel",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "firekeepers": {
  //   bookmakerID: "firekeepers",
  //   name: "FireKeepers",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "fliff": {
    bookmakerID: "fliff",
    name: "Fliff",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "foxbet": {
    bookmakerID: "foxbet",
    name: "FOX Bet",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "fourwinds": {
    bookmakerID: "fourwinds",
    name: "FourWinds",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "fubo": {
  //   bookmakerID: "fubo",
  //   name: "Fubo",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  // "gambetdc": {
  //   bookmakerID: "gambetdc",
  //   name: "Gambet DC",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  // "goldennugget": {
  //   bookmakerID: "goldennugget",
  //   name: "Golden Nugget",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "grosvenor": {
    bookmakerID: "grosvenor",
    name: "Grosvenor",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "gtbets": {
    bookmakerID: "gtbets",
    name: "GTbets",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  // "gunlake": {
  //   bookmakerID: "gunlake",
  //   name: "Gun Lake",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "hardrockbet": {
    bookmakerID: "hardrockbet",
    name: "Hard Rock Bet",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "huddle": {
  //   bookmakerID: "huddle",
  //   name: "Huddle",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  // "intertops": {
  //   bookmakerID: "intertops",
  //   name: "Intertops",
  //   primaryRegion: "de",
  //   type: "sportsbook",
  // },
  "ladbrokes": {
    bookmakerID: "ladbrokes",
    name: "Ladbrokes",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "leovegas": {
    bookmakerID: "leovegas",
    name: "LeoVegas",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "livescorebet": {
    bookmakerID: "livescorebet",
    name: "LiveScore Bet",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "lowvig": {
    bookmakerID: "lowvig",
    name: "LowVig",
    primaryRegion: "us",
    type: "sportsbook",
    website: "lowvig.ag",
  },
  "marathonbet": {
    bookmakerID: "marathonbet",
    name: "Marathon Bet",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "matchbook": {
    bookmakerID: "matchbook",
    name: "Matchbook",
    primaryRegion: "eu",
    type: "exchange",
  },
  // "maximbet": {
  //   bookmakerID: "maximbet",
  //   name: "Maxim Bet",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "mrgreen": {
    bookmakerID: "mrgreen",
    name: "Mr Green",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "mybookie": {
    bookmakerID: "mybookie",
    name: "MyBookie",
    primaryRegion: "all",
    type: "sportsbook",
  },
  "neds": {
    bookmakerID: "neds",
    name: "Neds",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "nordicbet": {
    bookmakerID: "nordicbet",
    name: "NordicBet",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  // "officefootballpool": {
  //   bookmakerID: "officefootballpool",
  //   name: "Office Football Pool",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "paddypower": {
    bookmakerID: "paddypower",
    name: "Paddy Power",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "pinnacle": {
    bookmakerID: "pinnacle",
    name: "Pinnacle",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  // "mavericksports": {
  //   bookmakerID: "mavericksports",
  //   name: "Maverick Sports",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "playup": {
    bookmakerID: "playup",
    name: "PlayUp",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "pointsbet": {
    bookmakerID: "pointsbet",
    name: "PointsBet",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "prizepicks": {
    bookmakerID: "prizepicks",
    name: "PrizePicks",
    primaryRegion: "us",
    type: "dfs",
  },
  "prophetexchange": {
    bookmakerID: "prophetexchange",
    name: "Prophet Exchange",
    primaryRegion: "us",
    type: "sportsbook",
  },
  // "youwager": {
  //   bookmakerID: "youwager",
  //   name: "YouWager",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  // "runyourpool": {
  //   bookmakerID: "runyourpool",
  //   name: "RunYourPool",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  // "sbk": {
  //   bookmakerID: "sbk",
  //   name: "SBK",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "si": {
    bookmakerID: "si",
    longName: "Sports Illustrated Sportsbook",
    name: "SI Sportsbook",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "skybet": {
    bookmakerID: "skybet",
    name: "Sky Bet",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "sportsbet": {
    bookmakerID: "sportsbet",
    name: "SportsBet",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "sportsbetting_ag": {
    bookmakerID: "sportsbetting_ag",
    name: "SportsBetting.ag",
    primaryRegion: "ag",
    type: "sportsbook",
  },
  // "sportsinteraction": {
  //   bookmakerID: "sportsinteraction",
  //   name: "Sports Interaction",
  //   primaryRegion: "ca",
  //   type: "sportsbook",
  // },
  // "splashsports": {
  //   bookmakerID: "splashsports",
  //   name: "Splash Sports",
  //   primaryRegion: "us",
  //   type: "dfs",
  // },
  "sporttrade": {
    bookmakerID: "sporttrade",
    name: "Sporttrade",
    primaryRegion: "us",
    type: "exchange",
  },
  // "sugarhouse": {
  //   bookmakerID: "sugarhouse",
  //   name: "Sugarhouse",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "superbook": {
    bookmakerID: "superbook",
    name: "Superbook",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "suprabets": {
    bookmakerID: "suprabets",
    name: "Suprabets",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "tab": {
    bookmakerID: "tab",
    name: "TAB",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "tabtouch": {
    bookmakerID: "tabtouch",
    name: "TABtouch",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "tipico": {
    bookmakerID: "tipico",
    name: "Tipico",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "topsport": {
    bookmakerID: "topsport",
    name: "TopSport",
    primaryRegion: "au",
    type: "sportsbook",
  },
  // "twinspires": {
  //   bookmakerID: "twinspires",
  //   name: "TwinSpires",
  //   primaryRegion: "us",
  //   type: "sportsbook",
  // },
  "underdog": {
    bookmakerID: "underdog",
    name: "Underdog",
    primaryRegion: "us",
    type: "dfs",
  },
  "unibet": {
    bookmakerID: "unibet",
    name: "Unibet",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "unknown": {
    bookmakerID: "unknown",
    name: "Unknown",
    primaryRegion: "all",
    type: "",
  },
  "virginbet": {
    bookmakerID: "virginbet",
    name: "Virgin Bet",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "williamhill": {
    bookmakerID: "williamhill",
    name: "William Hill",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "windcreek": {
    bookmakerID: "windcreek",
    name: "Wind Creek (Betfred PA)",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "wynnbet": {
    bookmakerID: "wynnbet",
    name: "WynnBet",
    primaryRegion: "us",
    type: "sportsbook",
  },
  "betfred": {
    bookmakerID: "betfred",
    name: "Betfred",
    primaryRegion: "uk",
    type: "sportsbook",
  },
  "betsafe": {
    bookmakerID: "betsafe",
    name: "Betsafe",
    primaryRegion: "eu",
    type: "sportsbook",
  },
  "boombet": {
    bookmakerID: "boombet",
    name: "BoomBet",
    primaryRegion: "au",
    type: "sportsbook",
  },
  "hotstreak": {
    bookmakerID: "hotstreak",
    name: "HotStreak",
    primaryRegion: "us",
    type: "dfs",
  },
  "northstarbets": {
    bookmakerID: "northstarbets",
    name: "NorthStar Bets",
    primaryRegion: "ca",
    type: "sportsbook",
  },
  "parlayplay": {
    bookmakerID: "parlayplay",
    name: "ParlayPlay",
    primaryRegion: "us",
    type: "dfs",
  },
  "sleeper": {
    bookmakerID: "sleeper",
    name: "Sleeper",
    primaryRegion: "us",
    type: "dfs",
  },
  "stake": {
    bookmakerID: "stake",
    name: "Stake",
    primaryRegion: "all",
    type: "sportsbook",
  },
  "thescorebet": {
    bookmakerID: "thescorebet",
    name: "theScore Bet",
    primaryRegion: "ca",
    type: "sportsbook",
  },
  "bodog": {
    bookmakerID: "bodog",
    name: "Bodog",
    primaryRegion: "ca",
    type: "sportsbook",
  },
  "primesports": {
    bookmakerID: "primesports",
    name: "Prime Sports",
    primaryRegion: "us",
    type: "sportsbook",
  },
};
exports.BOOKMAKER_CONFIG = BOOKMAKER_CONFIG;

const DEFAULT_BOOKMAKER_ID = BOOKMAKER_IDS.UNKNOWN;
exports.DEFAULT_BOOKMAKER_ID = DEFAULT_BOOKMAKER_ID;
