import React from "react";
import MaterialIconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

// Icon should be a component imported from @mui/icons-material
export const IconButton = ({ Icon, loading = false, onClick, color = "primary", style = {}, disabled = false, ...props }) => {
  if (!Icon || !onClick) {
    console.error("IconButton requires an Icon and onClick prop");
    return null;
  }

  return (
    <MaterialIconButton
      variant="contained"
      onClick={onClick}
      disabled={disabled || loading}
      sx={{
        "backgroundColor": `${color}.main`,
        "color": "white",
        "padding": "6px",
        "boxShadow": 1,
        "transition": "all 0.2s",
        "&:hover": {
          backgroundColor: `${color}.dark`,
          boxShadow: 2,
        },
        "&.Mui-disabled": {
          backgroundColor: "grey.400",
          color: "grey.100",
        },
        ...style,
      }}
      {...props}
    >
      {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : <Icon />}
    </MaterialIconButton>
  );
};

export const TextButton = ({ Icon = null, size = 14, fullWidth = false, centered = false, text, loading = false, onClick, color = "primary", style = {}, disabled = false, ...props }) => {
  if (!text || !onClick) {
    console.error("TextButton requires text and onClick props");
    return null;
  }

  const paddingVertical = Math.round(size * 0.5);
  const paddingHorizontal = Math.round(size * 1.5);
  const iconSize = Math.round(size * 1.25);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      startIcon={!Icon ? null : loading ? <CircularProgress size={iconSize} sx={{ color: "white" }} /> : Icon}
      sx={{
        "backgroundColor": `${color}.main`,
        "color": "white",
        "padding": `${paddingVertical}px ${paddingHorizontal}px`,
        "boxShadow": 1,
        "transition": "all 0.2s",
        "fontSize": `${size}px`,
        "margin": centered && !fullWidth ? "0 auto" : undefined,
        "display": centered && !fullWidth ? "flex" : undefined,
        "&:hover": {
          backgroundColor: `${color}.dark`,
          boxShadow: 2,
        },
        "&.Mui-disabled": {
          backgroundColor: "grey.400",
          color: "grey.100",
        },
        ...style,
      }}
      {...props}
    >
      {text}
    </Button>
  );
};
