const { EventsCollection, EVENTS_CONFIG } = require("./collections/Events");
const { PlayersCollection, PLAYERS_CONFIG } = require("./collections/Players");
const { TeamsCollection, TEAMS_CONFIG } = require("./collections/Teams");
const { LeaguesCollection, LEAGUES_CONFIG } = require("./collections/Leagues");
const { ApiKeysCollection, API_KEYS_CONFIG } = require("./collections/ApiKeys");
const { ConfigCollection, CONFIG_CONFIG } = require("./collections/Config");
const { UnknownPlayersCollection, UNKNOWN_PLAYERS_CONFIG } = require("./collections/UnknownPlayers");
const { UnknownTeamsCollection, UNKNOWN_TEAMS_CONFIG } = require("./collections/UnknownTeams");
const { MongoDatabase, MONGO_CONFIG } = require("./adapters/mongo/MongoDatabase");
const { MongoAdapter } = require("./adapters/mongo/MongoAdapter");
const { FirestoreDatabase, FIRESTORE_CONFIG } = require("./adapters/firestore/FirestoreDatabase");
const { FirestoreAdapter } = require("./adapters/firestore/FirestoreAdapter");
const { EventShortsCollection, EVENT_SHORTS_CONFIG } = require("@wagerlab/utils/database/collections/EventShorts");
const { logError } = require("@wagerlab/utils/logging");

// TODO - WWW - How should I log errors in these files?

const ADAPTERS = {
  [MONGO_CONFIG.adapterType]: {
    DatabaseClass: MongoDatabase,
    AdapterClass: MongoAdapter,
    config: MONGO_CONFIG,
  },
  [FIRESTORE_CONFIG.adapterType]: {
    DatabaseClass: FirestoreDatabase,
    AdapterClass: FirestoreAdapter,
    config: FIRESTORE_CONFIG,
  },
};

const COLLECTIONS = {
  [EVENTS_CONFIG.collectionType]: {
    CollectionClass: EventsCollection,
    config: EVENTS_CONFIG,
  },
  [PLAYERS_CONFIG.collectionType]: {
    CollectionClass: PlayersCollection,
    config: PLAYERS_CONFIG,
  },
  [TEAMS_CONFIG.collectionType]: {
    CollectionClass: TeamsCollection,
    config: TEAMS_CONFIG,
  },
  [LEAGUES_CONFIG.collectionType]: {
    CollectionClass: LeaguesCollection,
    config: LEAGUES_CONFIG,
  },
  [API_KEYS_CONFIG.collectionType]: {
    CollectionClass: ApiKeysCollection,
    config: API_KEYS_CONFIG,
  },
  [CONFIG_CONFIG.collectionType]: {
    CollectionClass: ConfigCollection,
    config: CONFIG_CONFIG,
  },
  [UNKNOWN_PLAYERS_CONFIG.collectionType]: {
    CollectionClass: UnknownPlayersCollection,
    config: UNKNOWN_PLAYERS_CONFIG,
  },
  [UNKNOWN_TEAMS_CONFIG.collectionType]: {
    CollectionClass: UnknownTeamsCollection,
    config: UNKNOWN_TEAMS_CONFIG,
  },
  [EVENT_SHORTS_CONFIG.collectionType]: {
    CollectionClass: EventShortsCollection,
    config: EVENT_SHORTS_CONFIG,
  },
};

const ACTIVE_ADAPTERS = Object.values(COLLECTIONS).reduce((activeAdapters, collectionInfo) => {
  const { CollectionClass, config: collectionConfig } = collectionInfo || {};
  const { collectionType, collectionName, adapterType } = collectionConfig || {};
  const adapterInfo = ADAPTERS[adapterType];
  const { DatabaseClass, AdapterClass, config: adapterConfig } = adapterInfo || {};
  if (!collectionType || !DatabaseClass || !AdapterClass || !CollectionClass || !collectionName) {
    logError("Invalid collection or adapter info", { collectionConfig, adapterConfig });
    return activeAdapters;
  }
  if (!activeAdapters[adapterType]) {
    activeAdapters[adapterType] = {
      ...adapterInfo,
      collections: {},
    };
  }
  activeAdapters[adapterType].collections[collectionType] = { ...collectionInfo };
  return activeAdapters;
}, {});

const ACTIVE_COLLECTION_INSTANCES = {};

const ADAPTER_CONNECTION_PROMISES = {};

let DATABASE_CONNECT_OPTIONS = {};
const setDatabaseConnectOptions = (connectOptions) => {
  DATABASE_CONNECT_OPTIONS = connectOptions || {};
};
exports.setDatabaseConnectOptions = setDatabaseConnectOptions;

const connectToDatabase = async () => {
  const allAdapterConnectionPromises = [];
  Object.entries(ACTIVE_ADAPTERS).forEach(([adapterType, activeAdapterInfo]) => {
    ADAPTER_CONNECTION_PROMISES[adapterType] = ADAPTER_CONNECTION_PROMISES[adapterType] || connectToSingleDatabase(activeAdapterInfo);
    allAdapterConnectionPromises.push(ADAPTER_CONNECTION_PROMISES[adapterType]);
  });
  const connectionResults = await Promise.all(allAdapterConnectionPromises);
  return connectionResults.every((result) => result);
};
exports.connectToDatabase = connectToDatabase;

const disconnectFromDatabase = async () => {
  const databaseDisconnectPromises = Object.values(ACTIVE_ADAPTERS).map((activeAdapterInfo) => disconnectFromSingleDatabase(activeAdapterInfo));
  const disconnectionResults = await Promise.all(databaseDisconnectPromises);
  return disconnectionResults.every((result) => result);
};
exports.disconnectFromDatabase = disconnectFromDatabase;

const db = (collectionType) => ACTIVE_COLLECTION_INSTANCES[collectionType?.toLowerCase?.() || ""] || null;
exports.db = db;

const connectToDB = async (collectionType) => {
  try {
    const collectionTypeLower = collectionType?.toLowerCase?.() || "";
    if (!collectionTypeLower) return null;
    if (ACTIVE_COLLECTION_INSTANCES[collectionTypeLower]) return ACTIVE_COLLECTION_INSTANCES[collectionTypeLower];
    const adapterType = COLLECTIONS[collectionTypeLower]?.config?.adapterType;
    if (!adapterType) return null;
    ADAPTER_CONNECTION_PROMISES[adapterType] = ADAPTER_CONNECTION_PROMISES[adapterType] || connectToSingleDatabase(ACTIVE_ADAPTERS[adapterType]);
    await ADAPTER_CONNECTION_PROMISES[adapterType];
    return ACTIVE_COLLECTION_INSTANCES[collectionTypeLower] || null;
  } catch (error) {
    logError(`Error connecting to database: ${collectionType}`, { error });
    return null;
  }
};
exports.connectToDB = connectToDB;

const connectToSingleDatabase = async (activeAdapterInfo) => {
  const { DatabaseClass, AdapterClass, collections, config: adapterConfig } = activeAdapterInfo || {};
  const { firebase, dbTargetTestCollections } = DATABASE_CONNECT_OPTIONS || {};
  const databaseInstance = DatabaseClass.getInstance();

  const collectionNamePrefix = dbTargetTestCollections ? "Test" : "";

  if (!databaseInstance.connected) await databaseInstance.connect(DATABASE_CONNECT_OPTIONS);
  if (!databaseInstance.connected) return false;

  Object.values(collections).forEach((collectionInfo) => {
    const { CollectionClass, config: collectionConfig } = collectionInfo || {};
    const { collectionType, collectionName, adapterType, primaryKey } = collectionConfig || {};
    if (!collectionType || ACTIVE_COLLECTION_INSTANCES[collectionType] || !primaryKey) return;

    const fullCollectionName = `${collectionNamePrefix}${collectionName}`;

    const adapterInstance = new AdapterClass(databaseInstance, fullCollectionName, primaryKey);
    const collectionInstance = new CollectionClass(adapterInstance);

    ACTIVE_COLLECTION_INSTANCES[collectionType] = collectionInstance;
  });
  return true;
};

const disconnectFromSingleDatabase = async (activeAdapterInfo) => {
  const { DatabaseClass, AdapterClass, collections, config: adapterConfig } = activeAdapterInfo || {};
  const adapterType = adapterConfig?.adapterType;
  const databaseInstance = DatabaseClass.getInstance();
  const disconnected = await databaseInstance.disconnect();
  if (!disconnected) return false;
  if (adapterType) delete ADAPTER_CONNECTION_PROMISES[adapterType];
  Object.values(collections).forEach((collectionInfo) => {
    const { CollectionClass, config: collectionConfig } = collectionInfo || {};
    const { collectionType, collectionName, adapterType } = collectionConfig || {};
    if (!collectionType || !ACTIVE_COLLECTION_INSTANCES[collectionType]) return;
    delete ACTIVE_COLLECTION_INSTANCES[collectionType];
  });
  return true;
};
