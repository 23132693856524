const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isNumber } = require("@wagerlab/utils/data/types");
const { getEventDisplay } = require("@wagerlab/utils/displays");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");
const { asSpreadFloat, asMoneylineInt, getInverseOdds } = require("@wagerlab/utils/odds/converters");
const { getOddID } = require("@wagerlab/utils/odds/identifiers");
const { getStatConfigFromEventOdds } = require("@wagerlab/utils/sports/stats");
const _ = require("lodash");

exports.refreshOddsOverview = (event) => {
  const oddsOverview = getOddsOverview(event);
  if (oddsOverview) setObjVal(event, "status.oddsOverview", oddsOverview);
  else _.unset(event, "status.oddsOverview");

  return event;
};

const getOddsOverview = (eventData) => {
  if (eventData?.status?.type !== EVENT_TYPES.MATCH) return "";

  const spreadOverview = getOverviewFromSpread(eventData);
  if (!spreadOverview || spreadOverview === EVEN_ODDS_OUTPUT) return getOverviewFromMoneyline(eventData);
  return spreadOverview || "";
};

const getOverviewFromSpread = (eventData) => {
  const preferCloseSpread = getShouldPreferClosingValues(eventData);
  const sportID = eventData?.sportID;

  const homeSpreadOddID = getOddID({ periodID: "game", statID: "points", sideID: "home", betTypeID: "sp", statEntityID: "home" });
  const awaySpreadOddID = getOddID({ periodID: "game", statID: "points", sideID: "away", betTypeID: "sp", statEntityID: "away" });
  const homeSpreadOddData = eventData?.odds?.[homeSpreadOddID];
  const awaySpreadOddData = eventData?.odds?.[awaySpreadOddID];

  if (homeSpreadOddData?.isFallbackOdds || awaySpreadOddData?.isFallbackOdds) return "";

  let homeSpreadStr = "";
  let awaySpreadStr = "";
  let homeOddsStr = "";
  let awayOddsStr = "";
  if (preferCloseSpread && homeSpreadOddData?.closeSpread && awaySpreadOddData?.closeSpread) {
    homeSpreadStr = homeSpreadOddData?.closeSpread;
    awaySpreadStr = awaySpreadOddData?.closeSpread;
    homeOddsStr = homeSpreadOddData?.closeOdds;
    awayOddsStr = awaySpreadOddData?.closeOdds;
  } else if (homeSpreadOddData?.spread && awaySpreadOddData?.spread) {
    homeSpreadStr = homeSpreadOddData?.spread;
    awaySpreadStr = awaySpreadOddData?.spread;
    homeOddsStr = homeSpreadOddData?.odds;
    awayOddsStr = awaySpreadOddData?.odds;
  } else if (homeSpreadOddData?.closeSpread && awaySpreadOddData?.closeSpread) {
    homeSpreadStr = homeSpreadOddData?.closeSpread;
    awaySpreadStr = awaySpreadOddData?.closeSpread;
    homeOddsStr = homeSpreadOddData?.closeOdds;
    awayOddsStr = awaySpreadOddData?.closeOdds;
  }
  homeOddsStr = homeOddsStr || "+100";
  awayOddsStr = awayOddsStr || "+100";

  if (!homeSpreadStr && !awaySpreadStr) return "";

  const homeTeamName = getEventDisplay("homeTeam_medium", eventData);
  const awayTeamName = getEventDisplay("awayTeam_medium", eventData);

  const homeSpreadNum = asSpreadFloat(homeSpreadStr);
  if (isNumber(homeSpreadNum) && homeOddsStr === "+100") {
    if (homeSpreadNum === 0) return EVEN_ODDS_OUTPUT;

    const favoriteTeam = homeSpreadNum < 0 ? homeTeamName : awayTeamName;
    const favoriteBy = homeSpreadNum < 0 ? homeSpreadNum * -1 : homeSpreadNum;

    const statConfig = getStatConfigFromEventOdds(eventData, homeSpreadOddData);
    const statUnitWord = (favoriteBy === 1 && statConfig?.units?.long?.singular) || statConfig?.units?.long?.plural || "";

    if (favoriteTeam && favoriteBy) return `${favoriteTeam} favored by ${favoriteBy} ${statUnitWord || ""}`.trim();
  }
  const awaySpreadNum = asSpreadFloat(awaySpreadStr);
  if (isNumber(awaySpreadNum) && awayOddsStr === "+100") {
    if (awaySpreadNum === 0) return EVEN_ODDS_OUTPUT;

    const favoriteTeam = awaySpreadNum < 0 ? awayTeamName : homeTeamName;
    const favoriteBy = awaySpreadNum < 0 ? awaySpreadNum * -1 : awaySpreadNum;

    const statConfig = getStatConfigFromEventOdds(eventData, awaySpreadOddData);
    const statUnitWord = (favoriteBy === 1 && statConfig?.units?.long?.singular) || statConfig?.units?.long?.plural || "";

    if (favoriteTeam && favoriteBy) return `${favoriteTeam} favored by ${favoriteBy} ${statUnitWord || ""}`.trim();
  }
  return "";
};

const getOverviewFromMoneyline = (eventData) => {
  const preferCloseMoneyline = getShouldPreferClosingValues(eventData);

  const homeMoneylineOddID = getOddID({ periodID: "game", statID: "points", sideID: "home", betTypeID: "ml", statEntityID: "home" });
  const awayMoneylineOddID = getOddID({ periodID: "game", statID: "points", sideID: "away", betTypeID: "ml", statEntityID: "away" });
  const homeMoneylineOddData = eventData?.odds?.[homeMoneylineOddID];
  const awayMoneylineOddData = eventData?.odds?.[awayMoneylineOddID];

  if (homeMoneylineOddData?.isFallbackOdds || awayMoneylineOddData?.isFallbackOdds) return "";

  let homeMoneylineStr = "";
  let awayMoneylineStr = "";
  if (preferCloseMoneyline && homeMoneylineOddData?.closeOdds && awayMoneylineOddData?.closeOdds) {
    homeMoneylineStr = homeMoneylineOddData?.closeOdds;
    awayMoneylineStr = awayMoneylineOddData?.closeOdds;
  } else if (homeMoneylineOddData?.odds && awayMoneylineOddData?.odds) {
    homeMoneylineStr = homeMoneylineOddData?.odds;
    awayMoneylineStr = awayMoneylineOddData?.odds;
  } else if (homeMoneylineOddData?.closeOdds && awayMoneylineOddData?.closeOdds) {
    homeMoneylineStr = homeMoneylineOddData?.closeOdds;
    awayMoneylineStr = awayMoneylineOddData?.closeOdds;
  }

  if (!homeMoneylineStr && !awayMoneylineStr) return "";

  const homeTeamName = getEventDisplay("homeTeam_medium", eventData);
  const awayTeamName = getEventDisplay("awayTeam_medium", eventData);

  let homeMoneylineNum = asMoneylineInt(homeMoneylineStr);
  let awayMoneylineNum = asMoneylineInt(awayMoneylineStr);
  if (isNumber(homeMoneylineNum) && !isNumber(awayMoneylineNum)) awayMoneylineNum = getInverseOdds(homeMoneylineNum);
  if (!isNumber(homeMoneylineNum) && isNumber(awayMoneylineNum)) homeMoneylineNum = getInverseOdds(awayMoneylineNum);

  if (!isNumber(homeMoneylineNum) || !isNumber(awayMoneylineNum)) return "";

  const homeMoneylineAbs = Math.abs(homeMoneylineNum);
  const awayMoneylineAbs = Math.abs(awayMoneylineNum);

  const isEvenMoneyLine = (homeMoneylineAbs === 100 && awayMoneylineAbs === 100) || homeMoneylineNum === awayMoneylineNum;
  if (isEvenMoneyLine) return EVEN_ODDS_OUTPUT;

  const favoriteTeam = homeMoneylineNum < awayMoneylineNum ? homeTeamName : awayTeamName;
  const favoriteMoneylineNum = homeMoneylineNum < awayMoneylineNum ? homeMoneylineNum : awayMoneylineNum;
  if (favoriteMoneylineNum > 0) return "";
  const favoriteMoneylineAbs = Math.abs(favoriteMoneylineNum);
  const favoredToOneNum = favoriteMoneylineAbs / 100;
  const favoredToOneStr = favoredToOneNum?.toFixed?.(2);
  if (favoriteTeam && favoredToOneStr) return `${favoriteTeam} favored ${favoredToOneStr}-to-1`;
  return "";
};

const EVEN_ODDS_OUTPUT = "Neither side is favored";

const getShouldPreferClosingValues = (eventData) => !!eventData?.status?.ended || !!eventData?.status?.finalized;
