import React, { useMemo, useState } from "react";
import { Alert, Box, Container, Snackbar, Typography, Tabs, Tab } from "@mui/material";
import { copyData, dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { initializeFormData, buildConfigFromFormData, formFieldValidation, validateFormData } from "@wagerlab/admin/src/config/formHelpers";
import { getMainConfig, updateMainConfig } from "@wagerlab/admin/src/config/database";
import { useParams } from "react-router-dom";
import { deleteValue } from "@wagerlab/utils/database/legacyDatabaseUtils/firestore";
import { Formik } from "formik";
import { ConfigAffiliatesList } from "@wagerlab/admin/src/config/ConfigAffiliatesList";
import { LoadingSpinner } from "@wagerlab/admin/src/shared/Loading";
import { useQuery } from "@tanstack/react-query";
import { SubmitButton } from "@wagerlab/admin/src/shared/inputs/SubmitButton";
import { ConfigAffiliateLocales } from "@wagerlab/admin/src/config/ConfigAffiliateLocales";
import { ConfigAffiliateGeneral } from "@wagerlab/admin/src/config/ConfigAffiliateGeneral";

const EDIT_CONFIG_TABS = {
  affiliatesList: { label: "Affiliate Info", key: "affiliatesList" },
  affiliateLocales: { label: "Affiliate Countries/States", key: "affiliateLocales" },
  affiliateGeneral: { label: "Affiliate General Config", key: "affiliateGeneral" },
  all: { label: "All", key: "all" },
};
const EDIT_CONFIG_TABS_ORDER = ["all", "affiliatesList", "affiliateLocales", "affiliateGeneral"];

export const EditConfigPage = () => {
  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const {
    data: fetchedConfig,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["config-main"],
    queryFn: () => getMainConfig(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    refetchIntervalInBackground: false,
  });

  const screenLoading = isLoading || isFetching;

  const { initialConfigData, initialConfigForm } = useMemo(() => {
    const initialData = screenLoading ? null : copyData(fetchedConfig);
    const initialForm = initializeFormData(initialData);
    return { initialConfigData: initialData, initialConfigForm: initialForm };
  }, [screenLoading, fetchedConfig]);

  const handleFormSubmit = async (formData, { resetForm }) => {
    const validationError = validateFormData(formData, initialConfigData);
    if (validationError) return setMessage(validationError);
    const newConfig = buildConfigFromFormData(formData, initialConfigData);

    if (!newConfig) setMessage("Config data has vanished");

    const initialConfigUpdates = dataUpdates(initialConfigData, newConfig, deleteValue());
    if (!initialConfigUpdates) return setMessage("No changes to save");

    const latestConfigData = await getMainConfig();
    if (!latestConfigData) return setMessage("Couldn't verify remote Config data");

    const configUpdates = dataUpdates(latestConfigData, newConfig, deleteValue());
    if (dataChanged(initialConfigUpdates, configUpdates)) {
      console.log(dataUpdates(initialConfigUpdates, configUpdates, null));
      return setMessage("Config data has changed or is malformed. Please refresh and try again. If the issue persists, that means the data needs to be fixed in the database manually.");
    }

    const updated = await updateMainConfig(configUpdates);
    if (!updated) return setMessage(`Failed to update Config in database`);

    return setMessage("Success!");
  };

  return (
    <>
      {initialConfigData ? (
        <Container maxWidth="xl" sx={{ marginTop: 10, marginBottom: 8 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 4 }}>
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
              {EDIT_CONFIG_TABS_ORDER.map((tabKey) => {
                const tabConfig = EDIT_CONFIG_TABS?.[tabKey];
                if (!tabKey || tabConfig?.key !== tabKey) return null;
                return <Tab key={tabKey} label={tabConfig?.label || "???"} value={tabKey} />;
              })}
            </Tabs>
          </Box>

          <Formik initialValues={initialConfigForm} onSubmit={handleFormSubmit} validationSchema={formFieldValidation}>
            <>
              {activeTab === "affiliatesList" || activeTab === "all" ? <ConfigAffiliatesList prevConfig={initialConfigData} /> : null}
              {activeTab === "affiliateLocales" || activeTab === "all" ? <ConfigAffiliateLocales prevConfig={initialConfigData} /> : null}
              {activeTab === "affiliateGeneral" || activeTab === "all" ? <ConfigAffiliateGeneral prevConfig={initialConfigData} /> : null}
              <Box
                sx={{
                  marginTop: 8,
                  marginBottom: 16,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={"Save"} />
              </Box>
            </>
          </Formik>
        </Container>
      ) : (
        <LoadingSpinner />
      )}

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!message} onClose={() => setMessage("")} autoHideDuration={10000}>
        <Alert onClose={() => setMessage("")} severity={"info"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
