import { copyData } from "@wagerlab/utils/data/mutations";
import * as Yup from "yup";
import {
  initializeFormData as initializeFormData_affiliatesList,
  validateFormData as validateFormData_affiliatesList,
  buildConfigFromFormData as buildConfigFromFormData_affiliatesList,
  formFieldValidation as formFieldValidation_affiliatesList,
} from "@wagerlab/admin/src/config/formHelpers/affiliatesList";
import {
  initializeFormData as initializeFormData_affiliateLocales,
  validateFormData as validateFormData_affiliateLocales,
  buildConfigFromFormData as buildConfigFromFormData_affiliateLocales,
  formFieldValidation as formFieldValidation_affiliateLocales,
} from "@wagerlab/admin/src/config/formHelpers/affiliateLocales";
import {
  initializeFormData as initializeFormData_affiliateGeneral,
  validateFormData as validateFormData_affiliateGeneral,
  buildConfigFromFormData as buildConfigFromFormData_affiliateGeneral,
  formFieldValidation as formFieldValidation_affiliateGeneral,
} from "@wagerlab/admin/src/config/formHelpers/affiliateGeneral";

export const initializeFormData = (configData) => {
  if (!configData) return null;

  return {
    configID: "main",
    affiliatesList: initializeFormData_affiliatesList(configData),
    affiliateLocales: initializeFormData_affiliateLocales(configData),
    affiliateGeneral: initializeFormData_affiliateGeneral(configData),
  };
};

export const validateFormData = (eventForm, prevConfigData) => {
  if (eventForm?.configID !== "main") return "Config form data is corrupted";
  if (!prevConfigData) return "Remote config data is missing";
  const affiliatesListErrors = validateFormData_affiliatesList(eventForm.affiliatesList, prevConfigData);
  if (affiliatesListErrors) return affiliatesListErrors;
  const affiliateLocalesErrors = validateFormData_affiliateLocales(eventForm.affiliateLocales, prevConfigData);
  if (affiliateLocalesErrors) return affiliateLocalesErrors;
  const affiliateGeneralErrors = validateFormData_affiliateGeneral(eventForm.affiliateGeneral, prevConfigData);
  if (affiliateGeneralErrors) return affiliateGeneralErrors;

  return "";
};

export const buildConfigFromFormData = (configForm, prevConfigData) => {
  if (!prevConfigData) return null;

  let newConfigData = copyData(prevConfigData);

  newConfigData = buildConfigFromFormData_affiliatesList(configForm.affiliatesList, prevConfigData, newConfigData);
  newConfigData = buildConfigFromFormData_affiliateLocales(configForm.affiliateLocales, prevConfigData, newConfigData);
  newConfigData = buildConfigFromFormData_affiliateGeneral(configForm.affiliateGeneral, prevConfigData, newConfigData);

  // Any final formatting?
  // const formattedEvent = stripEmptyValues(refreshedEvent);

  return newConfigData;
};

export const formFieldValidation = Yup.object().shape({
  configID: Yup.string().required("Config ID is missing"),
  affiliatesList: formFieldValidation_affiliatesList,
  affiliateLocales: formFieldValidation_affiliateLocales,
  affiliateGeneral: formFieldValidation_affiliateGeneral,
});
