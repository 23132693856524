import React, { useState } from "react";
import { Button, Snackbar, TableCell, TableRow, Box } from "@mui/material";
import { getEventDisplay } from "@wagerlab/utils/displays";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { ObjectDataDialog } from "@wagerlab/admin/src/shared/ObjectDataDialog";
import { getEvent } from "@wagerlab/admin/src/events/database";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";

export const EventTableRow = ({ event }) => {
  const eventTitle = getEventDisplay("title_long", event);
  const timezoneFormattedDate = moment(event.status?.startsAt).tz(moment.tz.guess()).format("dddd MMMM D, YYYY [@] h:mma zz");

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" align="left">
          {event?.eventID || "???"}
        </TableCell>
        <TableCell align="left">{eventTitle}</TableCell>
        <TableCell align="left">{event.sportID}</TableCell>
        <TableCell align="left">{event.leagueID}</TableCell>
        <TableCell align="left">{timezoneFormattedDate}</TableCell>
        <TableCell align="right">
          <EventTableRowActions eventID={event?.eventID} />
        </TableCell>
      </TableRow>
    </>
  );
};

const EventTableRowActions = ({ eventID }) => {
  const navigate = useNavigate();
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  if (!eventID) return null;

  const loadEventData = async () => {
    if (loadingEvent) return false;
    setLoadingEvent(true);
    const event = await getEvent(eventID);
    const foundEvent = !!event?.eventID;
    if (!foundEvent) setErrorMessage("Couldn't find/load event data");
    setEventData(foundEvent ? event : null);
    setLoadingEvent(false);
    return foundEvent;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
      <IconButton Icon={EditIcon} onClick={() => navigate(`/events/edit/${eventID}`)} />

      <ObjectDataDialog
        data={eventData}
        enableEdits={false}
        renderButton={(openDialog) => (
          <IconButton
            Icon={VisibilityIcon}
            onClick={async () => {
              const foundEvent = await loadEventData();
              if (foundEvent) openDialog();
            }}
            loading={loadingEvent}
            style={{}}
            color="secondary"
          />
        )}
      />

      <Snackbar open={!!errorMessage} autoHideDuration={10000} onClose={() => setErrorMessage("")} message={errorMessage} />
    </Box>
  );
};
