import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { isEmptyObject, isObject } from "@wagerlab/utils/data/types";
import { getAllTeamNames, getNewTeamID, getTeamName } from "@wagerlab/utils/teams";

export const type = "unknownteams";
export const typeDisplay = "Teams";

export const getUnknownEntityID = (unknownEntity) => unknownEntity?.unknownTeamID;
export const getEntityID = (existingEntity) => existingEntity?.teamID;
export const getSourceEntityID = (unknownEntity) => unknownEntity?.sourceTeamID;

export const dataPath = "teamData";
export const namesPath = "teamData.names";

export const refreshConfigKey = "queryTeamLookups";

export const fetchUnknownEntities = async (leagueID) => {
  if (!leagueID) return null;
  const result = await callAdminAPI("getUnknownTeams", { leagueID });
  const { success, unknownTeams } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  const sortedUnknownTeams = (unknownTeams || []).sort((unknownTeam_a, unknownTeam_b) => getTeamName(unknownTeam_a?.teamData).localeCompare(getTeamName(unknownTeam_b?.teamData)));
  return sortedUnknownTeams;
};

export const fetchExistingEntities = async (leagueID) => {
  // Each item must at least have a teamID field;
  if (!leagueID) return null;
  const result = await callAdminAPI("getTeams", { leagueID, fields: ["teamID", "names"] });
  const { success, teams } = result || {};
  if (!success) {
    console.error(result);
    return null;
  }
  return teams || [];
};

export const deleteUnknownEntity = async (unknownEntity) => {
  const { unknownTeamID } = unknownEntity || {};
  if (!unknownTeamID) return false;
  const result = await callAdminAPI("deleteUnknownTeam", { unknownTeamID });
  const { success } = result || {};
  if (!success) {
    console.error(result);
    return false;
  }
  return true;
};

export const matchUnknownEntity = async (unknownEntity, entityID) => {
  const { unknownTeamID } = unknownEntity || {};
  if (!unknownTeamID || !entityID) return false;
  const result = await callAdminAPI("matchUnknownTeam", { unknownTeam: unknownEntity, teamID: entityID });
  const { success } = result || {};
  if (!success) {
    console.error(result);
    return false;
  }
  return true;
};

export const validateUnknownEntity = (unknownEntity) => {
  if (!unknownEntity?.unknownTeamID) return false;
  if (!unknownEntity?.teamData) return false;
  if (!unknownEntity?.sourceID) return false;
  if (!unknownEntity?.sourceTeamID) return false;
  return true;
};

const ALLOW_IDS_FROM_MEDIUM_NAME = true;
export const getNewEntityID = (unknownEntity, existingEntitiesMap) => {
  const teamNameLong = getTeamName(unknownEntity?.teamData, ALLOW_IDS_FROM_MEDIUM_NAME);
  const leagueID = unknownEntity?.teamData?.leagueID || unknownEntity?.leagueID;
  if (!teamNameLong || !leagueID || !isObject(existingEntitiesMap)) return null;
  const getIsTeamIDAvailable = (potentialTeamID) => !!potentialTeamID && !existingEntitiesMap[potentialTeamID];
  return getNewTeamID(teamNameLong, leagueID, getIsTeamIDAvailable);
};

export const getUnknownEntityDisplayNames = (unknownEntity) => {
  return getAllTeamNames(unknownEntity?.teamData);
};

export const getUnknownEntityDisplaySource = (unknownEntity) => {
  const { sourceID, sourceTeamID } = unknownEntity || {};
  return [sourceID, sourceTeamID].filter((srcDisp) => !!srcDisp);
};

export const extraCellHeader_1 = null;
export const ExtraCellComponent_1 = ({ unknownEntity }) => {
  return null;
};
