import React, { useState, useMemo } from "react";
import { Button, Snackbar, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReactJson from "react-json-view";
import _ from "lodash";
import { copyData, dataChanged } from "@wagerlab/utils/data/mutations";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";

const SAVE_CLOSES_MODAL = true;

export const ObjectDataDialog = ({ data, enableEdits = false, onEdit = null, title = "", description = "", renderButton = null }) => {
  const [notice, setNotice] = useState("");
  const [visibleData, setVisibleData] = useState(null);

  const isVisible = !!visibleData;
  const canSaveChanges = useMemo(() => {
    if (!enableEdits || !onEdit || !visibleData) return false;
    return dataChanged(data, visibleData);
  }, [data, visibleData, enableEdits, onEdit]);

  const handleOpen = () => setVisibleData(copyData(data || {}));
  const handleSave = () => {
    if (canSaveChanges) {
      onEdit(visibleData);
      setNotice("Changes saved");
    }
    setNotice("No changes saved");
  };
  const handleClose = () => setVisibleData(null);
  const handleSaveAndClose = () => {
    if (canSaveChanges) onEdit(visibleData);
    setVisibleData(null);
  };

  const handleEdit = (edit) => {
    if (!enableEdits || !edit?.updated_src) return false;
    setVisibleData(copyData(edit?.updated_src));
    return true;
  };

  const defaultTitle = enableEdits ? "View or Edit Data" : "View Data";

  return (
    <>
      {renderButton ? renderButton(handleOpen) : <IconButton Icon={VisibilityIcon} onClick={handleOpen} size="small" title={title || defaultTitle} />}
      <Dialog open={isVisible} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>{title || defaultTitle}</DialogTitle>
        <DialogContent dividers>
          {description && (
            <Typography variant="body1" gutterBottom>
              {description}
            </Typography>
          )}
          <ReactJson src={data || {}} onEdit={handleEdit} onAdd={handleEdit} onDelete={handleEdit} theme="ashes" style={{ fontSize: "14px" }} enableClipboard={false} quotesOnKeys={false} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{enableEdits ? "Cancel" : "Close"}</Button>
          {enableEdits ? (
            <Button onClick={SAVE_CLOSES_MODAL ? handleSaveAndClose : handleSave} color="primary" disabled={!canSaveChanges}>
              Save
            </Button>
          ) : null}
        </DialogActions>
        <Snackbar open={!!notice} autoHideDuration={5000} onClose={() => setNotice("")} message={notice} />
      </Dialog>
    </>
  );
};
