// Currently unsupported:
// setCollectionDoc with merge=true

const { generateRemovedDoc } = require("@wagerlab/utils/database/helpers");
const { runWithRetry } = require("@wagerlab/utils/functions/retries");
const { logError } = require("@wagerlab/utils/logging");

class FirestoreAdapter {
  databaseInstance = null;
  collectionName = null;
  primaryKey = null;
  firebase = null;
  collectionRef = null;

  constructor(databaseInstance, collectionName, primaryKey) {
    this.databaseInstance = databaseInstance;
    this.collectionName = collectionName;
    this.primaryKey = primaryKey;
    this.firebase = databaseInstance.firebase;
    this.collectionRef = databaseInstance.firebase.firestore().collection(collectionName);
  }

  async query(queryParts, sorting = [], limit = null, selectFields = []) {
    let query = queryParts.reduce((combinedQuery, queryPart) => combinedQuery.where(queryPart.key, queryPart.operator, queryPart.value), this.collectionRef);

    const startAfterValues = [];
    const endBeforeValues = [];
    sorting.forEach(({ sortBy, order = "asc", startAfter, endBefore }) => {
      query = query.orderBy(sortBy, order);
      if (startAfter != null) startAfterValues.push(startAfter);
      if (endBefore != null) endBeforeValues.push(endBefore);
    });
    if (startAfterValues.length) query = query.startAfter(startAfterValues);
    if (endBeforeValues.length) query = query.endBefore(endBeforeValues);
    if (limit) query = query.limit(limit);
    if (selectFields.length) query = query.select(...selectFields);

    return query
      .get()
      .then((querySnap) => this.parseQuery(querySnap))
      .catch((error) => {
        logError(`FirestoreAdapter:query error`, { queryParts, sorting, limit, selectFields, error });
        return null;
      });
  }

  queryListener(queryParts, onUpdate, onError, onlyChanges = false) {
    let resolveInitialFunc;
    let shouldResolveInitial = true;
    const initial = new Promise((resolve) => {
      resolveInitialFunc = resolve;
    });
    const query = queryParts.reduce((combinedQuery, queryPart) => combinedQuery.where(queryPart.key, queryPart.operator, queryPart.value), this.collectionRef);

    const handleSnapshot = (querySnap) => {
      const resolveInitialNow = !!shouldResolveInitial;
      shouldResolveInitial = false;
      const docs = onlyChanges
        ? querySnap.docChanges().reduce((changedDocs, change) => {
            let changedDoc;
            if (change?.type === "removed") {
              const isDeletedFromDatabase = !queryParts?.length ? true : null;
              changedDoc = generateRemovedDoc(change?.doc?.id, isDeletedFromDatabase);
            } else {
              changedDoc = this.parseDocument(change?.doc);
            }
            changedDocs.push(changedDoc);
            return changedDocs;
          }, [])
        : this.parseQuery(querySnap);

      if (resolveInitialNow || docs.length) onUpdate(docs, resolveInitialNow);
      if (resolveInitialNow) {
        resolveInitialFunc(docs);
        resolveInitialFunc = null;
      }
    };
    const handleError = (error) => {
      const resolveInitialNow = !!shouldResolveInitial;
      shouldResolveInitial = false;
      onError(error);
      if (resolveInitialNow) {
        resolveInitialFunc(null);
        resolveInitialFunc = null;
      }
    };

    const unsubscribe = query.onSnapshot(handleSnapshot, handleError);
    return { initial, unsubscribe };
  }

  docListener(id, onUpdate, onError) {
    let shouldResolveInitial = true;
    let resolveInitialFunc;
    const initial = new Promise((resolve) => {
      resolveInitialFunc = resolve;
    });
    const docRef = this.collectionRef.doc(id);
    const handleSnapshot = (docSnap) => {
      const resolveInitialNow = !!shouldResolveInitial;
      shouldResolveInitial = false;
      const document = this.parseDocument(docSnap);
      onUpdate(document, resolveInitialNow);
      if (resolveInitialNow) {
        resolveInitialFunc(document);
        resolveInitialFunc = null;
      }
    };
    const handleError = (error) => {
      const resolveInitialNow = !!shouldResolveInitial;
      shouldResolveInitial = false;
      onError(error);
      if (resolveInitialNow) {
        resolveInitialFunc(null);
        resolveInitialFunc = null;
      }
    };

    const unsubscribe = docRef.onSnapshot(handleSnapshot, handleError);
    return { initial, unsubscribe };
  }

  async getAll() {
    return this.collectionRef
      .get()
      .then((querySnap) => this.parseQuery(querySnap))
      .catch((error) => {
        logError(`FirestoreAdapter:getAll`, { error });
        return null;
      });
  }

  async get(id) {
    return this.collectionRef
      .doc(id)
      .get()
      .then((docSnap) => this.parseDocument(docSnap))
      .catch((error) => {
        logError(`FirestoreAdapter:get error`, { id, error });
        return null;
      });
  }

  async set(id, data, returnModified = false) {
    //TODO - returnModified is not yet implemented here
    return await runWithRetry(() =>
      this.collectionRef
        .doc(id)
        .set(data)
        .then((r) => true)
    ).catch((error) => {
      logError(`FirestoreAdapter:set error`, { id, data, error });
      return null;
    });
  }

  async delete(id, returnModified = false) {
    //TODO - returnModified is not yet implemented here
    return await runWithRetry(() =>
      this.collectionRef
        .doc(id)
        .delete()
        .then((r) => true)
    ).catch((error) => {
      logError(`FirestoreAdapter:delete error`, { id, error });
      return null;
    });
  }

  async update(id, updates, returnModified = false) {
    //TODO - returnModified is not yet implemented here
    return this.collectionRef
      .doc(id)
      .update(updates)
      .then((r) => {
        return true;
      })
      .catch((error) => {
        logError(`FirestoreAdapter:update error`, { id, updates, error });
        return null;
      });
  }

  async batchWrite(batchList) {
    const MAX_BATCH_SIZE_ITEMS = Math.floor(500 * 0.75);
    const MAX_BATCH_SIZE_BYTES = Math.floor(1048576 * 0.75);

    const getOperationSize = (id, payload) => {
      const baseSize = 100;
      const idSize = id.length;
      return baseSize + idSize + JSON.stringify(payload || {}).length;
    };

    const chunks = [];
    let currentChunk = [];
    let currentChunkSize = 0;
    for (let i = 0; i < batchList.length; i++) {
      const batchItem = batchList[i] || {};
      let { method, id, payload } = batchItem;
      const opSize = getOperationSize(id, payload);
      if (opSize > MAX_BATCH_SIZE_BYTES) {
        logError(`FirestoreAdapter:batchWrite error: Operation for document ${id} exceeds maximum batch size and will be skipped`, { id, payload, opSize, MAX_BATCH_SIZE_BYTES });
        continue;
      }

      if (currentChunkSize + opSize > MAX_BATCH_SIZE_BYTES || currentChunk.length >= MAX_BATCH_SIZE_ITEMS) {
        if (currentChunk.length) chunks.push(currentChunk);
        currentChunk = [];
        currentChunkSize = 0;
      }
      currentChunk.push(batchItem);
      currentChunkSize += opSize;
    }
    if (currentChunk.length) chunks.push(currentChunk);

    const batchChunkPromises = chunks.map((batchListChunk, batchListChunkIndex) => {
      const isSoloBatch = batchListChunk.length === 1 && batchListChunkIndex === 0;
      const retries = isSoloBatch ? 0 : 3;
      return this.writeSingleBatch(batchListChunk, retries);
    });
    const chunkResponses = await Promise.all(batchChunkPromises);

    let results = [];
    let allSuccess = true;
    let someSuccess = false;
    chunks.forEach((chunk, chunkIndex) => {
      const chunkSucceeded = chunkResponses[chunkIndex];
      if (!chunkSucceeded) allSuccess = false;
      if (chunkSucceeded) someSuccess = true;
      for (let i = 0; i < chunk.length; i = i + 1) {
        results.push(chunkSucceeded);
      }
    });
    if (allSuccess && !someSuccess) allSuccess = false;
    return allSuccess;
  }
  writeSingleBatch = async (batchList, retries = 0) => {
    const batchWriter = this.firebase.firestore().batch();
    (batchList || []).forEach((batchItem) => {
      let { method, id, payload } = batchItem || {};
      let docRef = this.collectionRef.doc(id);
      if (method === "set") batchWriter.set(docRef, payload);
      else if (method === "delete") batchWriter.delete(docRef);
      else if (method === "update") batchWriter.update(docRef, payload);
    });
    return batchWriter
      .commit()
      .then((r) => {
        return true;
      })
      .catch((err) => {
        if (retries > 0) return this.writeSingleBatch(batchList, retries - 1);
        return false;
      });
  };

  async transaction(inputData, transactionFunction) {
    const id = inputData?.id;
    const docRef = this.collectionRef.doc(id);
    return this.firebase
      .firestore()
      .runTransaction(async (transaction) => {
        const docSnap = await transaction.get(docRef);
        const remoteData = this.parseDocument(docSnap);

        let abortCalled = false;
        let abortFunc = () => (abortCalled = true);
        const updatedData = transactionFunction(remoteData, inputData, abortFunc);

        if (abortCalled) return { success: true, aborted: true, data: remoteData, input: inputData };

        if (updatedData) {
          transaction.set(docRef, updatedData);
          return { success: true, aborted: false, data: updatedData, input: inputData };
        } else {
          transaction.delete(docRef);
          return { success: true, aborted: false, data: null, input: inputData };
        }
      })
      .catch((error) => {
        logError(`FirestoreAdapter:transaction error`, { inputData, error });
        return { success: false, aborted: false, data: null, input: inputData };
      });
  }

  async batchTransaction(inputList, transactionFunction) {
    const BATCH_SIZE = 250;
    const results = [];

    for (let i = 0; i < inputList.length; i += BATCH_SIZE) {
      const batchInputs = inputList.slice(i, i + BATCH_SIZE);
      const batchResults = await this.firebase
        .firestore()
        .runTransaction(async (transaction) => {
          const transactionResults = [];
          const snapshots = await Promise.all(batchInputs.map((input) => transaction.get(this.collectionRef.doc(input.id))));
          batchInputs.forEach((input, index) => {
            try {
              let abortCalled = false;
              const abortFunc = () => (abortCalled = true);
              const remoteData = this.parseDocument(snapshots[index]);
              const updatedData = transactionFunction(remoteData, input, abortFunc);

              if (abortCalled) {
                transactionResults.push({ success: true, aborted: true, data: remoteData, input });
              } else if (updatedData) {
                transaction.set(this.collectionRef.doc(input.id), updatedData);
                transactionResults.push({ success: true, aborted: false, data: updatedData, input });
              } else {
                transaction.delete(this.collectionRef.doc(input.id));
                transactionResults.push({ success: true, aborted: false, data: null, input });
              }
            } catch (error) {
              logError(`FirestoreAdapter:batchTransaction Error processing item ${input?.id}`, { input, error });
              transactionResults.push({ success: false, aborted: false, data: null, input });
            }
          });
          return transactionResults;
        })
        .catch((error) => {
          logError(`FirestoreAdapter:batchTransaction Transaction failed for batch at index ${i}:`, { error });
          return batchInputs.map((input) => ({
            success: false,
            aborted: false,
            data: null,
            input,
          }));
        });

      results.push(...batchResults);
    }

    return results;
  }

  deleteValue() {
    return this.firebase.firestore.FieldValue.delete();
  }

  serverTimeValue() {
    return this.firebase.firestore.FieldValue.serverTimestamp();
  }

  incrementValue(incrementBy) {
    return this.firebase.firestore.FieldValue.increment(incrementBy);
  }

  // Firestore-specific methods

  getFirebaseModule = () => this.firebase;

  getFirebaseCollectionRef = () => this.collectionRef;

  parseDocument = (docSnap) => {
    if (!docSnap?.exists || !docSnap?.id) return null;
    const docData = docSnap?.data?.() ?? null;
    return this.convertTimestampsToDates(docData);
  };

  parseQuery = (querySnap) => {
    if (!querySnap || querySnap.empty || !querySnap.forEach) return [];
    const items = [];
    querySnap.forEach((docSnap) => {
      const parsedDocument = this.parseDocument(docSnap);
      if (parsedDocument != null) items.push(parsedDocument);
    });
    return items;
  };

  isFirestoreTimestamp = (item) => typeof item?.toDate === "function";

  convertTimestampsToDates = (data) => {
    if (!data) return data;
    if (this.isFirestoreTimestamp(data)) return data.toDate();
    if (Array.isArray(data)) {
      const dataLen = data?.length || 0;
      for (let i = 0; i < dataLen; i++) {
        data[i] = this.convertTimestampsToDates(data[i]);
      }
      return data;
    }
    if (typeof data === "object") {
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i++) {
        const key = dataKeys[i];
        data[key] = this.convertTimestampsToDates(data[key]);
      }
      return data;
    }
    return data;
  };
}
exports.FirestoreAdapter = FirestoreAdapter;
