const { Collection } = require("../Collection");

const UNKNOWN_TEAMS_CONFIG = {
  collectionType: "unknownteams",
  collectionName: "UnknownTeams",
  adapterType: "mongo",
  primaryKey: "unknownTeamID",
};
exports.UNKNOWN_TEAMS_CONFIG = UNKNOWN_TEAMS_CONFIG;

class UnknownTeamsCollection extends Collection {
  config = UNKNOWN_TEAMS_CONFIG;
  // Any methods here will overwrite the default implementation. Calling `await super(args)` gives access to the default implementation response.
}
exports.UnknownTeamsCollection = UnknownTeamsCollection;
