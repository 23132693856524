import React, { useState, useMemo } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Switch, Stack, Box, Typography, Select, MenuItem, Divider } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import _ from "lodash";
import { parseDate } from "@wagerlab/utils/data/dates";
import { Formik, Form } from "formik";
import { ToggledInput } from "../shared/inputs/ToggledInput";
import { SelectBoolean } from "../shared/inputs/SelectBoolean";
import { useQueryClient } from "@tanstack/react-query";
import { IconButton } from "@wagerlab/admin/src/shared/Buttons";
import { DateTimePicker } from "@wagerlab/admin/src/shared/inputs/DateTimePicker";
import moment from "moment";
import { updateApiKey } from "@wagerlab/admin/src/apiKeys/database";
import { presentErrorMessage, presentSuccessMessage } from "@wagerlab/admin/src/utils/alerts";

export const OverridesEditor = ({ apiKeyData }) => {
  const queryClient = useQueryClient();
  const [isVisible, setIsVisible] = useState(false);

  const initialValues = useMemo(() => {
    const overrides = apiKeyData?.overrides || {};
    return {
      hasActive: overrides?.active != null,
      active: overrides?.active ?? apiKeyData?.active ?? false,
      endsAt: overrides?.endsAt ? moment(overrides.endsAt) : null,
    };
  }, [apiKeyData]);

  const handleSubmit = async (values) => {
    const endsAtMoment = values?.endsAt?.isValid?.() ? values.endsAt : null;
    const newOverridesEnabled = !!endsAtMoment && endsAtMoment.isAfter();
    let newOverrides = {};

    if (values.hasActive) newOverrides.active = !!values?.active;

    if (!newOverridesEnabled) {
      newOverrides = {};
    } else {
      newOverrides.endsAt = endsAtMoment.toISOString();
    }

    const apiKeyUpdated = await updateApiKey(apiKeyData?.apiKeyHash, { overrides: newOverrides });
    if (!apiKeyUpdated) {
      presentErrorMessage("Failed to update API key overrides. See console logs for details");
    } else {
      presentSuccessMessage("API key overrides updated successfully");
      queryClient.invalidateQueries({ queryKey: ["apiKeys"] });
    }
  };

  const endsAt_remote = parseDate(apiKeyData?.overrides?.endsAt, null);
  const overridesEnabled_remote = !!endsAt_remote && endsAt_remote > new Date();

  return (
    <>
      <IconButton Icon={TuneIcon} onClick={() => setIsVisible(true)} color={overridesEnabled_remote ? "accent" : "primary"} title="Set Temporary Overrides" />

      <Dialog open={isVisible} onClose={() => setIsVisible(false)} maxWidth="sm" fullWidth>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ values }) => {
            const overridesEnabled = values?.endsAt?.isValid?.() ? values.endsAt.isAfter() : false;
            return (
              <Form>
                <DialogTitle color={overridesEnabled ? "success.main" : "error.main"}>Override is {overridesEnabled ? "ENABLED" : "DISABLED"}</DialogTitle>
                <DialogContent>
                  <Stack spacing={3} sx={{ width: "100%", mt: 2 }}>
                    <DateTimePicker name="endsAt" label="Override Ends At" disabled={false} />
                    <Divider />
                    <ToggledInput toggleName="hasActive" InputComponent={SelectBoolean} name="active" label="Key Is Active" />
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setIsVisible(false)}>Cancel</Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
