const { setLoggerModule, setFirebaseModule, setMongoDBModule, getFirebaseModule, getLoggerModule } = require("@wagerlab/utils/modules");
const { connectToDatabase, disconnectFromDatabase, setDatabaseConnectOptions } = require("@wagerlab/utils/database");

exports.initialize = async (options) => {
  const { firebase, logger, shouldInitFirebase, shouldInitDatabase, firebaseInitOptions, dbTargetTestCollections, mongodb, mongoInitOptions, isServer } = options || {};

  setLoggerModule(logger);
  setFirebaseModule(firebase, isServer);
  setMongoDBModule(mongodb);

  const firebaseModule = getFirebaseModule();

  if (shouldInitFirebase && !firebaseModule.apps?.length) firebaseInitOptions ? firebaseModule.initializeApp(firebaseInitOptions) : firebaseModule.initializeApp();

  setDatabaseConnectOptions({ firebase, dbTargetTestCollections, mongodb, mongoInitOptions });
  if (shouldInitDatabase) await connectToDatabase();

  return true;
};

exports.terminate = async () => {
  await disconnectFromDatabase();
  return true;
};
