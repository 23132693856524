import _ from "lodash";
import { SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { isDate, isString } from "@wagerlab/utils/data/types";
import { getNormalizedString } from "@wagerlab/utils/aggregator/lookups";

export const generateEventSearchIndexMap = (events) => {
  const indexMap = {};
  (events || []).forEach((event) => {
    if (!event?.eventID) return;
    indexMap[event.eventID] = indexStringsForEvent(event);
  });
  return indexMap;
};

export const filterEvents = (events, searchIndexMap, searchQuery) => {
  const searchQueryNormalized = searchQuery?.toLowerCase?.() || "";
  if (!searchQueryNormalized) return events || [];
  return (events || []).filter((event) => {
    const searchIndex = event?.eventID && searchIndexMap?.[event.eventID];
    if (!searchIndex) return false;
    return searchIndex.includes(searchQueryNormalized);
  });
};

//Fields that end up indexed:  sport name, league name, event name, team names, player names, source IDs

export const EVENT_SUMMARY_PATHS = [
  "eventID",
  "eventName",
  "names.long",
  "names.short",
  "names.medium",
  "teams.home.teamID",
  "teams.home.names.short",
  "teams.home.names.medium",
  "teams.home.names.long",
  "teams.away.teamID",
  "teams.away.names.short",
  "teams.away.names.medium",
  "teams.away.names.long",
  "sportID",
  "leagueID",
  "props.prop0.propTitle",
  "props.prop0.sides.side1.sideDisplay",
  "props.prop0.sides.side2.sideDisplay",
  "status.startsAt",
];

const indexStringsForEvent = (event, isFullEvent = false) => {
  const defaultIndexedValues = EVENT_SUMMARY_PATHS.map((path) => _.get(event, path));

  const indexedValuesSet = new Set(defaultIndexedValues);

  if (isFullEvent) {
    Object.entries(event?.players || {}).forEach(([playerID, eventPlayerData]) => {
      if (!playerID) return;
      const { firstName, lastName, display, nickname } = eventPlayerData?.names || {};
      indexedValuesSet.add(playerID);
      indexedValuesSet.add(firstName);
      indexedValuesSet.add(lastName);
      indexedValuesSet.add(display);
      indexedValuesSet.add(nickname);
    });
    Object.entries(event?.props || {}).forEach(([propStatID, propData]) => {
      if (!propStatID) return;
      const { propTitle, propDetails } = propData || {};
      const side1Display = propData?.sides?.side1?.sideDisplay;
      const side2Display = propData?.sides?.side2?.sideDisplay;

      indexedValuesSet.add(propStatID);
      indexedValuesSet.add(propTitle);
      indexedValuesSet.add(propDetails);
      indexedValuesSet.add(side1Display);
      indexedValuesSet.add(side2Display);
    });
    Object.keys(event?.sourceContext || {}).forEach((sourceID) => {
      indexedValuesSet.add(sourceID);
    });
  }

  indexedValuesSet.add(SPORT_CONFIG?.[event?.sportID]?.name);
  indexedValuesSet.add(SPORT_CONFIG?.[event?.sportID]?.shortName);
  indexedValuesSet.add(LEAGUE_CONFIG?.[event?.leagueID]?.name);
  indexedValuesSet.add(LEAGUE_CONFIG?.[event?.leagueID]?.shortName);

  return Array.from(indexedValuesSet)
    .reduce((indexString, value) => {
      if (!value) return indexString;
      if (isDate(value)) {
        indexString += ` ${value.toISOString()} ${value.toLocaleString()}`;
        return indexString;
      }
      const stringValue = isString(value) ? value : value?.toString?.() || "";
      const normalizedValue = getNormalizedString(stringValue).toLowerCase();
      if (normalizedValue) indexString += ` ${normalizedValue}`;
      return indexString;
    }, "")
    .trim();
};
