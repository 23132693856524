import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, TextField, IconButton, Autocomplete, Snackbar, Alert, Badge, Typography, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment-timezone";
import { ObjectDataDialog } from "@wagerlab/admin/src/shared/ObjectDataDialog";
import * as unknownPlayersConfig from "./unknownPlayersConfig";
import * as unknownTeamsConfig from "./unknownTeamsConfig";
import { useParams } from "react-router-dom";
import { callAdminAPI } from "@wagerlab/admin/src/utils/database";
import { TextButton } from "@wagerlab/admin/src/shared/Buttons";
import { copyToClipboard } from "@wagerlab/admin/src/shared/clipboard";
import { generateLLMPrompt } from "@wagerlab/admin/src/unknowns/llmPrompt";
import { presentQuestion } from "@wagerlab/admin/src/utils/alerts";

const UNKNOWNS_CONFIG_BY_TYPE = {
  unknownplayers: unknownPlayersConfig,
  unknownteams: unknownTeamsConfig,
};

//If we ever add more unknownEntity types, they must have these fields:
// sourceID, ranAt, clashes, leagueID
// as well as (dynamic): unknownXID, XData, sourceXID

// refresh values: load, check, refresh, match, delete

export const AttachUnknownEntities = ({ type }) => {
  const params = useParams();
  const leagueID = params?.leagueID || "";
  const [unknownEntities, setUnknownEntities] = useState([]);
  const [existingEntities, setExistingEntities] = useState([]);
  const [loading, setLoading] = useState("");
  const [toast, setToast] = useState(null);

  const unknownConfig = UNKNOWNS_CONFIG_BY_TYPE[type];

  useEffect(() => {
    loadData();
  }, [leagueID]);

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => setToast(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [toast]);

  const existingEntitiesMap = useMemo(() => {
    return (existingEntities || []).reduce((entitiesMap, existingEntity) => {
      const entityID = unknownConfig.getEntityID(existingEntity);
      if (!entityID) return entitiesMap;
      entitiesMap[entityID] = existingEntity;
      return entitiesMap;
    }, {});
  }, [existingEntities, unknownConfig]);

  const handleSuccess = (message) => {
    setToast({ message, type: "success" });
    setLoading("");
  };

  const handleError = (message) => {
    setToast({ message, type: "error" });
    setLoading("");
  };

  const checkShouldContinue = (confirmMessage) => {
    const shouldContinue = window.confirm(confirmMessage);
    if (!shouldContinue) handleError("Cancelled by user");
    return !!shouldContinue;
  };

  const loadData = async () => {
    setLoading("load");
    setUnknownEntities([]);
    setExistingEntities([]);
    const unknownEntitiesPromise = unknownConfig.fetchUnknownEntities(leagueID);
    const existingEntitiesPromise = unknownConfig.fetchExistingEntities(leagueID);
    const [newUnknownEntities, newExistingEntities] = await Promise.all([unknownEntitiesPromise, existingEntitiesPromise]);
    if (!newUnknownEntities || !newExistingEntities) return handleError("Error loading unknown and existing entities");
    setUnknownEntities(newUnknownEntities);
    setExistingEntities(newExistingEntities);
    return handleSuccess("Data loaded");
  };

  const refreshUnknownEntities = async () => {
    if (loading) return;
    const refreshConfigKey = unknownConfig.refreshConfigKey;
    if (!leagueID || !refreshConfigKey) return handleError("Missing leagueID or refreshConfigKey");
    setLoading("refresh");
    const updateValue = await presentQuestion(`Would you like to refresh ALL unknowns (slower) or just for ${leagueID} (faster)?`, ["ALL", leagueID]);
    if (!updateValue) return setLoading("");
    const updatePath = `runner.${refreshConfigKey}`;
    const updates = { [updatePath]: updateValue };
    const result = await callAdminAPI("updateConfig", { configID: "aggregator", updates });
    const { success } = result || {};
    if (!success) {
      console.error(result);
      return handleError(`Failed to initiate refresh: ${result?.error || "Unknown error"}`);
    }
    return handleSuccess("Refresh Initiated. It should take 1-3 minutes to complete (more in dev mode).");
  };

  const checkIsRefreshingUnknownEntities = async () => {
    const refreshConfigKey = unknownConfig.refreshConfigKey;
    if (!leagueID || !refreshConfigKey) return handleError("Missing leagueID or refreshConfigKey");
    setLoading("check");
    const result = await callAdminAPI("getConfig", { configID: "aggregator" });
    const { success, error, config } = result || {};
    if (!success || config?.configID !== "aggregator") {
      console.error(result);
      return handleError(`Failed to check refresh status: ${error || "Unknown error"}`);
    }
    const updatingLeagueID = config?.runner?.[refreshConfigKey];
    if (!updatingLeagueID) return handleSuccess(`ALL COMPLETED - No leagues are having their unknowns refreshed. This means that any previously submitted refresh requests have completed.`);
    else if (updatingLeagueID === leagueID) return handleSuccess(`STILL REFRESHING - This league is currently having its unknowns refreshed.`);
    else return handleSuccess(`DIFFERENT LEAGUE - A different league (${updatingLeagueID}) is currently having its unknowns refreshed.`);
  };

  const handleMatch = async (unknownEntity, entityID) => {
    if (!entityID) return handleError(`ID is required to match unknown entity: ${unknownEntity} to ID: ${entityID}`);
    const unknownEntityID = unknownConfig.getUnknownEntityID(unknownEntity);
    if (!unknownEntityID) return handleError(`Can't match invalid unknown entity: ${unknownEntityID} to ID: ${entityID}`);
    if (!checkShouldContinue(`Are you sure you want to match unknown: ${unknownEntityID} to ID: ${entityID}?`)) return;
    setLoading("match");
    const successfullyMatched = await unknownConfig.matchUnknownEntity(unknownEntity, entityID);
    if (!successfullyMatched) return handleError(`Failed to attach entity: ${unknownEntityID} to ID: ${entityID}`);
    await loadData();
    return handleSuccess(`Successfully matched unknown: ${unknownEntityID} to ID: ${entityID}`);
  };

  const handleDelete = async (unknownEntity) => {
    const unknownEntityID = unknownConfig.getUnknownEntityID(unknownEntity);
    if (!unknownEntityID) return handleError(`Can't delete invalid unknown entity: ${unknownEntity}`);
    if (!checkShouldContinue(`Are you sure you want to delete: ${unknownEntityID}?`)) return;
    setLoading("delete");
    const successfullyDeleted = await unknownConfig.deleteUnknownEntity(unknownEntity);
    if (!successfullyDeleted) return handleError(`Failed to delete: ${unknownEntityID}`);
    await loadData();
    return handleSuccess(`Successfully deleted unknown: ${unknownEntityID}`);
  };

  const editUnknownEntityAtIndex = (newUnknownEntity, index) => {
    const newUnknownEntityID = unknownConfig.getUnknownEntityID(newUnknownEntity);
    if (!newUnknownEntityID) return handleError(`Can't edit invalid unknown entity: ${newUnknownEntity}`);
    const newUnknownEntities = [...unknownEntities];
    newUnknownEntities[index] = newUnknownEntity;
    setUnknownEntities(newUnknownEntities);
  };

  const llmPrompt = useMemo(() => generateLLMPrompt(unknownEntities, existingEntities, unknownConfig, leagueID), [unknownEntities, existingEntities, unknownConfig, leagueID]);

  //TextButton = ({ Icon, size = 14, fullWidth = false, centered = false, text, loading = false, onClick, color = "primary", style = {}, disabled = false,
  return (
    <div>
      <Box sx={{ display: "flex", gap: 1, my: 2 }}>
        <TextButton text="Load Unknowns" loading={loading === "load"} disabled={!!loading} onClick={loadData} color="primary" size={12} />
        <TextButton text="Refresh Unknowns" loading={loading === "refresh"} disabled={!!loading} onClick={refreshUnknownEntities} color="secondary" size={12} />
        <TextButton text="Check Refresh Status" loading={loading === "check"} disabled={!!loading} onClick={checkIsRefreshingUnknownEntities} color="secondary" size={12} />
        <TextButton text="Copy LLM Prompt" loading={false} disabled={!!loading} onClick={() => copyToClipboard(llmPrompt)} color="alternate" size={12} />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unknown</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Ran At</TableCell>
              {unknownConfig.extraCellHeader_1 ? <TableCell>{unknownConfig.extraCellHeader_1}</TableCell> : null}
              <TableCell>Clashes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unknownEntities.map((unknownEntity, index) => (
              <UnknownEntityRow
                unknownEntity={unknownEntity}
                existingEntitiesMap={existingEntitiesMap}
                unknownConfig={unknownConfig}
                leagueID={leagueID}
                loading={loading}
                handleDelete={handleDelete}
                handleMatch={handleMatch}
                handleEdit={(newUnknownEntity) => editUnknownEntityAtIndex(newUnknownEntity, index)}
                key={unknownConfig.getUnknownEntityID(unknownEntity)}
              />
            ))}
          </TableBody>
        </Table>

        {toast && (
          <Snackbar open={!!toast} onClose={() => setToast(null)} autoHideDuration={3000}>
            <Alert onClose={() => setToast(null)} severity={toast.type} sx={{ width: "100%" }}>
              {toast.message}
            </Alert>
          </Snackbar>
        )}
      </TableContainer>
    </div>
  );
};

const UnknownEntityRow = ({ unknownEntity, unknownConfig, existingEntitiesMap, leagueID, loading, handleDelete, handleMatch, handleEdit }) => {
  const unknownEntityID = unknownConfig.getUnknownEntityID(unknownEntity);

  const displayNames = unknownConfig.getUnknownEntityDisplayNames(unknownEntity);
  const primaryDisplayName = displayNames[0];
  const otherDisplayNames = displayNames.slice(1);

  if (!primaryDisplayName || !unknownEntityID) return null;

  const displaySources = unknownConfig.getUnknownEntityDisplaySource(unknownEntity);

  const ranAtDisplay = moment(unknownEntity?.ranAt).fromNow();

  const ExtraCellComponent_1 = unknownConfig.ExtraCellComponent_1;

  return (
    <TableRow key={unknownEntityID}>
      <TableCell>
        {primaryDisplayName}
        <ObjectDataDialog data={unknownEntity} enableEdits={true} onEdit={handleEdit} />
        {otherDisplayNames.map((otherDisplayName) => (
          <div key={otherDisplayName}>
            <span style={{ fontSize: "0.8em" }}>{otherDisplayName}</span>
          </div>
        ))}
      </TableCell>
      <TableCell>
        {displaySources.map((source) => (
          <div key={source}>{source}</div>
        ))}
      </TableCell>
      <TableCell>{ranAtDisplay}</TableCell>
      {unknownConfig.extraCellHeader_1 ? (
        <TableCell>
          <ExtraCellComponent_1 unknownEntity={unknownEntity} />
        </TableCell>
      ) : null}
      <TableCell>
        {(unknownEntity?.clashes || []).map((clash, index) => (
          <div key={clash}>{clash}</div>
        ))}
      </TableCell>
      <TableCell>
        <UnknownEntityActions
          unknownEntity={unknownEntity}
          existingEntitiesMap={existingEntitiesMap}
          unknownConfig={unknownConfig}
          leagueID={leagueID}
          loading={loading}
          handleDelete={handleDelete}
          handleMatch={handleMatch}
        />
      </TableCell>
    </TableRow>
  );
};

const UnknownEntityActions = ({ unknownEntity, existingEntitiesMap, unknownConfig, leagueID, loading, handleDelete, handleMatch }) => {
  const [entityIDInput, setEntityIDInput] = useState("");

  const { allIDOptions, specialIDOptions } = useMemo(() => {
    const existingOptions = Object.keys(existingEntitiesMap).sort();
    const clashOptions = [];
    const specialOptions = {};
    (unknownEntity?.clashes || []).forEach((clashingEntityID) => {
      if (!clashingEntityID) return;
      clashOptions.push(clashingEntityID);
      specialOptions[clashingEntityID] = "clash";
    });
    const allOptions = [...clashOptions, ...existingOptions];
    return { allIDOptions: allOptions, specialIDOptions: specialOptions };
  }, [unknownEntity, existingEntitiesMap]);

  const suggestedNewEntityID = useMemo(() => {
    return unknownConfig.getNewEntityID(unknownEntity, existingEntitiesMap);
  }, [unknownEntity, existingEntitiesMap]);

  let submitDisabled = false;
  let submitTooltipMessage = "";
  if (loading) {
    submitTooltipMessage = "Loading...";
    submitDisabled = true;
  } else if (!unknownConfig.validateUnknownEntity(unknownEntity)) {
    submitTooltipMessage = "Unknown entity data is invalid. Please check it and consider refreshing the data.";
    submitDisabled = true;
  } else if (!entityIDInput) {
    submitTooltipMessage = "Enter or select an ID to submit.";
    submitDisabled = true;
  } else if (entityIDInput && !entityIDInput.endsWith(`_${leagueID}`)) {
    submitTooltipMessage = "ID must end with the leagueID.";
    submitDisabled = true;
  }

  return (
    <div>
      <Autocomplete
        options={allIDOptions}
        renderInput={(params) => <TextField {...params} size="small" placeholder="Select or Input an ID" />}
        renderOption={(props, entityIDOption) => (
          <li
            {...props}
            style={{
              backgroundColor: specialIDOptions[entityIDOption] === "clash" ? "#e8f5e9" : "inherit",
            }}
          >
            {entityIDOption}
          </li>
        )}
        onChange={(_, newValue) => {
          setEntityIDInput(newValue || "");
        }}
        onInputChange={(_, newInputValue) => {
          setEntityIDInput(newInputValue || "");
        }}
        value={entityIDInput || ""}
        freeSolo
        size="small"
        sx={{ minWidth: "300px" }}
      />
      {suggestedNewEntityID ? (
        <div style={{ marginTop: "5px" }}>
          {`New ID: `}
          <span onClick={() => setEntityIDInput(suggestedNewEntityID)} style={{ textDecoration: "underline", cursor: "pointer" }}>
            {suggestedNewEntityID}
          </span>
        </div>
      ) : null}
      <Tooltip title={submitTooltipMessage} arrow>
        <span>
          <Button
            variant="contained"
            onClick={() => handleMatch(unknownEntity, entityIDInput)}
            disabled={submitDisabled}
            sx={{
              "mt": 1,
              "backgroundColor": submitDisabled ? "gray" : "green",
              "&:hover": { backgroundColor: submitDisabled ? "gray" : "darkgreen" },
              "cursor": submitDisabled ? "not-allowed" : "pointer", // Ensure cursor is applied
            }}
          >
            Submit ID
          </Button>
        </span>
      </Tooltip>
      <IconButton color="error" onClick={() => handleDelete(unknownEntity)} disabled={loading} sx={{ mt: 1 }}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
